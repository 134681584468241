<template>
  <section class="page-not-found">
    <svg v-if="status === '404'" class="page-not-found__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 173 145">
      <g fill="none" fill-rule="evenodd">
        <path
          class="page-not-found__icon-face"
          d="M172.0888 17.169H1.4704v115.9735c0 6.3513 5.1487 11.5 11.5 11.5h147.6184c6.3512 0 11.5-5.1487 11.5-11.5V17.1691z"
        />
        <path
          class="page-not-found__icon-top-bar"
          d="M172.5888 11.9805v5.551H.9704v-5.551c0-6.6274 5.3726-12 12-12h147.6184c6.6274 0 12 5.3726 12 12z"
        />
        <path
          class="page-not-found__icon-top-bar-x-background"
          fill-opacity=".3"
          d="M172.5888 11.9805v5.551h-18.913V-.0195h6.913c6.6274 0 12 5.3726 12 12z"
        />
        <path
          class="page-not-found__icon-top-bar-x"
          d="M164.0262 8.405l2.6115 2.6174c.3625.3632-.1812.9081-.5437.5449l-2.6115-2.6174-2.6115 2.6174c-.3625.3632-.9062-.1817-.5437-.545l2.6115-2.6173-2.6115-2.6173c-.3625-.3633.1812-.9082.5437-.545l2.6115 2.6174 2.6115-2.6173c.3625-.3633.9062.1816.5437.5449l-2.6115 2.6173z"
        />
        <g transform="translate(47.1143 66.5459)">
          <path
            class="page-not-found__icon-mouth"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="5"
            d="M28.7837 23.116c7.7353.172 15.2237 1.8065 22.4653 4.9033"
          />
          <ellipse class="page-not-found__icon-eye" cx="7.0204" cy="7.0048" rx="7.0204" ry="7.0048" />
          <ellipse class="page-not-found__icon-eye" cx="70.9061" cy="7.0048" rx="7.0204" ry="7.0048" />
        </g>
      </g>
    </svg>
    <div class="page-not-found__error">
      <h2 class="page-not-found__status">{{ status }}</h2>
      <p class="page-not-found__message">{{ message }}</p>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    status: {
      type: String,
      required: true,
      validator: (value: string): boolean => ['404'].includes(value),
    },
    message: {
      type: String,
      required: true,
    },
  },
});
</script>
