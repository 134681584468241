<template>
  <label class="toggleable-button" data-test="label-Checkbox">
    <input
      class="toggleable-button__hidden-checkbox"
      :checked="isChecked"
      type="checkbox"
      v-model="isChecked"
      @click="input"
    />
    <button class="toggleable-button__inner" @click="input" type="button">
      <div
        class="toggleable-button__icon-wrapper"
        :class="isChecked ? 'toggleable-button__icon-wrapper--checked' : undefined"
      >
        <i class="toggleable-button__icon" :class="icon ? `toggleable-button__icon--${icon}` : undefined" />
      </div>
      <span class="toggleable-button__label">{{ label }}</span>
    </button>
  </label>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted } from 'vue';

export default defineComponent({
  props: {
    icon: {
      validator: (value: string): boolean => ['face-id', 'fingerprint', 'prox-device', 'pin-code'].includes(value),
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isChecked = ref(false);

    const input = (event: Event) => {
      isChecked.value = !isChecked.value;
      emit('update:modelValue', isChecked.value);
    };

    watch(
      () => props.value,
      () => {
        isChecked.value = props.value;
      }
    );

    onMounted(() => {
      isChecked.value = props.value == null ? false : props.value;
    });

    return {
      isChecked,
      input,
    };
  },
});
</script>

<style lang="scss">
:root {
  --tw-toggleable-button-icon-background-color: var(--kw-checkbox-visual-icon-background-color);
  --tw-toggleable-button-icon-background-color-checked: var(--kw-checkbox-visual-icon-background-color-checked);
  --tw-toggleable-button-icon-border-color: var(--kw-checkbox-visual-icon-border-color);
  --tw-toggleable-button-icon-border-color-hover: var(--kw-checkbox-visual-icon-border-color-hover-checked);
  --tw-toggleable-button-icon-border-color-hover-checked: var(--kw-checkbox-visual-icon-border-color-hover-checked);
  --tw-toggleable-button-label-color: var(--kw-default-text-color);
}
</style>

<style lang="scss" scoped>
$hidden-checkbox-offset: 1rem;

.toggleable-button {
  display: inline-block;
  user-select: none;
  margin-top: -$hidden-checkbox-offset;

  &__hidden-checkbox {
    position: relative;
    top: $hidden-checkbox-offset;
    opacity: 0;
    cursor: pointer;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 4.5rem;
    /* remove default "button" styling */
    border-style: none;
    padding: 0;
    background-color: inherit;
    color: inherit;
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.625rem;
    height: 3rem;
    border: 1px solid var(--tw-toggleable-button-icon-border-color);
    border-radius: var(--kw-border-inner);
    background-color: var(--tw-toggleable-button-icon-background-color);
    cursor: pointer;

    &:hover {
      border-color: var(--tw-toggleable-button-icon-border-color-hover);
    }

    &--checked {
      background-color: var(--tw-toggleable-button-icon-background-color-checked);
      border-color: var(--tw-toggleable-button-icon-border-color-hover-checked);
    }
  }

  &__icon {
    font-family: var(--kw-mf-icons-font-family);
    font-size: 250%;
    font-style: normal;
    color: var(--tw-toggleable-button-label-color);

    &--face-id::before {
      content: '\e925';
    }

    &--fingerprint::before {
      content: '\e926';
    }

    &--pin-code::before {
      content: '\e962';
    }

    &--prox-device::before {
      content: '\e98a';
    }
  }

  &__label {
    line-height: 1.125rem;
    font-size: 0.75rem;
  }
}
</style>
