import { createApp, defineComponent, h } from 'vue';
import Toast from '@/components/Toast.vue';
import IToastService from './IToastService';
import { injectable } from 'inversify-props';

export interface IToast {
  header: string;
  body: string;
  status: 'success' | 'error' | 'warning' | 'inform';
}

@injectable()
class ToastService implements IToastService {
  private toastCount: number = 0;

  private showToast(timeout: number, instance: any, toastId: string): number {
    return setTimeout(() => {
      this.closeToast(timeout, instance, toastId);
    }, 8000);
  }

  private closeToast(timeout: number, instance: any, toastId: string): void {
    window.clearTimeout(timeout);
    instance.unmount();
    document.getElementById(toastId)?.remove();
  }

  public open(toast: IToast): void {
    const toastsWrapper = document.querySelector('#toasts.toasts');
    const toastId = this.toastCount.toString();
    let timeout: number;

    const close = () => {
      this.closeToast(timeout, instance, toastId);
    };

    const pause = () => {
      window.clearTimeout(timeout);
    };

    const start = () => {
      timeout = this.showToast(timeout, instance, toastId);
    };

    const instance = createApp({
      render() {
        return h(Toast, {
          status: toast.status,
          header: toast.header,
          body: toast.body,
          onClose: close,
          onPause: pause,
          onStart: start,
        });
      },
    });

    const mountPoint = document.createElement('div');
    instance.mount(mountPoint);

    toastsWrapper?.insertBefore(mountPoint, toastsWrapper?.children[0]!).setAttribute('id', toastId);
    this.toastCount++;

    timeout = this.showToast(timeout!, instance, toastId);
  }
}

export default ToastService;
