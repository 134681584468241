/* eslint-disable prettier/prettier */
import { CreateUserTagDto, UserTagDto, UserTagsClient } from '@/apiClients';
import authorizedClient from './authorizedClient';

export interface IUserTagState {
  userTags: [];
  allUserTags: UserTagDto[];
}

export default {
  namespaced: true as true,
  state: {
    userTags: {},
    allUserTags: {},
  } as IUserTagState,
  getters: {
    allUserTags:
      (state: IUserTagState) =>
      (id: string): UserTagDto[] => {
        return state.allUserTags;
      },
  },
  mutations: {
    GET_USERTAGS_FOR_USER(state: IUserTagState, allUserTags: UserTagDto[]) {
      state.allUserTags = allUserTags;
    },
  },
  actions: {
    async getAllUserTags({ commit }: any, id: string) {
      const userTagsClient = await authorizedClient(UserTagsClient);
      await userTagsClient
        .getUserTagsByUser(id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        })
        .then((result) => {
          commit('GET_USERTAGS_FOR_USER', result);
        });
    },
    async createUserTag({ commit }: any, userTag: CreateUserTagDto) {
      const userTagsClient = await authorizedClient(UserTagsClient);
      try {
        return await userTagsClient.create(process.env.VUE_APP_TERMINAL_WEB_API_VERSION, userTag);
      } catch (error) {
        throw error;
      }
    },
    async deleteUserTag({ commit }: any, id: string) {
      const userTagsClient = await authorizedClient(UserTagsClient);
      try {
        return await userTagsClient.delete(id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
      } catch (error) {
        throw error;
      }
    },
  },
};
