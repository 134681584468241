<template>
  <div class="toast" :class="`toast--${status}`" role="alert" @mouseenter="pauseTimeout" @mouseleave="startTimeout">
    <div class="toast__notifier">
      <i
        class="toast__icon"
        :class="`icon-${status === 'inform' || status === 'success' ? 'tick' : 'exclamation'}`"
      ></i>
    </div>
    <div class="toast__text">
      <h6 class="toast__header" data-test="header-toast">
        {{ header }}
      </h6>
      <p class="toast__body" data-test="paragraph-toastBody">
        {{ body }}
      </p>
    </div>
    <Btn @click="closeToast" class="toast__close" ghost title="Close notification" data-test="button-closeToast">
      <i class="toast__close-icon icon-cross"></i>
      <span class="sr-only">Close notification</span>
    </Btn>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, watch } from 'vue';
import { Btn } from '@/components';

export default defineComponent({
  name: 'Toast',
  components: { Btn },
  props: {
    status: {
      type: String as PropType<'success' | 'error' | 'warning' | 'inform'>,
      validator: (value: string): boolean => ['success', 'error', 'warning', 'inform'].includes(value),
      default: 'inform',
    },
    header: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const closeToast = () => emit('close');
    const pauseTimeout = () => emit('pause-timeout');
    const startTimeout = () => emit('start-timeout');

    watch(
      () => props.header + props.body,
      () => {
        // perform some action when props.header or props.body changes
      }
    );

    return {
      closeToast,
      pauseTimeout,
      startTimeout,
    };
  },
});
</script>
