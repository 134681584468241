<template>
  <label class="textbox" :class="{ 'textbox--required': required }" :data-test="`label- ${label}`">
    <span :class="{ 'sr-only': hideLabel }">
      {{ label }}
      <template v-if="required">
        <i class="textbox__asterisk" aria-hidden="true">*</i><i class="sr-only">(required)</i>
      </template>
    </span>
    <input
      class="textbox__entry"
      :class="status ? `textbox--${status}` : null"
      type="time"
      v-model="internalInputValue"
      :disabled="disabled"
      :max="max"
      :min="min"
      :placeholder="placeholder"
      :readonly="readonly"
      :required="required"
      @blur="onBlur"
      @change="onChange"
      @focus="onFocus"
      @input="onInput"
    />
    <span v-if="message" class="textbox__message">{{ message }}</span>
  </label>
</template>

<script lang="ts">
import { defineComponent, ref, watch, toRefs, SetupContext, onMounted } from 'vue';

export default defineComponent({
  props: {
    disabled: { type: Boolean, default: false },
    hideLabel: { type: Boolean, default: false },
    label: { type: String, required: true },
    max: { type: String, default: '23:59' },
    message: { type: String },
    min: { type: String, default: '00:00' },
    placeholder: { type: String },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    status: {
      type: String,
      default: undefined,
      validator: (value: string): boolean => ['success', 'error'].includes(value),
    },
    value: { type: String },
  },
  setup(props, { emit }: SetupContext) {
    const internalInputValue = ref(props.value || '00:00');

    const updateValue = (value: string) => {
      if (!/^[0-9]{2}:[0-9]{2}$/.test(value)) {
        throw new Error(`Invalid value: '${value}' should be in the format 'hh:mm'`);
      }

      if (props.max === '00:00' && value === '00:00') {
        internalInputValue.value = '00:00';
      } else {
        if (value < props.min) {
          internalInputValue.value = props.min;
        }
        if (value > props.max) {
          internalInputValue.value = props.max;
        }
        if (value >= props.min && (props.max === '00:00' || value <= props.max)) {
          internalInputValue.value = value;
        }
      }
    };

    onMounted(() => {
      if (props.min != null && props.max != null && props.max !== '00:00' && props.max < props.min) {
        throw new Error('The max prop should not have a value less than the min prop.');
      }

      if (props.readonly && props.disabled) {
        throw new Error('The readonly prop and the disabled prop cannot both be set.');
      }

      if (props.value) {
        updateValue(props.value);
      }
    });

    watch(
      () => props.value,
      (newValue) => {
        if (newValue !== undefined) {
          updateValue(newValue);
        }
      }
    );

    const onBlur = () => {
      emit('blur', internalInputValue.value);
    };

    const onChange = () => {
      emit('change', internalInputValue.value);
    };

    const onFocus = () => {
      emit('focus', internalInputValue.value);
    };

    const onInput = () => {
      emit('input', internalInputValue.value);
    };

    return {
      ...toRefs(props),
      internalInputValue,
      onBlur,
      onChange,
      onFocus,
      onInput,
    };
  },
});
</script>
