<template>
  <div class="[ mfc-radio-button-wrapper ] [ mu-mb-03 ]">
    <input :value="isChecked" :id="inputId" :name="name" type="radio" @input="onChange" />
    <label v-show="!hideLabel" :for="inputId">
      <span v-show="title" class="title">{{ title }}</span>
      <span v-show="subtitle" class="subtitle">{{ subtitle }}</span>
    </label>
  </div>
</template>

<script lang="ts">
import { ref, defineProps, defineEmits, watchEffect, defineComponent, watch, onMounted } from 'vue';

export default defineComponent({
  props: {
    value: {
      type: Boolean
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    inputId: {
      type: String,
    },
    name: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup( props ) {
    //const localValue = ref(props.value);
    const isChecked = ref(false);

    // watchEffect(() => {
    //   isChecked.value = localValue;
    // });

    // const onChange = () => {
    //   defineEmits<{ input: (value: boolean) => void }>().input(isChecked.value);
    // };
    
    watch(
      () => props.value,
      () => {
        isChecked.value = props.value;
      }
    );

    const onChange = (): boolean => {
      return isChecked.value;
    };

    onMounted(() => {
      if (props.value === undefined) {
        isChecked.value = false;
      } else {
        isChecked.value = props.value;
      }
    });
    
    return {
      isChecked,
      onChange,
    }
  },
});
</script>

<style lang="scss" scoped>
.mfc-radio-button-wrapper {
  --mds-input-as-radio-choice-border: var(--mds-border-color);
  --mds-input-as-radio-choice-background: var(--mds-form-bg-color);
  --mds-input-as-radio-choice-input-inset-inline: 1.25rem auto;
  --mds-input-as-radio-choice-label-padding-inline: 3.75rem 1.25rem;
  --mds-input-as-radio-choice-title-color: inherit;
  --mds-input-as-radio-choice-subtitle-color: var(--mds-text-color-muted);

  position: relative;

  input[type='radio'] {
    position: absolute;
    inset-block-start: 1.5rem;
    inset-inline: var(--mds-input-as-radio-choice-input-inset-inline);
    margin-inline-end: 0;
    appearance: none;
    vertical-align: top;
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-block: 0;
    margin-inline: 0 0.25rem;
    border: 1px solid var(--mds-form-border-color);
    border-radius: 50%;
    outline: none;
    background-color: var(--mds-form-bg-color);
    transition: 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition-property: background-color, border-color, box-shadow;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    &:checked {
      background-color: var(--mds-active-color-on);
      border-color: var(--mds-active-color);
      border-width: 7px;
      + label {
        --mds-input-as-radio-choice-border: var(--mds-color-primary-border);
        --mds-input-as-radio-choice-background: var(--mds-color-primary-soft);
      }
    }

    + label {
      display: grid;
      border: 1px solid var(--mds-input-as-radio-choice-border);
      border-radius: 0.187rem;
      padding-block: calc(0.75rem - 1px);
      padding-inline: var(--mds-input-as-radio-choice-label-padding-inline);
      cursor: pointer;
      background-color: var(--mds-input-as-radio-choice-background);

      .title {
        color: var(--mds-input-as-radio-choice-title-color);
      }

      .subtitle {
        color: var(--mds-input-as-radio-choice-subtitle-color);
      }
    }
  }
}

.mu-mb-03 {
  margin-bottom: 0.5rem !important;
}

*,
:after,
:before {
  box-sizing: border-box;
}

div {
  display: block;
}
</style>
