import 'reflect-metadata';
import { createApp } from 'vue';
import App from './App.vue';
import VueClickAway from 'vue3-click-away';
import axios from 'axios';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { container } from 'inversify-props';
import { directive as onClickaway } from 'vue3-click-away';
import router from './router';
import AuthenticationService from './services/AuthenticationService';
import IAuthenticationService from './services/IAuthenticationService';
import ToastService from './services/ToastService';
import IToastService from './services/IToastService';
import { PendoService } from './services/PendoService';
import { IPendoService } from './services/PendoService';
import '@/styles/main.scss';
import storeConfig from '@/store';

const app = createApp(App);

app.config.globalProperties.$axios = axios;
app.use(storeConfig);
app.use(VueClickAway);
app.use(router);

if (process.env.VUE_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY !== 'DISABLED') {
  const applicationInsightsPlugin = {
    install(Vue: any) {
      const appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: process.env.VUE_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
          enableAutoRouteTracking: true,
          disableDataLossAnalysis: false,
          enableDebug: true,
          disableFetchTracking: false,
        },
      });
      appInsights.loadAppInsights();
      appInsights.trackPageView();
      Vue.config.globalProperties.$appInsights = appInsights;
    },
  };
  app.use(applicationInsightsPlugin);
}

container.bind<IAuthenticationService>('authenticationService').to(AuthenticationService);
container.bind<IToastService>('toastService').to(ToastService);
container.bind<IPendoService>('pendoService').to(PendoService);

app.provide('container', container);
app.directive('onClickaway', onClickaway);

app.mount('#app');
