import { ref } from 'vue';
import { DeviceFirmwareClient, DeviceUpgradeStateDto, DeviceFirmwareDto, UpgradeProcessState } from '@/apiClients';

import authorizedClient from './authorizedClient';

export interface IDeviceFirmwareState {
  deviceFirmware: DeviceFirmwareDto[];
  deviceUpgradeProgress: DeviceUpgradeStateDto;
}

export default {
  namespaced: true,
  state: () => ({
    deviceFirmware: [] as DeviceFirmwareDto[],
    deviceUpgradeProgress: new DeviceUpgradeStateDto({
      id: '',
      deviceId: '',
      firmwareVersion: '',
      upgradeProcessState: UpgradeProcessState.None,
      timeOfChange: new Date(),
      attempts: 0,
      retry: false,
    }),
  }),
  getters: {
    deviceFirmware: (state: IDeviceFirmwareState) => state.deviceFirmware,
    deviceUpgradeProgress: (state: IDeviceFirmwareState) => state.deviceUpgradeProgress,
  },
  mutations: {
    SET_DEVICE_FIRMWARE(state: IDeviceFirmwareState, deviceFirmware: DeviceFirmwareDto[]) {
      state.deviceFirmware = deviceFirmware;
    },
    SET_DEVICE_UPGRADE_PROGRESS(state: IDeviceFirmwareState, deviceUpgradeProgress: DeviceUpgradeStateDto) {
      state.deviceUpgradeProgress = deviceUpgradeProgress;
    },
  },
  actions: {
    async getFirmwareVersions({ commit }: { commit: any }, terminalId: string) {
      const deviceFirmwareClient = await authorizedClient(DeviceFirmwareClient);
      try {
        const result = await deviceFirmwareClient.getFirmwareVersions(
          terminalId,
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION
        );
        if (result) {
          commit('SET_DEVICE_FIRMWARE', result);
        }
      } catch (error) {
        throw error;
      }
    },
    async getFirmwareUpgradeProgress({ commit }: { commit: any }, id: string): Promise<DeviceFirmwareDto> {
      await authorizedClient(DeviceFirmwareClient).then(async (client) => {
        await client
          .getDeviceUpgradeState(id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION)
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
            return <DeviceFirmwareDto>{};
          })
          .then((result) => {
            if (result) {
              commit('SET_DEVICE_UPGRADE_PROGRESS', result);
              return result;
            }
          });
      });
      return <DeviceFirmwareDto>{};
    },
  },
};
