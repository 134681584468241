<template>
  <label>
    <span class="sr-only">
      {{ label }}
    </span>
    <input
      v-model="inputValue"  
      :disabled="disabled"
      :placeholder="placeholder"
      type="text"
      class="searchbox__entry textbox__entry"
      @blur="blur"
      @focus="focus"
      @keyup.enter="search"
      @keyup.esc="reset"
    />
    <button
      class="searchbox__reset"
      data-test="button-searchReset"
      :class="!inputValue ? 'searchbox__reset--hidden' : undefined"
      :disabled="disabled"
      @click="reset"
    >
      <i class="icon-cross"></i>
    </button>
    <button 
      class="searchbox__search" 
      data-test="button-search" 
      :disabled="disabled || !inputValue" 
      @click="search"
    >
      <i class="icon-search"></i>
    </button>
  </label>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { Btn } from '@/components';

export default defineComponent({
  components: {
    Btn,
  },
  props: {
    value: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: String
  },
  setup(props, { emit }) {
    const hasFocus = ref(false);
    const inputValue = ref(props.value);
    const resetButtonClass = computed(() => !inputValue ? 'searchbox__reset--hidden' : undefined);

    const blur = (event: Event) => {
      hasFocus.value = false;
      emit('blur', (event.target as HTMLInputElement).value);
    }

    const focus = (event: Event) => {
      hasFocus.value = true;
      emit('focus', (event.target as HTMLInputElement).value);
    }

    const search = () => {
      inputValue.value = inputValue.value === undefined ? '' : inputValue.value.trim();
      emit('search', inputValue.value);
    }

    const reset = () => {
      inputValue.value = '';
      emit('reset', inputValue.value);
    }

    return {
      inputValue,
      blur,
      focus,
      reset,
      search,
      resetButtonClass,
    };
  },
});
</script>

<style lang="scss" scoped>
$searchbox-search-offset: -2px;
$searchbox-reset-search-size: 2.5rem;

.searchbox {
  &__entry {
    border-bottom-right-radius: 0;
    border-right-width: 0;
    border-top-right-radius: 0;
    display: inline-block;
    padding-right: $searchbox-reset-search-size;
    width: calc(100% - #{$searchbox-reset-search-size});

    &:focus-visible {
      // prettier-ignore
      &:not( [readonly] ) {
        outline: 0;
      }
    }
  }

  &__reset,
  &__search {
    cursor: pointer;
    height: $searchbox-reset-search-size;
    width: $searchbox-reset-search-size;
  }

  &__reset {
    background-color: transparent;
    border: none;
    color: inherit;
    margin-left: -$searchbox-reset-search-size;

    &--hidden {
      display: none;
    }
  }

  &__search {
    background-color: var(--kw-default-button-background-color);
    border: 1px solid var(--kw-textbox-border-color);
    border-left: 0;
    border-radius: var(--kw-border-inner);
    border-width: 0;
    color: var(--kw-default-button-text-color);
    margin-left: $searchbox-search-offset;

    > i {
      margin-left: $searchbox-search-offset;
    }

    &:disabled {
      background-color: var(--tw-searchbox-background-color-disabled);
      cursor: not-allowed;
    }
  }
}
</style>
