import { UserManager, WebStorageStateStore } from 'oidc-client';
import { IAuthenticator } from './IAuthenticator';
import { AccountProfile } from './AccountProfile';

export class Authenticator implements IAuthenticator {
  private userManager: UserManager;

  public constructor(
    authorityUri: string,
    clientId: string,
    redirectUri: string,
    silentRedirectUri: string,
    scope: string,
    postLogoutRedirectUri: string
  ) {
    this.userManager = new UserManager({
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: authorityUri,
      client_id: clientId,
      redirect_uri: redirectUri,
      silent_redirect_uri: silentRedirectUri,
      response_type: 'id_token token',
      scope: scope + ' openid profile email',
      post_logout_redirect_uri: postLogoutRedirectUri,
      revokeAccessTokenOnSignout: true,
    });
  }

  public async getToken(): Promise<string | null> {
    const user = await this.userManager.getUser();
    if (user === null) {
      return null;
    }
    if (user.expired === true) {
      // eslint-disable-next-line no-console
      console.info('Jwt has expired');
      return null;
    }
    return user.access_token;
  }

  public async getAccountProfile(): Promise<AccountProfile | null> {
    const user = await this.userManager.getUser();
    return user === null ? null : (user.profile as unknown as AccountProfile);
  }

  public async renewToken(): Promise<void> {
    try {
      await this.userManager.signinSilent();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  public async hasTokenExpired(): Promise<boolean> {
    const user = await this.userManager.getUser();
    if (user === null) {
      return true;
    }
    const expired = user.expired;
    return expired !== undefined ? expired : true;
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  public async logout(): Promise<void> {
    const user = await this.userManager.getUser();
    if (user === null) {
      return;
    }
    await this.userManager.signoutRedirect({ id_token_hint: user.id_token });
  }
}
