import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled", "min", "max", "step", "name", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", null, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.hideLabel ? 'sr-only' : null)
    }, _toDisplayString(_ctx.label), 3),
    _createElementVNode("input", {
      disabled: _ctx.disabled,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
      onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
      min: _ctx.min,
      max: _ctx.max,
      step: _ctx.step,
      name: _ctx.name,
      type: "range",
      value: _ctx.inputNumber,
      style: {"width":"97%"}
    }, null, 40, _hoisted_1)
  ]))
}