import { AddressDto, AddressLookupClient } from '@/apiClients';
import ArrayHelper from '@/utilities/arrayHelper';

import authorizedClient from './authorizedClient';

export interface IAddressLookupState {
  results: IAddressResult[];
}

export interface IAddressLookup {
  houseOrNumber: string | null;
  postCode: string | null;
}

interface IAddressResult {
  lookup: IAddressLookup;
  addresses: AddressDto[];
}

export default {
  namespaced: true as true,
  state: () =>
    ({
      results: [],
    }) as IAddressLookupState,
  getters: {
    addressLookup: (state: IAddressLookupState) => (lookup: IAddressLookup) => {
      const result = state.results.find(
        (x) => x.lookup.houseOrNumber === lookup.houseOrNumber && x.lookup.postCode === lookup.postCode
      );
      return result ? result.addresses : [];
    },
  },
  mutations: {
    SET_LOOKUP_RESULT(state: IAddressLookupState, result: IAddressResult) {
      ArrayHelper.pushOrUpdate(
        state.results,
        result,
        (x) => x.lookup.houseOrNumber === result.lookup.houseOrNumber && x.lookup.postCode === result.lookup.postCode
      );
    },
  },
  actions: {
    async getAddresses({ commit, state }: any, addressLookup: IAddressLookup) {
      if (
        (state.results as IAddressResult[]).findIndex(
          (x) => x.lookup.houseOrNumber === addressLookup.houseOrNumber && x.lookup.postCode === addressLookup.postCode
        ) !== -1
      ) {
        return;
      }
      const addressLookupClient = await authorizedClient(AddressLookupClient);
      try {
        const result = await addressLookupClient.getAddresses(
          addressLookup.houseOrNumber,
          addressLookup.postCode,
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION
        );
        if (result) {
          commit('SET_LOOKUP_RESULT', { lookup: addressLookup, addresses: result } as IAddressResult);
        }
      } catch (error) {
        throw error;
      }
    },
  },
};
