<template>
  <span class="tag" :class="[color ? `tag--${color}` : '', { 'tag--pill': pill, 'tag--circle': circle }]">
    <slot />
    <button class="tag__close-button" v-if="!pill && !circle" @click="click" title="Close">
      <i class="icon-cross tag__close"></i>
      <span class="sr-only">Close</span>
    </button>
  </span>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    color: {
      type: String as PropType<'green' | 'orange' | 'purple' | 'red' | 'secondary' | 'grey' | 'primary'>,
      validator: (value: string): boolean =>
        ['green', 'orange', 'purple', 'red', 'secondary', 'grey', 'primary'].includes(value),
    },
    pill: {
      type: Boolean,
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },
  },
  //emits: ['click'],
  setup(props, { emit, slots }) {
    // const handleClick = () => {
    //   emit('click');
    // };

    // return {
    //   click: handleClick,
    //   slots,
    // };
  },
});
</script>

<style lang="scss">
:root {
  --tw-auth-settings-tag-color: var(--kw-color-light-theme-grey-2);
}

.theme--dark {
  --tw-auth-settings-tag-color: var(--kw-color-dark-theme-grey-2);
}
</style>

<style lang="scss" scoped>
.tag--grey {
  background-color: var(--tw-auth-settings-tag-color);
}
</style>
