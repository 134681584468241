import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-785c9fde"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "toggleable-button",
  "data-test": "label-Checkbox"
}
const _hoisted_2 = ["checked"]
const _hoisted_3 = { class: "toggleable-button__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: "toggleable-button__hidden-checkbox",
      checked: _ctx.isChecked,
      type: "checkbox",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isChecked) = $event)),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.input && _ctx.input(...args)))
    }, null, 8, _hoisted_2), [
      [_vModelCheckbox, _ctx.isChecked]
    ]),
    _createElementVNode("button", {
      class: "toggleable-button__inner",
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.input && _ctx.input(...args))),
      type: "button"
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["toggleable-button__icon-wrapper", _ctx.isChecked ? 'toggleable-button__icon-wrapper--checked' : undefined])
      }, [
        _createElementVNode("i", {
          class: _normalizeClass(["toggleable-button__icon", _ctx.icon ? `toggleable-button__icon--${_ctx.icon}` : undefined])
        }, null, 2)
      ], 2),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.label), 1)
    ])
  ]))
}