<template>
  <header class="the-app-header container-fluid">
    <div class="row height--100">
      <section class="the-app-header__logo col-auto height--100 pl-0">
        <router-link to="/" class="d-flex height--100">
          <svg
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="14.91 65.555 561.178 56.89"
            aria-label="Advanced logo"
            role="img"
            style="min-width: 200% !important; position: static; padding-left: 10px"
          >
            <defs>
              <linearGradient
                id="paint0_linear_217_5"
                x1="73.2818"
                y1="82.4254"
                x2="73.2818"
                y2="11.0382"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#E31C79" />
                <stop offset="1" stop-color="#F2A900" />
              </linearGradient>
              <radialGradient
                id="paint0_radial_2_91"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(31 -8.8825) scale(67.4709 67.5866)"
              >
                <stop stop-color="#FCC54C" />
                <stop offset="0.6" stop-color="#F15D22" />
                <stop offset="1" stop-color="#E23F13" />
              </radialGradient>
            </defs>
            <path
              d="M31 25.3531L48.1893 57H62L31 0L0 57H13.8107L31 25.3531Z"
              fill="url(#paint0_radial_2_91)"
              transform="matrix(1, 0, 0, 1, 14.910492, 65.554955)"
            />          
            <path
              class="the-app-header__logo--image__svg-path"
              fill-rule="evenodd"
              d="M 97.269 80.355 L 108.717 80.355 C 111.453 80.355 113.865 80.883 115.953 81.939 C 118.065 82.971 119.697 84.435 120.849 86.331 C 122.025 88.227 122.613 90.435 122.613 92.955 C 122.613 95.475 122.025 97.683 120.849 99.579 C 119.697 101.475 118.065 102.951 115.953 104.007 C 113.865 105.039 111.453 105.555 108.717 105.555 L 97.269 105.555 L 97.269 80.355 Z M 108.429 100.767 C 110.949 100.767 112.953 100.071 114.441 98.679 C 115.953 97.263 116.709 95.355 116.709 92.955 C 116.709 90.555 115.953 88.659 114.441 87.267 C 112.953 85.851 110.949 85.143 108.429 85.143 L 103.101 85.143 L 103.101 100.767 L 108.429 100.767 Z"
            />
            <path
              class="the-app-header__logo--image__svg-path"
              fill-rule="evenodd"
              d="M 145.587 95.943 C 145.587 96.015 145.551 96.519 145.479 97.455 L 130.827 97.455 C 131.091 98.655 131.715 99.603 132.699 100.299 C 133.683 100.995 134.907 101.343 136.371 101.343 C 137.379 101.343 138.267 101.199 139.035 100.911 C 139.827 100.599 140.559 100.119 141.231 99.471 L 144.219 102.711 C 142.395 104.799 139.731 105.843 136.227 105.843 C 134.043 105.843 132.111 105.423 130.431 104.583 C 128.751 103.719 127.455 102.531 126.543 101.019 C 125.631 99.507 125.175 97.791 125.175 95.871 C 125.175 93.975 125.619 92.271 126.507 90.759 C 127.419 89.223 128.655 88.035 130.215 87.195 C 131.799 86.331 133.563 85.899 135.507 85.899 C 137.403 85.899 139.119 86.307 140.655 87.123 C 142.191 87.939 143.391 89.115 144.255 90.651 C 145.143 92.163 145.587 93.927 145.587 95.943 Z M 135.543 90.147 C 134.271 90.147 133.203 90.507 132.339 91.227 C 131.475 91.947 130.947 92.931 130.755 94.179 L 140.295 94.179 C 140.103 92.955 139.575 91.983 138.711 91.263 C 137.847 90.519 136.791 90.147 135.543 90.147 Z"
            />
            <path
              class="the-app-header__logo--image__svg-path"
              fill-rule="evenodd"
              d="M 168.199 86.187 L 160.027 105.555 L 154.231 105.555 L 146.095 86.187 L 151.891 86.187 L 157.255 99.363 L 162.799 86.187 L 168.199 86.187 Z"
            />
            <path
              class="the-app-header__logo--image__svg-path"
              fill-rule="evenodd"
              d="M 170.49 86.187 L 176.106 86.187 L 176.106 105.555 L 170.49 105.555 L 170.49 86.187 Z M 173.298 83.487 C 172.266 83.487 171.426 83.187 170.778 82.587 C 170.13 81.987 169.806 81.243 169.806 80.355 C 169.806 79.467 170.13 78.723 170.778 78.123 C 171.426 77.523 172.266 77.223 173.298 77.223 C 174.33 77.223 175.17 77.511 175.818 78.087 C 176.466 78.663 176.79 79.383 176.79 80.247 C 176.79 81.183 176.466 81.963 175.818 82.587 C 175.17 83.187 174.33 83.487 173.298 83.487 Z"
            />
            <path
              class="the-app-header__logo--image__svg-path"
              fill-rule="evenodd"
              d="M 190.678 105.843 C 188.614 105.843 186.754 105.423 185.098 104.583 C 183.466 103.719 182.182 102.531 181.246 101.019 C 180.334 99.507 179.878 97.791 179.878 95.871 C 179.878 93.951 180.334 92.235 181.246 90.723 C 182.182 89.211 183.466 88.035 185.098 87.195 C 186.754 86.331 188.614 85.899 190.678 85.899 C 192.718 85.899 194.494 86.331 196.006 87.195 C 197.542 88.035 198.658 89.247 199.354 90.831 L 194.998 93.171 C 193.99 91.395 192.538 90.507 190.642 90.507 C 189.178 90.507 187.966 90.987 187.006 91.947 C 186.046 92.907 185.566 94.215 185.566 95.871 C 185.566 97.527 186.046 98.835 187.006 99.795 C 187.966 100.755 189.178 101.235 190.642 101.235 C 192.562 101.235 194.014 100.347 194.998 98.571 L 199.354 100.947 C 198.658 102.483 197.542 103.683 196.006 104.547 C 194.494 105.411 192.718 105.843 190.678 105.843 Z"
            />
            <path
              class="the-app-header__logo--image__svg-path"
              fill-rule="evenodd"
              d="M 221.314 95.943 C 221.314 96.015 221.278 96.519 221.206 97.455 L 206.554 97.455 C 206.818 98.655 207.442 99.603 208.426 100.299 C 209.41 100.995 210.634 101.343 212.098 101.343 C 213.106 101.343 213.994 101.199 214.762 100.911 C 215.554 100.599 216.286 100.119 216.958 99.471 L 219.946 102.711 C 218.122 104.799 215.458 105.843 211.954 105.843 C 209.77 105.843 207.838 105.423 206.158 104.583 C 204.478 103.719 203.182 102.531 202.27 101.019 C 201.358 99.507 200.902 97.791 200.902 95.871 C 200.902 93.975 201.346 92.271 202.234 90.759 C 203.146 89.223 204.382 88.035 205.942 87.195 C 207.526 86.331 209.29 85.899 211.234 85.899 C 213.13 85.899 214.846 86.307 216.382 87.123 C 217.918 87.939 219.118 89.115 219.982 90.651 C 220.87 92.163 221.314 93.927 221.314 95.943 Z M 211.27 90.147 C 209.998 90.147 208.93 90.507 208.066 91.227 C 207.202 91.947 206.674 92.931 206.482 94.179 L 216.022 94.179 C 215.83 92.955 215.302 91.983 214.438 91.263 C 213.574 90.519 212.518 90.147 211.27 90.147 Z"
            />
            <path
              class="the-app-header__logo--image__svg-path"
              fill-rule="evenodd"
              d="M 255.012 105.987 L 251.988 103.179 C 249.588 105.051 246.78 105.987 243.564 105.987 C 241.74 105.987 240.096 105.699 238.632 105.123 C 237.168 104.523 236.016 103.695 235.176 102.639 C 234.36 101.583 233.952 100.395 233.952 99.075 C 233.952 97.515 234.384 96.147 235.248 94.971 C 236.136 93.771 237.552 92.631 239.496 91.551 C 238.584 90.615 237.924 89.727 237.516 88.887 C 237.108 88.023 236.904 87.135 236.904 86.223 C 236.904 84.999 237.228 83.919 237.876 82.983 C 238.524 82.047 239.436 81.327 240.612 80.823 C 241.812 80.295 243.192 80.031 244.752 80.031 C 246.936 80.031 248.688 80.559 250.008 81.615 C 251.328 82.647 251.988 84.051 251.988 85.827 C 251.988 87.123 251.592 88.275 250.8 89.283 C 250.032 90.291 248.784 91.263 247.056 92.199 L 251.664 96.483 C 252.216 95.331 252.636 94.023 252.924 92.559 L 257.424 93.963 C 256.944 96.195 256.176 98.103 255.12 99.687 L 258.072 102.423 L 255.012 105.987 Z M 244.752 83.847 C 243.912 83.847 243.24 84.063 242.736 84.495 C 242.256 84.927 242.016 85.479 242.016 86.151 C 242.016 86.655 242.148 87.135 242.412 87.591 C 242.676 88.023 243.192 88.611 243.96 89.355 C 245.184 88.707 246.036 88.131 246.516 87.627 C 246.996 87.099 247.236 86.535 247.236 85.935 C 247.236 85.311 247.02 84.807 246.588 84.423 C 246.156 84.039 245.544 83.847 244.752 83.847 Z M 243.96 101.595 C 245.712 101.595 247.284 101.103 248.676 100.119 L 242.592 94.431 C 241.464 95.079 240.66 95.715 240.18 96.339 C 239.7 96.963 239.46 97.671 239.46 98.463 C 239.46 99.399 239.868 100.155 240.684 100.731 C 241.5 101.307 242.592 101.595 243.96 101.595 Z"
            />
            <path
              class="the-app-header__logo--image__svg-path"
              fill-rule="evenodd"
              d="M 288.627 100.155 L 276.927 100.155 L 274.695 105.555 L 268.719 105.555 L 279.951 80.355 L 285.711 80.355 L 296.979 105.555 L 290.859 105.555 L 288.627 100.155 Z M 286.791 95.727 L 282.795 86.079 L 278.799 95.727 L 286.791 95.727 Z"
            />
            <path
              class="the-app-header__logo--image__svg-path"
              fill-rule="evenodd"
              d="M 308.382 105.843 C 306.318 105.843 304.458 105.423 302.802 104.583 C 301.17 103.719 299.886 102.531 298.95 101.019 C 298.038 99.507 297.582 97.791 297.582 95.871 C 297.582 93.951 298.038 92.235 298.95 90.723 C 299.886 89.211 301.17 88.035 302.802 87.195 C 304.458 86.331 306.318 85.899 308.382 85.899 C 310.422 85.899 312.198 86.331 313.71 87.195 C 315.246 88.035 316.362 89.247 317.058 90.831 L 312.702 93.171 C 311.694 91.395 310.242 90.507 308.346 90.507 C 306.882 90.507 305.67 90.987 304.71 91.947 C 303.75 92.907 303.27 94.215 303.27 95.871 C 303.27 97.527 303.75 98.835 304.71 99.795 C 305.67 100.755 306.882 101.235 308.346 101.235 C 310.266 101.235 311.718 100.347 312.702 98.571 L 317.058 100.947 C 316.362 102.483 315.246 103.683 313.71 104.547 C 312.198 105.411 310.422 105.843 308.382 105.843 Z"
            />
            <path
              class="the-app-header__logo--image__svg-path"
              fill-rule="evenodd"
              d="M 329.405 105.843 C 327.341 105.843 325.481 105.423 323.825 104.583 C 322.193 103.719 320.909 102.531 319.973 101.019 C 319.061 99.507 318.605 97.791 318.605 95.871 C 318.605 93.951 319.061 92.235 319.973 90.723 C 320.909 89.211 322.193 88.035 323.825 87.195 C 325.481 86.331 327.341 85.899 329.405 85.899 C 331.445 85.899 333.221 86.331 334.733 87.195 C 336.269 88.035 337.385 89.247 338.081 90.831 L 333.725 93.171 C 332.717 91.395 331.265 90.507 329.369 90.507 C 327.905 90.507 326.693 90.987 325.733 91.947 C 324.773 92.907 324.293 94.215 324.293 95.871 C 324.293 97.527 324.773 98.835 325.733 99.795 C 326.693 100.755 327.905 101.235 329.369 101.235 C 331.289 101.235 332.741 100.347 333.725 98.571 L 338.081 100.947 C 337.385 102.483 336.269 103.683 334.733 104.547 C 333.221 105.411 331.445 105.843 329.405 105.843 Z"
            />
            <path
              class="the-app-header__logo--image__svg-path"
              fill-rule="evenodd"
              d="M 360.04 95.943 C 360.04 96.015 360.004 96.519 359.932 97.455 L 345.28 97.455 C 345.544 98.655 346.168 99.603 347.152 100.299 C 348.136 100.995 349.36 101.343 350.824 101.343 C 351.832 101.343 352.72 101.199 353.488 100.911 C 354.28 100.599 355.012 100.119 355.684 99.471 L 358.672 102.711 C 356.848 104.799 354.184 105.843 350.68 105.843 C 348.496 105.843 346.564 105.423 344.884 104.583 C 343.204 103.719 341.908 102.531 340.996 101.019 C 340.084 99.507 339.628 97.791 339.628 95.871 C 339.628 93.975 340.072 92.271 340.96 90.759 C 341.872 89.223 343.108 88.035 344.668 87.195 C 346.252 86.331 348.016 85.899 349.96 85.899 C 351.856 85.899 353.572 86.307 355.108 87.123 C 356.644 87.939 357.844 89.115 358.708 90.651 C 359.596 92.163 360.04 93.927 360.04 95.943 Z M 349.996 90.147 C 348.724 90.147 347.656 90.507 346.792 91.227 C 345.928 91.947 345.4 92.931 345.208 94.179 L 354.748 94.179 C 354.556 92.955 354.028 91.983 353.164 91.263 C 352.3 90.519 351.244 90.147 349.996 90.147 Z"
            />
            <path
              class="the-app-header__logo--image__svg-path"
              fill-rule="evenodd"
              d="M 370.295 105.843 C 368.687 105.843 367.115 105.651 365.579 105.267 C 364.043 104.859 362.819 104.355 361.907 103.755 L 363.779 99.723 C 364.643 100.275 365.687 100.731 366.911 101.091 C 368.135 101.427 369.335 101.595 370.511 101.595 C 372.887 101.595 374.075 101.007 374.075 99.831 C 374.075 99.279 373.751 98.883 373.103 98.643 C 372.455 98.403 371.459 98.199 370.115 98.031 C 368.531 97.791 367.223 97.515 366.191 97.203 C 365.159 96.891 364.259 96.339 363.491 95.547 C 362.747 94.755 362.375 93.627 362.375 92.163 C 362.375 90.939 362.723 89.859 363.419 88.923 C 364.139 87.963 365.171 87.219 366.515 86.691 C 367.883 86.163 369.491 85.899 371.339 85.899 C 372.707 85.899 374.063 86.055 375.407 86.367 C 376.775 86.655 377.903 87.063 378.791 87.591 L 376.919 91.587 C 375.215 90.627 373.355 90.147 371.339 90.147 C 370.139 90.147 369.239 90.315 368.639 90.651 C 368.039 90.987 367.739 91.419 367.739 91.947 C 367.739 92.547 368.063 92.967 368.711 93.207 C 369.359 93.447 370.391 93.675 371.807 93.891 C 373.391 94.155 374.687 94.443 375.695 94.755 C 376.703 95.043 377.579 95.583 378.323 96.375 C 379.067 97.167 379.439 98.271 379.439 99.687 C 379.439 100.887 379.079 101.955 378.359 102.891 C 377.639 103.827 376.583 104.559 375.191 105.087 C 373.823 105.591 372.191 105.843 370.295 105.843 Z"
            />
            <path
              class="the-app-header__logo--image__svg-path"
              fill-rule="evenodd"
              d="M 389.42 105.843 C 387.812 105.843 386.24 105.651 384.704 105.267 C 383.168 104.859 381.944 104.355 381.032 103.755 L 382.904 99.723 C 383.768 100.275 384.812 100.731 386.036 101.091 C 387.26 101.427 388.46 101.595 389.636 101.595 C 392.012 101.595 393.2 101.007 393.2 99.831 C 393.2 99.279 392.876 98.883 392.228 98.643 C 391.58 98.403 390.584 98.199 389.24 98.031 C 387.656 97.791 386.348 97.515 385.316 97.203 C 384.284 96.891 383.384 96.339 382.616 95.547 C 381.872 94.755 381.5 93.627 381.5 92.163 C 381.5 90.939 381.848 89.859 382.544 88.923 C 383.264 87.963 384.296 87.219 385.64 86.691 C 387.008 86.163 388.616 85.899 390.464 85.899 C 391.832 85.899 393.188 86.055 394.532 86.367 C 395.9 86.655 397.028 87.063 397.916 87.591 L 396.044 91.587 C 394.34 90.627 392.48 90.147 390.464 90.147 C 389.264 90.147 388.364 90.315 387.764 90.651 C 387.164 90.987 386.864 91.419 386.864 91.947 C 386.864 92.547 387.188 92.967 387.836 93.207 C 388.484 93.447 389.516 93.675 390.932 93.891 C 392.516 94.155 393.812 94.443 394.82 94.755 C 395.828 95.043 396.704 95.583 397.448 96.375 C 398.192 97.167 398.564 98.271 398.564 99.687 C 398.564 100.887 398.204 101.955 397.484 102.891 C 396.764 103.827 395.708 104.559 394.316 105.087 C 392.948 105.591 391.316 105.843 389.42 105.843 Z"
            />
            <path
              class="the-app-header__logo--image__svg-path"
              fill-rule="evenodd"
              d="M 435.553 105.555 L 435.517 90.435 L 428.101 102.891 L 425.473 102.891 L 418.093 90.759 L 418.093 105.555 L 412.621 105.555 L 412.621 80.355 L 417.445 80.355 L 426.877 96.015 L 436.165 80.355 L 440.953 80.355 L 441.025 105.555 L 435.553 105.555 Z"
            />
            <path
              class="the-app-header__logo--image__svg-path"
              fill-rule="evenodd"
              d="M 454.419 85.899 C 457.419 85.899 459.723 86.619 461.331 88.059 C 462.939 89.475 463.743 91.623 463.743 94.503 L 463.743 105.555 L 458.487 105.555 L 458.487 103.143 C 457.431 104.943 455.463 105.843 452.583 105.843 C 451.095 105.843 449.799 105.591 448.695 105.087 C 447.615 104.583 446.787 103.887 446.211 102.999 C 445.635 102.111 445.347 101.103 445.347 99.975 C 445.347 98.175 446.019 96.759 447.363 95.727 C 448.731 94.695 450.831 94.179 453.663 94.179 L 458.127 94.179 C 458.127 92.955 457.755 92.019 457.011 91.371 C 456.267 90.699 455.151 90.363 453.663 90.363 C 452.631 90.363 451.611 90.531 450.603 90.867 C 449.619 91.179 448.779 91.611 448.083 92.163 L 446.067 88.239 C 447.123 87.495 448.383 86.919 449.847 86.511 C 451.335 86.103 452.859 85.899 454.419 85.899 Z M 453.987 102.063 C 454.947 102.063 455.799 101.847 456.543 101.415 C 457.287 100.959 457.815 100.299 458.127 99.435 L 458.127 97.455 L 454.275 97.455 C 451.971 97.455 450.819 98.211 450.819 99.723 C 450.819 100.443 451.095 101.019 451.647 101.451 C 452.223 101.859 453.003 102.063 453.987 102.063 Z"
            />
            <path
              class="the-app-header__logo--image__svg-path"
              fill-rule="evenodd"
              d="M 480.598 85.899 C 482.998 85.899 484.93 86.619 486.394 88.059 C 487.882 89.499 488.626 91.635 488.626 94.467 L 488.626 105.555 L 483.01 105.555 L 483.01 95.331 C 483.01 93.795 482.674 92.655 482.002 91.911 C 481.33 91.143 480.358 90.759 479.086 90.759 C 477.67 90.759 476.542 91.203 475.702 92.091 C 474.862 92.955 474.442 94.251 474.442 95.979 L 474.442 105.555 L 468.826 105.555 L 468.826 86.187 L 474.19 86.187 L 474.19 88.455 C 474.934 87.639 475.858 87.015 476.962 86.583 C 478.066 86.127 479.278 85.899 480.598 85.899 Z"
            />
            <path
              class="the-app-header__logo--image__svg-path"
              fill-rule="evenodd"
              d="M 501.529 85.899 C 504.529 85.899 506.833 86.619 508.441 88.059 C 510.049 89.475 510.853 91.623 510.853 94.503 L 510.853 105.555 L 505.597 105.555 L 505.597 103.143 C 504.541 104.943 502.573 105.843 499.693 105.843 C 498.205 105.843 496.909 105.591 495.805 105.087 C 494.725 104.583 493.897 103.887 493.321 102.999 C 492.745 102.111 492.457 101.103 492.457 99.975 C 492.457 98.175 493.129 96.759 494.473 95.727 C 495.841 94.695 497.941 94.179 500.773 94.179 L 505.237 94.179 C 505.237 92.955 504.865 92.019 504.121 91.371 C 503.377 90.699 502.261 90.363 500.773 90.363 C 499.741 90.363 498.721 90.531 497.713 90.867 C 496.729 91.179 495.889 91.611 495.193 92.163 L 493.177 88.239 C 494.233 87.495 495.493 86.919 496.957 86.511 C 498.445 86.103 499.969 85.899 501.529 85.899 Z M 501.097 102.063 C 502.057 102.063 502.909 101.847 503.653 101.415 C 504.397 100.959 504.925 100.299 505.237 99.435 L 505.237 97.455 L 501.385 97.455 C 499.081 97.455 497.929 98.211 497.929 99.723 C 497.929 100.443 498.205 101.019 498.757 101.451 C 499.333 101.859 500.113 102.063 501.097 102.063 Z"
            />
            <path
              class="the-app-header__logo--image__svg-path"
              fill-rule="evenodd"
              d="M 536.127 86.187 L 536.127 102.315 C 536.127 105.867 535.203 108.507 533.355 110.235 C 531.507 111.963 528.807 112.827 525.255 112.827 C 523.383 112.827 521.607 112.599 519.927 112.143 C 518.247 111.687 516.855 111.027 515.751 110.163 L 517.983 106.131 C 518.799 106.803 519.831 107.331 521.079 107.715 C 522.327 108.123 523.575 108.327 524.823 108.327 C 526.767 108.327 528.195 107.883 529.107 106.995 C 530.043 106.131 530.511 104.811 530.511 103.035 L 530.511 102.207 C 529.047 103.815 527.007 104.619 524.391 104.619 C 522.615 104.619 520.983 104.235 519.495 103.467 C 518.031 102.675 516.867 101.571 516.003 100.155 C 515.139 98.739 514.707 97.107 514.707 95.259 C 514.707 93.411 515.139 91.779 516.003 90.363 C 516.867 88.947 518.031 87.855 519.495 87.087 C 520.983 86.295 522.615 85.899 524.391 85.899 C 527.199 85.899 529.335 86.823 530.799 88.671 L 530.799 86.187 L 536.127 86.187 Z M 525.507 100.011 C 526.995 100.011 528.207 99.579 529.143 98.715 C 530.103 97.827 530.583 96.675 530.583 95.259 C 530.583 93.843 530.103 92.703 529.143 91.839 C 528.207 90.951 526.995 90.507 525.507 90.507 C 524.019 90.507 522.795 90.951 521.835 91.839 C 520.875 92.703 520.395 93.843 520.395 95.259 C 520.395 96.675 520.875 97.827 521.835 98.715 C 522.795 99.579 524.019 100.011 525.507 100.011 Z"
            />
            <path
              class="the-app-header__logo--image__svg-path"
              fill-rule="evenodd"
              d="M 560.326 95.943 C 560.326 96.015 560.29 96.519 560.218 97.455 L 545.566 97.455 C 545.83 98.655 546.454 99.603 547.438 100.299 C 548.422 100.995 549.646 101.343 551.11 101.343 C 552.118 101.343 553.006 101.199 553.774 100.911 C 554.566 100.599 555.298 100.119 555.97 99.471 L 558.958 102.711 C 557.134 104.799 554.47 105.843 550.966 105.843 C 548.782 105.843 546.85 105.423 545.17 104.583 C 543.49 103.719 542.194 102.531 541.282 101.019 C 540.37 99.507 539.914 97.791 539.914 95.871 C 539.914 93.975 540.358 92.271 541.246 90.759 C 542.158 89.223 543.394 88.035 544.954 87.195 C 546.538 86.331 548.302 85.899 550.246 85.899 C 552.142 85.899 553.858 86.307 555.394 87.123 C 556.93 87.939 558.13 89.115 558.994 90.651 C 559.882 92.163 560.326 93.927 560.326 95.943 Z M 550.282 90.147 C 549.01 90.147 547.942 90.507 547.078 91.227 C 546.214 91.947 545.686 92.931 545.494 94.179 L 555.034 94.179 C 554.842 92.955 554.314 91.983 553.45 91.263 C 552.586 90.519 551.53 90.147 550.282 90.147 Z"
            />
            <path
              class="the-app-header__logo--image__svg-path"
              fill-rule="evenodd"
              d="M 569.428 88.743 C 570.1 87.807 571.001 87.099 572.129 86.619 C 573.281 86.139 574.601 85.899 576.089 85.899 L 576.089 91.083 C 575.465 91.035 575.045 91.011 574.829 91.011 C 573.221 91.011 571.96 91.467 571.048 92.379 C 570.136 93.267 569.681 94.611 569.681 96.411 L 569.681 105.555 L 564.064 105.555 L 564.064 86.187 L 569.428 86.187 L 569.428 88.743 Z"
            />
          </svg>
        </router-link>
      </section>
      <section class="col px-0">
        <slot name="appHeaderContent" />
      </section>
      <notifications />
      <section class="the-app-header__tools border--left border--grey-2">
        <dropdown-menu :dropdownItems="dropdownItems">
          <Btn
            title="User profile"
            ghost
            class="the-app-header__tools__profile"
            data-test="button-AppHeaderToolsProfile"
          >
            <div class="the-app-header__tools__profile-user-icon">
              <i class="icon-user-2"></i>
            </div>
            <i class="icon-chevron-down the-app-header__tools__profile-chevron"></i>
          </Btn>
        </dropdown-menu>
      </section>
      <a href="https://adaam.helpdocs.io/" target="_blank">
        <Btn icon="question" title="Help Documents" ghost class="help" />
      </a>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import { Btn, DropdownMenu } from '@/components';
import Notifications from './Notifications.vue';
import Theme from '@/utilities/theme';

export default defineComponent({
  components: { Btn, Notifications, dropdownMenu: DropdownMenu },
  props: {
    accountUri: String,
  },
  emits: ['logoutClicked'],
  setup(props, { emit }) {
    const isProfileDropdownDisplayed = ref(false);
    const theme = ref('');

    const logoutClicked = () => {
      emit('logoutClicked');
    };

    const dropdownItems = computed(() => {
      return [
        ...(props.accountUri
          ? [
              {
                name: 'User profile',
                action: openUriInNewPage,
                icon: 'user-2',
              },
            ]
          : []),
        ...(isThemingAllowed.value
          ? [
              {
                name: `${theme.value === 'light' ? 'Dark' : 'Light'} theme`,
                action: switchTheme,
                icon: `${theme.value === 'light' ? 'moon' : 'lamp'}`,
              },
            ]
          : []),
        {
          name: 'Logout',
          action: logoutClicked,
          icon: 'logout',
        },
      ];
    });

    onMounted(() => {
      if (getTheme() == null) {
        if (isThemingAllowed.value) {
          const preferredTheme = matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
          setTheme(preferredTheme);
        } else {
          setTheme('dark');
        }
      } else {
        setTheme(getTheme());
      }
    });

    function openUriInNewPage(): void {
      if (props.accountUri) {
        window.open(props.accountUri, '_blank');
      }
    }

    function getTheme(): string {
      return window.localStorage.getItem(Theme.localStorageThemeVar)!;
    }

    function setTheme(themeVal: string): void {
      window.localStorage.setItem(Theme.localStorageThemeVar, themeVal)!;
      theme.value = themeVal;

      if (themeVal === 'dark') {
        document.querySelector('html')!.classList.add('theme--dark');
      } else if (themeVal === 'light') {
        document.querySelector('html')!.classList.remove('theme--dark');
      } else throw new Error(`Incorrect theme set: ${getTheme()}`);
    }

    function switchTheme(): void {
      if (getTheme() === 'light') setTheme('dark');
      else if (getTheme() === 'dark') setTheme('light');
      else throw new Error(`Incorrect theme set: ${getTheme()}`);
    }

    const isThemingAllowed = computed(() => {
      return process.env.VUE_APP_THEMING === 'false' ? false : true;
    });

    return {
      isProfileDropdownDisplayed,
      theme,
      logoutClicked,
      dropdownItems,
      getTheme,
      setTheme,
      switchTheme,
      isThemingAllowed,
    };
  },
});
</script>
<style lang="scss" scoped>
.help {
  font-size: 190%;
  margin-top: 1.75rem;
  margin-right: 1rem;
}

.bar-align {
  margin-left: 1.25rem;
}
</style>
