import { DeviceHardware, DeviceHardwareClient } from '@/apiClients';

import authorizedClient from './authorizedClient';

export interface IDeviceHardwareState {
  hardwareDevices: DeviceHardware[];
}

export default {
  namespaced: true,
  state: (): IDeviceHardwareState =>
    ({
      hardwareDevices: [],
    }) as IDeviceHardwareState,
  getters: {
    hardwareDevices: (state: IDeviceHardwareState) => () => state.hardwareDevices,
  },
  mutations: {
    SET_HARDWARE_DEVICES(state: IDeviceHardwareState, hardwareDevices: DeviceHardware[]) {
      state.hardwareDevices = hardwareDevices;
    },
  },
  actions: {
    async getHardwareDevices({ commit }: { commit: any }, deviceId: string) {
      const deviceHardwareClient = await authorizedClient(DeviceHardwareClient);
      try {
        const hardwareDevices = await deviceHardwareClient.getHardwareDevices(
          deviceId,
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION
        );
        if (hardwareDevices) {
          commit('SET_HARDWARE_DEVICES', hardwareDevices);
        }
      } catch (error) {
        throw error;
      }
    }
  }
};
