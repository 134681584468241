<!-- TODO: Component is broken & needs fixing. See https://mitrefinch.visualstudio.com/CloudGuard/_workitems/edit/27832 -->

<template>
  <label
    :class="[status ? `checkbox--${status}` : '', visualIcon ? 'checkbox--visual' : '']"
    class="checkbox"
    data-test="label-Checkbox"
  >
    <span v-if="!visualIcon" class="checkbox__label" :class="hideLabel ? 'sr-only' : null">{{ label }}</span>
    <input
      @change="onInput"
      @focus="onFocus"
      @input="onInput"
      :disabled="disabled"
      :name="name"
      :readonly="readonly"
      :class="visualIcon ? 'checkbox--visual__input' : 'checkbox__input'"
      type="checkbox"
      :checked="isChecked"
      :value="isChecked"
      v-model="isChecked"
    />
    <span :class="visualIcon ? `checkbox--visual__icon icon-${visualIcon}` : 'checkbox__tick'"></span>
    <span v-if="visualIcon" class="checkbox__label" :class="hideLabel ? 'sr-only' : null">{{ label }}</span>
    <span v-if="message" class="checkbox__message">{{ message }}</span>
  </label>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted, onBeforeMount } from 'vue';

export default defineComponent({
  name: 'Checkbox',
  props: {
    value: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    name: String,
    readonly: { type: Boolean, default: false },
    hideLabel: { type: Boolean, default: false },
    label: { type: String, required: true },
    visualIcon: String,
    message: String,
    status: {
      type: String,
      validator: (value: string): boolean => ['error', 'success'].includes(value),
    },
  },
  setup(props, { emit }) {
    const isChecked = ref(false);

    watch(
      () => props.value,
      () => {
        isChecked.value = props.value;
      }
    );

    const onInput = (): boolean => {
      return isChecked.value;
    };

    const onFocus = (event: Event): HTMLInputElement => {
      return event.target as HTMLInputElement;
    };

    onBeforeMount(() => {
      if (props.name) {
        emit('emit-name', props.name);
      }
    });

    onMounted(() => {
      if (props.readonly && props.disabled) {
        throw new Error('The readonly prop and the disabled prop cannot both be set.');
      }

      if (props.value === undefined) {
        isChecked.value = false;
      } else {
        isChecked.value = props.value;
      }
    });

    return {
      isChecked,
      onInput,
      onFocus,
    };
  },
});
</script>
