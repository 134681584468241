import {
  CreateUserGroupDto,
  ModifyUserGroupDto,
  UserGroupDto,
  UserGroupSummaryDto,
  UserGroupsClient,
  UserGroupSummaryListMetaDataDto,
  ModifyUserGroupMembersDto,
  ApiResponseOfGuid,
} from '@/apiClients';
import { Dictionary } from '@/extensions/types';
import { forceStringVal } from '@/utilities/strings';
import authorizedClient from './authorizedClient';

export interface IUserGroupsState {
  userGroups: Dictionary<UserGroupDto>;
  userGroupSummaries: Dictionary<UserGroupSummaryDto>;
  metaData: UserGroupSummaryListMetaDataDto;
}

export interface IGetUserGroupSummariesPayload {
  pageOffset: number;
  pageSize: number;
  searchTerm: string;
}

export interface IModifyUserGroupMembersPayload {
  id: string;
  dto: ModifyUserGroupMembersDto;
}

export default {
  namespaced: true as true,
  state: () =>
    ({
      userGroups: {},
      userGroupSummaries: {},
      metaData: new UserGroupSummaryListMetaDataDto({ filteredTotal: 0 }),
    }) as IUserGroupsState,
  getters: {
    userGroup:
      (state: IUserGroupsState) =>
      (id: string): UserGroupDto | undefined => {
        return state.userGroups[id];
      },
      metaData: (state: IUserGroupsState) => () => state.metaData,
  },
  mutations: {
    ADD_USER_GROUP(state: IUserGroupsState, userGroup: UserGroupDto) {
      state.userGroups[userGroup.id] = userGroup;
    },
    REMOVE_USER_GROUP(state: IUserGroupsState, id: string) {
      delete state.userGroups[id];
    },
    ADD_USER_GROUP_SUMMARIES(state: IUserGroupsState, userGroupSummaries: UserGroupSummaryDto[]) {
      userGroupSummaries.forEach((summary) => {
        state.userGroupSummaries[summary.id] = summary;
      });
    },
    ADD_USER_GROUP_METADATA(state: IUserGroupsState, metaData: UserGroupSummaryListMetaDataDto) {
      state.metaData = metaData;
    },
  },
  actions: {
    async getUserGroup({ commit }: any, id: string) {
      const userGroupsClient = await authorizedClient(UserGroupsClient);
      try {
        const userGroup = await userGroupsClient.getUserGroup(id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
        if (userGroup) {
          commit('ADD_USER_GROUP', userGroup);
        }
      } catch (error) {
        throw error;
      }
    },
    async getUserGroupSummaries({ commit }: any, payload: IGetUserGroupSummariesPayload) {
      const userGroupsClient = await authorizedClient(UserGroupsClient);
      try {
        const result = await userGroupsClient.getAllUserGroupsSummary(
          payload.pageOffset,
          payload.pageSize,
          payload.searchTerm,
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION
        );

        if (result) {
          if (result.userGroupSummaries) {
            commit('ADD_USER_GROUP_SUMMARIES', result.userGroupSummaries);
          }
          if (result.metaData) {
            commit('ADD_USER_GROUP_METADATA', result.metaData);
          }
          return result.userGroupSummaries;
        }
      } catch (error) {
        throw error;
      }
      return [];
    },
    async createUserGroup({ commit }: any, userGroup: CreateUserGroupDto): Promise<ApiResponseOfGuid> {
      const userGroupsClient = await authorizedClient(UserGroupsClient);
      try {
        return (await userGroupsClient.create(
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION,
          userGroup
        )) as ApiResponseOfGuid;
      } catch (error) {
        throw error;
      }
    },
    async deleteUserGroup({ commit }: any, id: string) {
      const userGroupsClient = await authorizedClient(UserGroupsClient);
      try {
        await userGroupsClient.delete(id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
        commit('REMOVE_USER_GROUP', id);
      } catch (error) {
        throw error;
      }
    },
    async modifyUserGroup({ commit }: any, userGroup: UserGroupDto) {
      if (userGroup.id === null || userGroup.id === undefined) {
        throw Error('Invalid user group ID; user group ID must not be null nor undefined');
      }
      const userGroupsClient = await authorizedClient(UserGroupsClient);
      const members = userGroup.members?.map((x) => x.userId);
      const tags = userGroup.tags?.map((x) => x.tagId);
      const modifyDto = new ModifyUserGroupDto({
        name: forceStringVal(userGroup.name),
        tags: tags ? tags : [],
        members: members ? members : [],
        userGroupType: userGroup.userGroupType,
      });

      await userGroupsClient.modify(userGroup.id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION, modifyDto);
      commit('REMOVE_USER_GROUP', userGroup.id);
    },
    async modifyUserGroupMembers({ commit }: any, { id, dto }: IModifyUserGroupMembersPayload) {
      try {
        const userGroupsClient = await authorizedClient(UserGroupsClient);
        await userGroupsClient.modifyUserGroupMembers(id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION, dto);
      } catch (error) {
        throw error;
      }
    },
  },
};
