<template>
  <label v-on-clickaway="closeDropdown" class="product-select">
    <span class="sr-only">Advanced product</span>
    <div
      class="product-select__selector"
      :class="[productIconClass(selectedProduct()), { 'product-select__selector--expanded': isListDisplayed }]"
      aria-haspopup="listbox"
      role="button"
      tabindex="0"
      @click="handleDropdownClick"
      @keydown.space.prevent="handleDropdownClick"
      v-if="router.currentRoute.value.meta.sidebar !== false"
      :title="`${selectedProduct.name}`"
    >
      <div class="product-name">{{ selectedProduct().displayname }}</div>
    </div>

    <div v-if="isListDisplayed" class="product-select__dropdown">
      <ul class="product-select__list" role="listbox">
        <li
          v-for="(MfProduct, index) in MfProducts"
          :key="MfProduct.name"
          class="product-select__item"
          :aria-setsize="MfProducts.length"
          :aria-posinset="index + 1"
          :aria-selected="MfProduct.selected ? 'true' : undefined"
          :title="`${MfProduct.name}`"
          role="option"
        >
          <a :href="MfProduct.uri" :class="productIconClass(MfProduct)">
            <div class="product-name">{{ MfProduct.name }}</div>
          </a>
        </li>
      </ul>
    </div>
  </label>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import apiUrl from '@/utilities/apiHelper';
import { useRouter } from 'vue-router';

interface MfProduct {
  name: string;
  uri: string;
  selected: boolean;
  iconname: string;
  displayname: string;
}

export default defineComponent({
  setup() {
    const isListDisplayed = ref(false);
    const router = useRouter();

    const MfProducts: MfProduct[] = [
      {
        name: 'User Admin',
        uri: process.env.VUE_APP_AUTH_AUTHORITY_URI,
        selected: false,
        iconname: 'mfaccount',
        displayname: 'User Admin',
      },
      {
        name: 'Device & Access Manager',
        uri: apiUrl(),
        selected: true,
        iconname: 'mfsecure',
        displayname: 'Device & Access',
      },
    ];

    const selectedProduct = () => {
      return MfProducts.filter((product) => product.selected === true)[0];
    };

    const productIconClass = (product: MfProduct): string => {
      const iconname = product.iconname;

      // Return product name as lowercase with no spaces appended to the CSS class
      return `product-select__icon--${iconname}`;
    };

    const handleDropdownClick = (): void => {
      isListDisplayed.value = !isListDisplayed.value;
    };

    const closeDropdown = (): void => {
      isListDisplayed.value = false;
    };

    return {
      isListDisplayed,
      MfProducts,
      router,
      selectedProduct,
      productIconClass,
      handleDropdownClick,
      closeDropdown,
    };
  },
});
</script>

<style lang="scss">
:root {
  --tw-product-select-mfaccount: var(--kw-color-dark-theme-grey-3);
  --tw-product-select-mfsecure: var(--kw-color-orange);

  --tw-product-select-bg-color: var(--kw-color-white);
  --tw-product-select-list-bg-color: var(--kw-color-white);
  --tw-product-select-border-color: var(--kw-color-light-theme-grey-2);
  --tw-product-select-border-color-focus: var(--kw-color-light-theme-grey-4);
  --tw-product-select-text-color: var(--kw-color-light-theme-grey-4);
  --tw-product-select-chevron-color: var(--kw-color-light-theme-grey-3);
  --tw-product-select-item-bg-color-selected: var(--kw-color-blue-o20);
  --tw-product-select-item-bg-color-hover: var(--kw-color-light-theme-grey-2-o30);
}

.theme--dark {
  --tw-product-select-bg-color: var(--kw-color-dark-theme-grey-1);
  --tw-product-select-list-bg-color: var(--kw-color-dark-theme-grey-2);
  --tw-product-select-border-color: var(--kw-color-dark-theme-grey-3);
  --tw-product-select-border-color-focus: var(--kw-color-white);
  --tw-product-select-text-color: var(--kw-color-white);
  --tw-product-select-chevron-color: var(--kw-color-dark-theme-grey-3);
  --tw-product-select-item-bg-color-selected: var(--kw-color-blue-o20);
  --tw-product-select-item-bg-color-hover: var(--kw-color-dark-theme-grey-3-o20);
}
</style>

<style lang="scss" scoped>
%product-icon {
  content: '';
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
  margin-left: 1rem;
  padding-right: 0.75rem;
}

.product-name {
  padding-left: 8px;
  padding-right: 8px;
}

.product-select {
  position: relative;
  display: block;
  font-weight: 700;
  user-select: none;
  width: fit-content;

  &__selector {
    font-size: 0.875rem;
    line-height: 1.375rem;
    background-color: var(--tw-product-select-bg-color);
    border: 1px solid var(--tw-product-select-border-color);
    color: var(--tw-product-select-text-color);
    cursor: pointer;
    height: 2.5rem;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;

    &::after {
      color: var(--tw-product-select-chevron-color);
      content: '\e90e'; // Down chevron
      font-family: var(--kw-mf-icons-font-family);
      font-size: 2rem;
    }

    &:focus {
      border-color: var(--tw-product-select-border-color-focus);
    }

    &--expanded {
      border-color: var(--tw-product-select-border-color-focus);
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &__dropdown {
    background-color: var(--tw-product-select-list-bg-color);
    color: var(--tw-product-select-text-color);
    border: 1px solid var(--tw-product-select-border-color);
    left: 0;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 999;
  }

  &__list {
    margin: 0;
    padding: 0;
  }

  &__item {
    display: block;
    align-items: center;
    padding: 8px 0 8px 0;

    a {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: baseline;
    }

    &[aria-selected] {
      background-color: var(--tw-product-select-item-bg-color-selected) !important;
    }

    &:hover,
    &:focus {
      background-color: var(--tw-product-select-item-bg-color-hover);
    }
  }

  &__icon {
    &--mfsecure {
      &::before {
        @extend %product-icon;
        background-color: var(--tw-product-select-mfsecure);
      }
    }

    &--mfaccount {
      &::before {
        @extend %product-icon;
        background-color: var(--tw-product-select-mfaccount);
      }
    }
  }
}
</style>
