import { EmailClient, EmailAddressesDto } from '@/apiClients';
import authorizedClient from './authorizedClient';

export default {
  namespaced: true as true,

  actions: {
    async sendTestEmail({ commit }: any, emailAddresses: EmailAddressesDto) {
      const emailClient = await authorizedClient(EmailClient);
      try {
        await emailClient.sendTestEmail(
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION,
          emailAddresses        
        );
      } catch (error) {
        throw error;
      }
    },
  },
};
