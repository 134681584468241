<template>
  <section class="kw-collapsible-section" :class="{ 'kw-collapsible-section--expanded': isSectionBodyDisplayed }">
    <component :is="headingTag" class="kw-collapsible-section__heading">
      <button
        class="kw-collapsible-section__button"
        :aria-expanded="isSectionBodyDisplayed"
        :aria-controls="collapsibleSectionId"
        @click="isSectionBodyDisplayed = !isSectionBodyDisplayed"
      >
        <span class="kw-collapsible-section__heading-text">{{ header }}</span>
        <i
          class="kw-collapsible-section__chevron"
          :class="`icon-chevron-${isSectionBodyDisplayed ? 'up' : 'down'}`"
          aria-hidden="true"
        ></i>
      </button>
    </component>
    <div v-if="isSectionBodyDisplayed" class="kw-collapsible-section__body" :id="collapsibleSectionId">
      <slot />
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, Prop, ref } from 'vue';

export default defineComponent({
  name: 'CollapsibleSection',

  props: {
    header: {
      type: String,
      required: true,
    },
    headingTag: {
      type: String,
      required: true,
      validator: (value: string): boolean => ['h2', 'h3', 'h4', 'h5', 'h6'].includes(value),
    },
  },

  setup(props) {
    const isSectionBodyDisplayed = ref(false);

    function toggleSection() {
      isSectionBodyDisplayed.value = !isSectionBodyDisplayed.value;
    }

    function collapsibleSectionId(): string {
      return `collapsible-section-for-${props.header.toLowerCase().replace(/\s/g, '-')}`;
    }

    return {
      isSectionBodyDisplayed,
      toggleSection,
      collapsibleSectionId,
    };
  },
});
</script>
