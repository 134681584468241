import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "toggle" }
const _hoisted_2 = { class: "sr-only" }
const _hoisted_3 = ["checked", "value", "disabled"]
const _hoisted_4 = ["tabindex"]
const _hoisted_5 = {
  key: 0,
  class: "toggle__message toggle__message--checked"
}
const _hoisted_6 = {
  key: 1,
  class: "toggle__message toggle__message--unchecked"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createElementVNode("input", {
      checked: _ctx.modelValue,
      value: _ctx.modelValue,
      class: "toggle__input",
      disabled: _ctx.disabled,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
      onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
      onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
      type: "checkbox"
    }, null, 40, _hoisted_3),
    _createElementVNode("span", {
      class: "toggle__slider",
      tabindex: _ctx.disabled ? undefined : '0',
      onKeydown: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onSpace && _ctx.onSpace(...args)), ["space"]))
    }, null, 40, _hoisted_4),
    (_ctx.checkedMessage && _ctx.uncheckedMessage)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.checkedMessage), 1))
      : _createCommentVNode("", true),
    (_ctx.checkedMessage && _ctx.uncheckedMessage)
      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.uncheckedMessage), 1))
      : _createCommentVNode("", true)
  ]))
}