import {
  CreateDeviceGroupDto,
  DeviceGroupDto,
  DeviceGroupsClient,
  ModifyDeviceGroupDto,
  TimeZonesClient,
  TimeZoneDto,
} from '@/apiClients';
import ArrayHelper from '@/utilities/arrayHelper';
import { forceStringVal } from '@/utilities/strings';
import { id } from 'date-fns/locale';
import authorizedClient from './authorizedClient';

export interface ITimeZoneState {
  timeZoneList: TimeZoneDto[];
}

export default {
  namespaced: true as true,
  state: () =>
    ({
      timeZoneList: [],
    }) as ITimeZoneState,
  getters: {
    timeZoneList: (state: ITimeZoneState) => () => {
      return state.timeZoneList;
    },
  },
  mutations: {
    ADD_TIMEZONE(state: ITimeZoneState, timeZone: TimeZoneDto[]) {
      state.timeZoneList = timeZone;
    },
  },

  actions: {
    async getTimeZone({ commit }: any) {
      const timeZonesClient = await authorizedClient(TimeZonesClient);
      try {
        const timeZone = await timeZonesClient.getAllTimeZones(process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
        if (timeZone) {
          commit('ADD_TIMEZONE', timeZone);
        }
      } catch (error) {
        throw error;
      }
    },
  },
};
