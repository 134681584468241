<template>
  <label class="textbox" :class="required ? 'textbox--required' : null">
    <span :class="hideLabel ? 'sr-only' : null">
      {{ label }}
      <template v-if="required">
        <i class="textbox__asterisk" aria-hidden="true">*</i><i class="sr-only">(required)</i>
      </template>
    </span>
    <span class="textbox__entry textbox__icon pl-0" :class="[status ? 'textbox--' + status : '']">
      <input
        class="textbox__input"
        :disabled="disabled"
        :readonly="readonly"
        :required="required"
        type="date"
        v-model="inputDate"
        @blur="onBlur"
        @change="onChange"
        @input="onInput"
        onkeydown="return false"
      />
    </span>
    <span v-if="message" class="textbox__message">{{ message }}</span>
  </label>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, PropType } from 'vue';
import { format } from 'date-fns';

interface DateboxProps {
  disabled?: boolean;
  readonly?: boolean;
  hideLabel?: boolean;
  label: string;
  value?: Date;
  message?: string;
  required?: boolean;
  status?: string | undefined;
}

export default defineComponent({
  name: 'Datebox',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Date
    },
    message: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: undefined,
      validator: (value: string): boolean => ['success', 'error'].includes(value),
    }
  },
  // emits: ['blur', 'change', 'input'],
  setup(props: DateboxProps, { emit }) {
    const minDateValue = new Date('0001-01-01T00:00:00.000Z');
    const inputDate = ref('');

    const onBlur = (event: Event) => {
      emit('update:modelValue', (event.target as HTMLInputElement).valueAsDate);
    };

    const onChange = (event: Event) => {
      emit('update:modelValue', (event.target as HTMLInputElement).valueAsDate);
    };

    const onInput = (event: Event) => {
      const value = (event.target as HTMLInputElement).valueAsDate;
      emit('update:modelValue', value == null ? minDateValue : new Date(value));
    };

    const dateToISOString = (date): string => {
      return date && format(date, 'yyyy-MM-dd');
    };

    onMounted(() => {
      if (props.readonly && props.disabled) {
        throw new Error('The readonly prop and the disabled prop cannot both be set.');
      }

      if (props.value) {
        const parsedDate = new Date(props.value);
        if (!isNaN(parsedDate.getTime())) {
          inputDate.value = dateToISOString(parsedDate);
        }
      }
    });

    return {
      inputDate,
      onBlur,
      onChange,
      onInput,
    };
  },
});
</script>
