<template>
  <modal @close="negativeClicked" :showClose="showClose" aria-modal="true" role="dialog">
    <template v-slot:modalHeader>
      <h2>
        <div class="title-text">
          {{ title }}
        </div>
        <div class="name-text">
          {{ name }}
        </div>
      </h2>
    </template>
    <template v-slot:modalBody>
      <div v-if="warningMessage" class="warning-message">
        <p class="warning-message__inner">
          <span class="warning-message__icon">
            <i class="icon-exclamation" />
          </span>
          <span class="warning-message__text">{{ warningMessage }}</span>
        </p>
      </div>
      <p v-if="messages && messages.length === 1" class="text-align--center">{{ messages[0] }}</p>
      <ul v-if="messages && messages.length > 1" class="list-style--none pl-0 text-align--center">
        <li
          v-for="(message, index) in messages"
          :key="index"
          :aria-setsize="messages.length"
          :aria-posinset="index + 1"
        >
          {{ message }}
        </li>
      </ul>
      <p v-if="noteMessage" class="note-message">{{ noteMessage }}</p>
    </template>
    <template v-slot:modalFooter>
      <div v-if="showConfirm" id="modal-confirmation" class="row align-items-center justify-content-center">
        <div class="col-auto">
          <checkbox :label="showConfirm" v-model="isConfirmed" />
        </div>
      </div>
      <div class="row align-items-center justify-content-center text-align--center">
        <div class="col-auto mt-3">
          <button
            type="button"
            class="button button--shadow"
            :class="[positiveButtonColor ? 'button--' + positiveButtonColor : '']"
            id="modal-positive-button"
            data-test="button-alertModalButtonPositive"
            @click="positiveClicked"
            :disabled="(showConfirm && !isConfirmed) || undefined"
          >
            {{ positiveButtonText }}
          </button>
        </div>
        <div class="col-auto mt-3" v-if="neutralButtonText">
          <button type="button" class="button button--shadow button--secondary" @click="neutralClicked">
            {{ neutralButtonText }}
          </button>
        </div>
        <div v-if="!hideNegativeButton" class="col-auto mt-3">
          <button
            type="button"
            data-test="button-alertModalButtonNegative"
            class="button button--shadow"
            :class="[negativeButtonColor !== 'blue' ? 'button--' + negativeButtonColor : '']"
            @click="negativeClicked"
          >
            {{ negativeButtonText }}
          </button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from 'vue';
import Checkbox from '@/components/FormFields/Checkbox.vue';
import Modal from '@/components/Modal.vue';

interface AlertModalProps {
  title: string;
  name: string;
  warningMessage?: string;
  messages?: any[];
  noteMessage?: string;
  positiveButtonText?: string;
  positiveButtonColor?: string;
  negativeButtonText?: string;
  negativeButtonColor?: string;
  hideNegativeButton?: boolean;
  neutralButtonText?: string | null;
  showClose?: boolean;
  showConfirm?: string;
}

export default defineComponent({
  components: { Checkbox, Modal },
  props: {
    title: { type: String, required: true },
    name: { type: String, default: '' },
    warningMessage: { type: String, default: '' },
    messages: Array,
    noteMessage: { type: String, default: '' },
    positiveButtonText: { type: String, default: 'OK' },
    positiveButtonColor: {
      type: String,
      validator: (value: string): boolean => ['green', 'orange', 'purple', 'red', 'secondary'].includes(value),
    },
    negativeButtonText: { type: String, default: 'Cancel' },
    negativeButtonColor: {
      type: String,
      default: 'secondary',
      validator: (value: string): boolean => ['blue', 'green', 'orange', 'purple', 'red', 'secondary'].includes(value),
    },
    hideNegativeButton: { type: Boolean, default: false },
    neutralButtonText: { type: String, default: null },
    showClose: { type: Boolean, default: false },
    showConfirm: String,
  },
  setup(props: AlertModalProps, { emit }) {
    const isConfirmed = ref(!props.showConfirm ? true : false);

    const positiveClicked = () => {
      emit('positiveClicked');
    };

    const negativeClicked = () => {
      emit('negativeClicked');
    };

    const neutralClicked = () => {
      emit('neutralClicked');
    };

    return {
      isConfirmed,
      positiveClicked,
      negativeClicked,
      neutralClicked,
    };
  },
});
</script>

<style lang="scss">
:root {
  --tw-note-message-color: var(--kw-color-light-theme-grey-3);
}

.theme--dark {
  --tw-note-message-color: var(--kw-color-dark-theme-grey-4);
}
</style>

<style lang="scss" scoped>
.title-text {
  word-wrap: break-word;
  font-size: 1.125rem;
  line-height: 1.75rem;
  overflow-y: auto;
  max-height: 300px;
}

.name-text {
  word-wrap: break-word;
  overflow-y: auto;
  max-height: 300px;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.6rem;
}

.vertical-align--middle {
  vertical-align: middle;
}

.warning-message {
  display: flex;
  justify-content: center;

  &__inner {
    display: flex;
    border: 1px solid var(--kw-color-red);
    border-radius: var(--kw-border-inner);
    padding: 0.5rem 0.75rem;
    color: var(--kw-color-red);
    font-weight: 700;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    flex: 0 0 content;
    height: 2rem;
    border-radius: var(--kw-border-inner);
    padding: 0.125rem;
    background-color: var(--kw-color-red);
    color: var(--kw-color-white);
  }

  &__text {
    flex: 0 1 auto;
    padding-left: 1rem;
  }
}

.note-message {
  text-align: center;
  color: var(--tw-note-message-color);
  font-size: 0.75rem;
}
</style>
