<template>
  <div class="message" :class="color ? `message--${color}` : ''" role="alert" data-test="div-alertMessage">
    <slot />
    <Btn v-if="showClose" @click="click" class="message__close" ghost title="Close">
      <i class="icon-cross"></i>
      <span class="sr-only">Close</span>
    </Btn>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Btn from './Btn.vue';

export default defineComponent({
  components: { Btn },
  props: {
    color: {
      type: String,
      validator: (value: string): boolean => ['green', 'orange', 'purple', 'red'].includes(value),
    },
    showClose: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const click = () => {
      emit('click');
    };

    return {
      click,
    };
  },
});
</script>
