<template>
  <div class="activity-loader">
    <loader-spinner />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoaderSpinner from '@/components/LoaderSpinner.vue';

export default defineComponent({
  name: 'ActivityLoader',
  components: {
    LoaderSpinner,
  },
});
</script>

<style lang="scss" scoped>
.activity-loader {
  align-items: center;
  background-color: var(--kw-modal-mask-background-color);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
</style>
