<template>
  <component
    :is="to ? 'router-link' : 'button'"
    :to="to"
    :class="[
      ghost ? 'button--ghost' : 'button',
      color ? `button--${color}` : '',
      {
        'button--create': create && small,
        'button--create-large': create && !small,
        'button--shadow': shadow,
        'button--small': !create && small,
        'button--square': square,
        'width--100': fullWidth,
        'button--compact': compact,
        'button--smart-filter': smartFilter,
      },
    ]"
    :type="!to && type"
    :form="!to && form"
    :disabled="!to && disabled"
  >
    <slot v-if="!iconBeforeText" />
    <i v-if="icon || create" :class="`icon-${create ? 'plus' : icon}`"></i>
    <slot v-if="iconBeforeText" />
  </component>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from 'vue';

export default defineComponent({
  name: 'Btn',
  props: {
    color: {
      type: String as PropType<string>,
      validator: (value: string): boolean => ['green', 'orange', 'purple', 'red', 'secondary'].includes(value),
      readOnly: true,
    },
    create: {
      type: Boolean,
      default: false,
      readOnly: true,
    },
    fullWidth: {
      type: Boolean,
      default: false,
      readOnly: true,
    },
    icon: {
      type: String as PropType<string>,
        readOnly: true,
    },
    shadow: {
      type: Boolean,
      default: false,
      readOnly: true,
    },
    small: {
      type: Boolean,
      default: false,
      readOnly: true,
    },
    square: {
      type: Boolean,
      default: false,
      readOnly: true,
    },
    type: {
      type: String as PropType<string>,
      default: 'button',
      validator: (value: string): boolean => ['button', 'reset', 'submit'].includes(value),
      readOnly: true,
    },
    form: {
      type: String as PropType<string>,
        readOnly: true,
    },
    disabled: {
      type: Boolean,
      default: false,
      readOnly: true,
    },
    compact: {
      type: Boolean,
      default: false,
      readOnly: true,
    },
    iconBeforeText: {
      type: Boolean,
      default: false,
      readOnly: true,
    },
    smartFilter: {
      type: Boolean,
      default: false,
      readOnly: true,
    },
    ghost: {
      type: Boolean,
      default: false,
      readOnly: true,
    },
    to: {
      type: [Object, String] as PropType<object | string>,
        readOnly: true,
    },
  },
});
</script>
