<template>
  <figure class="profile-picture" :class="size !== 'thumbnail' ? `profile-picture--${size}` : ''">
    <img
      v-if="showImage"
      :src="imageUrl"
      :alt="`${getFullname}'s profile picture`"
      :title="getFullname"
      loading="lazy"
    />
    <span v-else>{{ getInitials }}</span>
  </figure>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, Prop, ref } from 'vue';

interface Props {
  firstName?: string;
  surname?: string;
  size?: 'thumbnail' | 'small';
  imageUrl?: string | null;
}

export default defineComponent({
  name: 'ProfilePicture',
  props: {
    firstName: {
      type: String,
      required: false,
    },
    surname: {
      type: String,
      required: false,
    },
    size: {
      type: String as () => 'thumbnail' | 'small',
      default: 'thumbnail',
      validator: (value: string): boolean => ['thumbnail', 'small'].includes(value),
    },
    imageUrl: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props: Readonly<Props>) {
    const showImage = ref(false);

    const getInitials = computed((): string => {
      const firstCharacter = (word: string): string => {
        return word.charAt(0).toUpperCase();
      };

      if (props.firstName || props.surname) {
        return `${props.firstName ? firstCharacter(props.firstName) : ''}${
          props.surname ? firstCharacter(props.surname) : ''
        }`;
      } else {
        return '';
      }
    });

    const getFullname = computed((): string => {
      if (props.firstName || props.surname) {
        return [props.firstName, props.surname].join(' ');
      } else {
        return '';
      }
    });

    const axios = {
      /* TODO: Change to HEAD when CORS is sorted */
      get: (url: string) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = () => reject(new Error(`Failed to load image from URL: ${url}`));
          img.src = url;
        }),
    };

    onMounted(() => {
      if (props.imageUrl) {
        try {
          axios.get(props.imageUrl)
            .then(() => {
              showImage.value = true;
            })
            .catch(() => {
              showImage.value = false;
            });
        } catch (error) {
          showImage.value = false;
        }
      }
    });

    return {
      showImage,
      getInitials,
      getFullname,
    };
  },
});
</script>

<style lang="scss">
:root {
  --tw-profile-picture-background: var(--kw-color-light-theme-grey-2);
}

.theme--dark {
  --tw-profile-picture-background: var(--kw-color-dark-theme-grey-4);
}
</style>

<style lang="scss" scoped>
.profile-picture {
  align-items: center;
  background-color: var(--tw-profile-picture-background);
  border-radius: var(--kw-border-inner);
  display: flex;
  font-size: var(--kw-font-size-body-text);
  font-weight: 700;
  height: 2rem;
  justify-content: center;
  margin: 0;
  overflow: hidden;
  min-width: 2rem;
  max-width: 2rem;

  &--small {
    font-size: var(--kw-font-size-page-head-text);
    height: 5.5rem;
    min-width: 5.5rem;
    max-width: 5.5rem;
  }

  > img {
    font-size: var(--kw-font-size-label-text);
  }
}
</style>
