<template>
  <label>
    <span :class="hideLabel ? 'sr-only' : null">{{ label }}</span>
    <input
      :disabled="disabled"
      @change="onChange"
      @input="onInput"
      @blur="onBlur"
      :min="min"
      :max="max"
      :step="step"
      :name="name"
      type="range"
      :value="inputNumber"
      style="width: 97%"
    />
  </label>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, onMounted, watch } from 'vue';

export default defineComponent({
  name: 'Range',
  props: {
    label: {
      type: String,
      required: true,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
    },
    name: {
      type: String,
    },
    value: {
      type: [Number, String],
    },
  },
  emits: ['blur', 'change', 'input'],
  setup(props, { emit }) {
    const inputNumber = ref(props.value ?? 0);

    const onBlur = (event: any) => {
      emit('blur', event.target.valueAsNumber);
    };

    const onChange = (event: any) => {
      emit('change', event.target.valueAsNumber);
    };

    const onInput = (event: any) => {
      emit('input', event.target.valueAsNumber);
    };

    onMounted(async () => {
      if (props.value != null) {
        inputNumber.value = props.value;
      }

      if (props.min != null && props.max != null && props.max < props.min) {
        throw new Error('The max prop should not have a value less than the min prop.');
      }
    });

    watch(
      () => props.value,
      (newValue) => {
        if (newValue != null && newValue !== inputNumber.value) {
          inputNumber.value = newValue;
        }
      }
    );

    return {
      inputNumber,
      onBlur,
      onChange,
      onInput,
    };
  },
});
</script>
