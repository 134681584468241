<template>
  <div v-click-away="closeDropdown" class="scrollable-select select" :class="required ? 'select--required' : null">
    <span :class="hideLabel ? 'sr-only' : null">
      {{ label }}
      <template v-if="required">
        <i class="select__asterisk" aria-hidden="true">*</i><i class="sr-only">(required)</i>
      </template>
    </span>
    <div
      class="scrollable-select__selector select__selector"
      :class="[
        status ? 'select--' + status : '',
        disabled ? 'select__selector--disabled' : '',
        readonly ? 'select__selector--readonly' : '',
        status && disabled ? 'select--' + status + '--disabled' : '',
        isListDisplayed && !disabled ? 'scrollable-select__selector--visible' : '',
      ]"
      :title="placeholderValue"
      aria-haspopup="listbox"
      :aria-disabled="disabled"
      :aria-readonly="readonly"
      :aria-required="required"
      role="button"
      :tabindex="disabled ? null : '0'"
      @click="handleDropdownClick"
      @keydown.space.prevent="handleDropdownClick"
    >
      <span class="scrollable-select__placeholder" :class="!selectedItemKey && 'placeholder-clr'">{{
        placeholderValue
      }}</span>
    </div>
    <div
      class="scrollable-select__dropdown"
      :class="[isListDisplayed ? 'scrollable-select__dropdown--visible' : '']"
      v-if="isListDisplayed && !disabled && !readonly"
    >
      <div class="scrollable-select__dropdown__search" v-if="!noSearch">
        <input
          class="scrollable-select__dropdown__search__input"
          type="search"
          placeholder="Search..."
          v-model="searchFilterInput"
          @input="filterOptions"
          aria-label="Search through the list"
        />
      </div>
      <div class="scrollable-select__dropdown__search" v-else-if="allowInput">
        <input
          class="scrollable-select__dropdown__search__input"
          type="text"
          placeholder="Type to input..."
          v-model="inputValue"
          @keydown.enter="handleEnter"
          aria-label="Type to input"
        />
      </div>
      <ul
        class="scrollable-select__dropdown__list"
        :class="Object.keys(filteredOptions).length > 5 ? 'pr-2' : ''"
        role="listbox"
      >
        <li
          class="scrollable-select__dropdown__list-item overflow-x--hidden"
          :class="[isSelectedItem(key) ? 'scrollable-select__dropdown__list-item--selected' : '']"
          v-for="(key, index) in Object.keys(filteredOptions)"
          :key="index"
          role="option"
          tabindex="0"
          :aria-setsize="Object.keys(options).length"
          :aria-posinset="index + 1"
          :aria-selected="isSelectedItem(key)"
          :title="filteredOptions[key]"
          @click="selectListItem(key)"
          @keydown.enter="selectListItem(key)"
        >
          {{ filteredOptions[key] }}
        </li>
      </ul>
    </div>
    <span class="select__message" v-if="(!isListDisplayed && message && status) || disabled">
      {{ message }}
    </span>
  </div>
</template>

<script lang="ts">
import { ref, computed, defineComponent, PropType, toRefs, watchEffect } from 'vue';
import { Dictionary } from '@/extensions/types';
import Search from '@/utilities/search';

export default defineComponent({
  name: 'ScrollableSelect',
  props: {
    label: {
      type: String,
      required: true,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object as PropType<Dictionary<string>>,
      default: () => ({}),
    },
    message: {
      type: String,
      default: '',
    },
    noSearch: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: undefined,
      validator: (value: string): boolean => ['success', 'error'].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    fileName: {
      type: String,
      default: '',
    },
    isNullable: {
      type: Boolean,
      default: false,
    },
    selectedItemKey: {
      type: String as PropType<string | null>,
      default: null,
    },
    inputValue: {
      type: String,
      default: '',
    },
    allowInput: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const {
      label,
      hideLabel,
      options,
      message,
      noSearch,
      status,
      disabled,
      readonly,
      placeholder,
      fileName,
      isNullable,
      selectedItemKey,
    } = toRefs(props);
    const searchFilterInput = ref('');
    const isListDisplayed = ref(false);
    const filteredOptions = ref<Dictionary<string>>({});
    const inputValue = ref('');
    const allowInput = props.allowInput;

    const handleEnter = (event: Event) => {
      inputValue.value = (event.target as HTMLInputElement).value;
      isListDisplayed.value = false;
      emit('update:inputValue', inputValue.value);
    };

    const placeholderValue = computed(() => {
      if (selectedItemKey.value === null || selectedItemKey.value === '') {
        return placeholder.value;
      } else {
        return options.value[selectedItemKey.value];
      }
    });

    const isSelectedItem = (key: string) => {
      return key === selectedItemKey.value;
    };

    const filterOptions = () => {
      if (fileName.value === 'firmware') {
        for (let [key, value] of Object.entries(options.value)) {
          options.value[key] = value.length > 45 ? value.substring(0, 45) + '...' : value;
        }
      }
      if (searchFilterInput.value === '') {
        filteredOptions.value = options.value;
      } else {
        const filterResults: Dictionary<string> = {};
        for (const key in options.value) {
          if (Search.fuzzySearch(options.value[key], searchFilterInput.value)) {
            filterResults[key] = options.value[key];
          }
        }
        filteredOptions.value = filterResults;
      }
    };

    const handleDropdownClick = () => {
      isListDisplayed.value = !isListDisplayed.value;
    };

    const closeDropdown = () => {
      isListDisplayed.value = false;
    };

    const selectListItem = (key: string) => {
      if (isNullable.value && selectedItemKey.value === key) {
        emit('update:selectedItemKey', null);
      } else {
        emit('update:selectedItemKey', key);
      }
      handleDropdownClick();
      emit('change', key);
    };

    const watchSearchFilterInput = watchEffect(() => {
      filterOptions();
    });

    const created = () => {
      if (readonly.value && disabled.value) {
        throw new Error('The readonly prop and the disabled prop cannot both be set.');
      }
    };

    const mounted = () => {
      filterOptions();
    };

    return {
      searchFilterInput,
      isListDisplayed,
      filteredOptions,
      placeholderValue,
      inputValue,
      isSelectedItem,
      handleDropdownClick,
      closeDropdown,
      selectListItem,
      filterOptions,
      created,
      mounted,
      watchSearchFilterInput,
      //handleInput,
      handleEnter,
      allowInput,
    };
  },
});
</script>

<style lang="scss">
.placeholder-clr {
  color: var(--kw-select-border-color);
}
</style>
