import { CreateDeviceGroupDto, DeviceGroupDto, DeviceGroupsClient, ModifyDeviceGroupDto } from '@/apiClients';
import ArrayHelper from '@/utilities/arrayHelper';
import { forceStringVal } from '@/utilities/strings';

import authorizedClient from './authorizedClient';

export interface IDeviceGroupsState {
  deviceGroups: DeviceGroupDto[];
}

export default {
  namespaced: true as true,
  state: () =>
    ({
      deviceGroups: [],
    }) as IDeviceGroupsState,
  getters: {
    deviceGroup: (state: IDeviceGroupsState) => (id: string) => {
      return state.deviceGroups.find((x) => x.id === id);
    },
  },
  mutations: {
    ADD_DEVICE_GROUP(state: IDeviceGroupsState, deviceGroup: DeviceGroupDto) {
      ArrayHelper.pushOrUpdate(state.deviceGroups, deviceGroup, (x) => x.id === deviceGroup.id);
    },
    REMOVE_DEVICE_GROUP(state: IDeviceGroupsState, id: string) {
      ArrayHelper.removeItemWhere(state.deviceGroups, (x) => x.id === id);
    },
  },
  actions: {
    async getDeviceGroup({ commit }: any, id: string) {
      const deviceGroupsClient = await authorizedClient(DeviceGroupsClient);
      try {
        const device = await deviceGroupsClient.getDeviceGroup(id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
        if (device) {
          commit('ADD_DEVICE_GROUP', device);
        }
      } catch (error) {
        throw error;
      }
    },
    async createDeviceGroup({ commit }: any, deviceGroup: CreateDeviceGroupDto) {
      const deviceGroupsClient = await authorizedClient(DeviceGroupsClient);
      try {
        const response = await deviceGroupsClient.create(process.env.VUE_APP_TERMINAL_WEB_API_VERSION, deviceGroup);
        return response?.result ? response.result : null;
      } catch (error) {
        throw error;
      }
    },
    async deleteDeviceGroup({ commit }: any, id: string) {
      const deviceGroupsClient = await authorizedClient(DeviceGroupsClient);
      try {
        await deviceGroupsClient.delete(id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
        commit('REMOVE_DEVICE_GROUP', id);
      } catch (error) {
        throw error;
      }
    },
    async modifyDeviceGroup({ commit }: any, deviceGroup: DeviceGroupDto) {;
      const deviceGroupsClient = await authorizedClient(DeviceGroupsClient);
      const modifyDto = new ModifyDeviceGroupDto({
        name: forceStringVal(deviceGroup.name),
        scheduleRotationLength: deviceGroup.scheduleRotationLength!,
        scheduleBaseDate: deviceGroup.scheduleBaseDate!,
        devices: deviceGroup.devices!,
      });
      try {
        await deviceGroupsClient.modify(deviceGroup.id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION, modifyDto);
        commit('REMOVE_DEVICE_GROUP', deviceGroup.id);
      } catch (error) {
        throw error;
      }
    },
  },
};
