import { Inject } from 'inversify-props';
import type IAuthenticationService from './IAuthenticationService';
import { injectable } from 'inversify-props';
declare let pendo: any;
export interface IPendoService {
  initializePendo(businessAccountName: string): void;
}

@injectable()
export class PendoService implements IPendoService {
  @Inject('authenticationService') private readonly authenticationService!: IAuthenticationService;
  private initialized: boolean = false;

  public async initializePendo(businessAccountName: string): Promise<void> {
    try {
      if (this.initialized) {
        return;
      }

      const token: string | null = await this.authenticationService.getToken();
      const parsedToken = JSON.parse(atob(token!.split('.')[1]));
      const businessAccountId = parsedToken.businessAccountId;
      const userAccountId = parsedToken.userAccountId;

      pendo.initialize({
        visitor: {
          id: userAccountId,
        },
        account: {
          id: businessAccountId,
          name: businessAccountName,
        },
      });
      this.initialized = true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Unable to initialize Pendo:', e);
    }
  }
}
