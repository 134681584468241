import { ApiException, AuthorizedClient, LicenceClient, LicenceDto } from '@/apiClients';

import authorizedClient from './authorizedClient';

export interface ILicenceState {
  licence: LicenceDto;
}

export default {
  namespaced: true as true,
  state: {
    isLicensed: false,
    licence: new LicenceDto({
      allowClocking: false,
      allowAccess: false,
      editLinkedUsers: false,
      allowActivities: false,
      allowImageOnClocking: false,
    }),
  },
  getters: {
    allowAccess: (state: ILicenceState) => state.licence.allowAccess,
    allowClocking: (state: ILicenceState) => state.licence.allowClocking,
    editLinkedUsers: (state: ILicenceState) => state.licence.editLinkedUsers,
    allowActivities: (state: ILicenceState) => state.licence.allowActivities,
    allowImageOnClocking: (state: ILicenceState) => state.licence.allowImageOnClocking,
  },
  mutations: {
    SET_LICENCE(state: ILicenceState, licence: LicenceDto) {
      state.licence = licence;
    },
    VALID_LICENCE(state: any) {
      state.isLicensed = true;
    },
  },
  actions: {
    async getLicence({ commit }: any) {
      await authorizedClient(LicenceClient).then(async (client) => {
        await client
          .getLicence(process.env.VUE_APP_TERMINAL_WEB_API_VERSION)
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
          })
          .then((result) => {
            if (result) {
              commit('SET_LICENCE', result);
            }
          });
      });
    },

    async hasLicence({ commit, state }: any): Promise<boolean> {
      if (state.isLicensed) {
        return true;
      }

      const client = await authorizedClient(AuthorizedClient);
      try {
        const response = await client.get(process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
        commit('VALID_LICENCE');
      } catch (error) {
        const apiException: ApiException = error;
        if (apiException.status === 403) {
          return false;
        }
      }
      return true;
    },
  },
};
