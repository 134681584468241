import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "slider-tabs" }
const _hoisted_2 = ["aria-setsize", "aria-posinset"]
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
      return (_openBlock(), _createElementBlock("li", {
        key: index,
        "aria-setsize": _ctx.options.length,
        "aria-posinset": index + 1,
        class: "slider-tabs__tab"
      }, [
        _createElementVNode("a", {
          href: _ctx.resolveHref(option.to),
          class: "slider-tabs__link"
        }, _toDisplayString(option.text), 9, _hoisted_3)
      ], 8, _hoisted_2))
    }), 128))
  ]))
}