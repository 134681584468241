import { DeviceHeartbeatDto, DeviceHeartbeatClient } from '@/apiClients';
import authorizedClient from './authorizedClient';

export interface IHeartbeatState {
  heartbeat: DeviceHeartbeatDto;
}

export default {
  namespaced: true as true,
  state: () =>
    ({
      heartbeat: {},
    }) as IHeartbeatState,
  getters: {
    heartbeat:
      (state: IHeartbeatState) =>
      (id: string): DeviceHeartbeatDto | undefined => {
        return state.heartbeat;
      },
  },
  mutations: {
    SET_HEARTBEAT(state: IHeartbeatState, heartbeat: DeviceHeartbeatDto) {
      state.heartbeat = heartbeat;
    },
  },
  actions: {
    async getHeartbeat({ commit }: any, id: string) {
      const deviceHeartbeatClient = await authorizedClient(DeviceHeartbeatClient);
      try {
        const heartbeat = await deviceHeartbeatClient.get(id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
        if (heartbeat) {
          commit('SET_HEARTBEAT', heartbeat);
        }
      } catch (error) {
        throw error;
      }
    },
  },
};
