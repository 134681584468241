import { CreateDeviceDto, DeviceDto, DeviceSummaryDto, DeviceFirmwareReleaseType, DevicesClient } from '@/apiClients';
import ArrayHelper from '@/utilities/arrayHelper';

import authorizedClient from './authorizedClient';

export interface ICreateDeviceWithNewDeviceGroup {
  areaId: string;
  device: CreateDeviceDto;
}

export interface IDevicesState {
  devices: DeviceDto[];
  deviceSummeries: DeviceSummaryDto[];
}

export interface IDeviceUpgradeFirmwarePayload {
  id: string;
  releaseType: DeviceFirmwareReleaseType;
  firmwareVersion: string;
}

export default {
  namespaced: true as true,
  state: (): IDevicesState => ({
    devices: [],
    deviceSummeries: [],
  }),
  getters: {
    device: (state: IDevicesState) => (id: string) => {
      return state.devices.find((x) => x.id === id);
    },
    devices: (state: IDevicesState) => state.devices,
    deviceSummeries: (state: IDevicesState) => state.deviceSummeries,
  },
  mutations: {
    ADD_DEVICE(state: IDevicesState, device: DeviceDto) {
      ArrayHelper.pushOrUpdate(state.devices, device, (x) => x.id === device.id);
    },
    ADD_DEVICES(state: IDevicesState, devices: DeviceDto[]) {
      devices.forEach((device) => {
        ArrayHelper.pushOrUpdate(state.devices, device, (x) => x.id === device.id);
      });
    },
    ADD_DEVICESUMMERIES(state: IDevicesState, deviceSummeries: DeviceSummaryDto[]) {
      state.deviceSummeries = deviceSummeries;
    },
    REMOVE_DEVICE(state: IDevicesState, id: string) {
      ArrayHelper.removeItemWhere(state.devices, (x) => x.id === id);
    },
  },
  actions: {
    async getDevices({ commit }: any) {
      const devicesClient = await authorizedClient(DevicesClient);
      try {
        const devices = await devicesClient.getAllDevices(process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
        if (devices) {
          commit('ADD_DEVICES', devices);
        }
      } catch (error) {
        throw error;
      }
    },
    async getDevice({ commit }: any, id: string) {
      const devicesClient = await authorizedClient(DevicesClient);
      try {
        const device = await devicesClient.getADevice(id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
        if (device) {
          commit('ADD_DEVICE', device);
        }
      } catch (error) {
        throw error;
      }
    },
    async getAllDevicesUsingUserGroup({ commit }: any, id: string) {
      const devicesClient = await authorizedClient(DevicesClient);
      try {
        const deviceSummeries = await devicesClient.getAllDevicesUsingUserGroup(
          id,
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION
        );
        if (deviceSummeries) {
          commit('ADD_DEVICESUMMERIES', deviceSummeries);
        }
      } catch (error) {
        throw error;
      }
    },
    async createDevice({ commit }: any, device: CreateDeviceDto) {
      const devicesClient = await authorizedClient(DevicesClient);
      try {
        const response = await devicesClient.create(process.env.VUE_APP_TERMINAL_WEB_API_VERSION, device);
        return response?.result ? response.result : null;
      } catch (error) {
        throw error;
      }
    },
    async createClockingTerminalWithNewDeviceGroup(
      { commit }: any,
      { areaId, device }: ICreateDeviceWithNewDeviceGroup
    ) {
      const devicesClient = await authorizedClient(DevicesClient);
      try {
        const response = await devicesClient.createClockingTerminalWithNewDeviceGroup(
          areaId,
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION,
          device
        );
        return response?.result ? response.result : null;
      } catch (error) {
        throw error;
      }
    },
    async createAccessPointWithNewDeviceGroup({ commit }: any, { areaId, device }: ICreateDeviceWithNewDeviceGroup) {
      const devicesClient = await authorizedClient(DevicesClient);
      try {
        const response = await devicesClient.createAccessPointWithNewDeviceGroup(
          areaId,
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION,
          device
        );
        return response?.result ? response.result : null;
      } catch (error) {
        throw error;
      }
    },
    async deleteDeviceWithDeviceGroup({ commit }: any, deviceId: string) {
      const deviceClient = await authorizedClient(DevicesClient);
      try {
        await deviceClient.deleteWithDeviceGroup(deviceId, process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
        commit('REMOVE_DEVICE', deviceId);
      } catch (error) {
        throw error;
      }
    },
    async deactivateDeviceWithDeviceGroup({ commit }: any, deviceId: string) {
      const deviceClient = await authorizedClient(DevicesClient);
      try {
        const response = await deviceClient.deactivate(deviceId, process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
        commit('REMOVE_DEVICE', deviceId);
        return response?.result ? response.result : null;
      } catch (error) {
        throw error;
      }
    },
    async resetEngineerPin({ commit }: any, id: string) {
      const devicesClient = await authorizedClient(DevicesClient);
      try {
        await devicesClient.resetEngineerPin(id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
        commit('REMOVE_DEVICE', id);
      } catch (error) {
        throw error;
      }
    },
    async upgradeFirmware({ commit }: any, payload: IDeviceUpgradeFirmwarePayload) {
      const devicesClient = await authorizedClient(DevicesClient);
      try {
        await devicesClient.upgradeFirmware(
          payload.id,
          payload.releaseType,
          payload.firmwareVersion,
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION
        );
      } catch (error) {
        throw error;
      }
    },
    async refreshUserList({ commit }: any, id: string) {
      const devicesClient = await authorizedClient(DevicesClient);
      try {
        await devicesClient.refreshUserList(id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
      } catch (error) {
        throw error;
      }
    },
    async identify({ commit }: any, deviceId: string) {
      const devicesClient = await authorizedClient(DevicesClient);
      try {
        await devicesClient.identify(deviceId, process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
      } catch (error) {
        throw error;
      }
    },
    async reboot({ commit }: any, deviceId: string) {
      const devicesClient = await authorizedClient(DevicesClient);
      try {
        await devicesClient.reboot(deviceId, process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
      } catch (error) {
        throw error;
      }
    },
  },
};
