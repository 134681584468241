import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { container } from 'inversify-props';
import IAuthenticationService from './services/IAuthenticationService';
import { PermissionsDto } from './apiClients';

// Named routes to be used when programmatically navigating
export class Routes {
  //public static readonly AccessSchedules: string = 'access-schedules';
  //public static readonly AccessSchedulesForSite: string = 'access-schedules-for-site';
  public static readonly PageNotFound: string = 'page-not-found';
  public static readonly AccessDenied: string = 'access-denied';
  public static readonly UnlicensedProduct: string = 'unlicensed-product';
  public static readonly IncorrectPermissions: string = 'incorrect-permissions';
  public static readonly Sites: string = 'sites';
  public static readonly SitesEdit: string = 'sites-edit';
  public static readonly SitesNew: string = 'sites-new';
  public static readonly SitesSingle: string = 'sites-single';
  public static readonly DeviceGroupsSingle: string = 'device-groups-single';
  public static readonly Users: string = 'users';
  public static readonly UsersNew: string = 'users-new';
  public static readonly UsersEdit: string = 'users-edit';
  public static readonly UserGroups: string = 'user-groups';
  public static readonly UserGroupsNew: string = 'user-groups-new';
  public static readonly Tools: string = 'tools';
  public static readonly Tags: string = 'tags';
  public static readonly GlobalDeviceSettings: string = 'global-device-settings';
}

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: Routes.Sites,
  },
  {
    path: '/sites',
    name: Routes.Sites,
    component: () => {
      return Promise.resolve(import('@/activities/main/sites/List.vue'));
    },
    meta: {
      title: 'Sites',
    },
  },
  {
    path: '/sites/new',
    name: Routes.SitesNew,
    component: () => {
      return Promise.resolve(import('@/activities/main/sites/New.vue'));
    },
    meta: {
      title: 'Add new site',
    },
  },
  {
    path: '/sites/:siteId',
    name: Routes.SitesSingle,
    component: () => {
      return Promise.resolve(import('@/activities/main/sites/Single.vue'));
    },
    meta: {
      title: 'View site',
    },
  },
  {
    path: '/sites/:siteId/edit',
    name: Routes.SitesEdit,
    component: () => {
      return Promise.resolve(import('@/activities/main/sites/Edit.vue'));
    },
    meta: {
      title: 'Edit site',
    },
  },
  {
    path: '/users',
    name: Routes.Users,
    component: () => {
      return Promise.resolve(import('@/activities/setup/users/list/List.vue'));
    },
    meta: {
      title: 'Manage users',
    },
  },
  {
    path: '/users/new',
    name: Routes.UsersNew,
    component: () => {
      return Promise.resolve(import('@/activities/setup/users/New.vue'));
    },
    meta: {
      title: 'Add new user',
    },
  },
  {
    path: '/users/:userId/edit',
    name: Routes.UsersEdit,
    component: () => {
      return Promise.resolve(import('@/activities/setup/users/Edit.vue'));
    },
    meta: {
      title: 'Edit user',
    },
  },
  {
    path: '/user-groups',
    name: Routes.UserGroups,
    component: () => {
      return Promise.resolve(import('@/activities/setup/userGroups/list/List.vue'));
    },
    meta: {
      title: 'User groups',
    },
  },
  {
    path: '/user-groups/new',
    name: Routes.UserGroupsNew,
    component: () => {
      return Promise.resolve(import('@/activities/setup/userGroups/New.vue'));
    },
    meta: {
      title: 'New user group',
    },
  },
  {
    path: '/access-denied',
    name: Routes.AccessDenied,
    component: () => {
      return Promise.resolve(import('@/views/AccessDenied.vue'));
    },
    meta: {
      title: 'Access denied',
      sidebar: false,
    },
  },
  {
    path: '/unlicensed-product',
    name: Routes.UnlicensedProduct,
    component: () => {
      return Promise.resolve(import('@/views/UnlicensedProduct.vue'));
    },
    meta: {
      title: 'Unlicensed product',
      sidebar: false,
    },
  },
  {
    path: '/incorrect-permissions',
    name: Routes.IncorrectPermissions,
    component: () => {
      return Promise.resolve(import('@/views/IncorrectPermissions.vue'));
    },
    meta: {
      title: 'Incorrect permissions',
      sidebar: false,
    },
  },
  {
    path: '/tools',
    name: Routes.Tools,
    component: () => {
      return Promise.resolve(import('@/activities/main/configurations/Tools.vue'));
    },
    meta: {
      title: 'Tools',
    },
  },
  {
    path: '/tags',
    name: Routes.Tags,
    component: () => {
      return Promise.resolve(import('@/activities/main/configurations/Tags/Tags.vue'));
    },
    meta: {
      title: 'Tags',
    },
  },
  {
    path: '/global-device-settings',
    name: Routes.GlobalDeviceSettings,
    component: () => import('@/activities/main/configurations/GlobalDeviceSettings/GlobalDeviceSettings.vue'),
    meta: {
      title: 'GlobalDeviceSettings',
    },
  },
  {
    // This catch-all route must be last!!!
    path: '/:catchAll(.*)',
    component: () => {
      return Promise.resolve(import('./views/PageNotFound.vue'));
    },
    meta: {
      title: 'Page not found',
    },
  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authenticationService = container.get<IAuthenticationService>('authenticationService');
  const jwt = await authenticationService.getToken();
  if (jwt == null) {
    try {
      await authenticationService.login();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error during login: ' + error);
    }
  }

  // Check user permissions
  if (to.meta?.requiredPermission) {
    if (!(await authenticationService.getPermission(to.meta?.requiredPermission as keyof PermissionsDto))) {
      // eslint-disable-next-line no-console
      console.log(
        `Access denied - permission '${to.meta?.requiredPermission}' required to navigate to '${to.fullPath}'`
      );
      next({ path: '/page-not-found', name: Routes.PageNotFound });
      return;
    }
  }

  // Set <title>
  if (to.meta?.title) {
    document.title = `${to.meta?.title} | ${process.env.VUE_APP_NAME}`;
  } else {
    document.title = process.env.VUE_APP_NAME;
  }
  next();
});

export default router;
