<template>
  <label class="textbox" :class="required ? 'textbox--required' : null" :data-test="`label- ${label}`">
    <span :class="hideLabel ? 'sr-only' : null">
      {{ label }}
      <template v-if="required">
        <i class="textbox__asterisk" aria-hidden="true">*</i><i class="sr-only">(required)</i>
      </template>
    </span>
    <span
      v-if="(icon || tag) && !multiline"
      class="textbox__entry textbox__icon"
      :class="status ? `textbox--${status}` : ''"
    >
      <i :class="'icon-' + icon"></i>
      <input
        class="textbox__input"
        :disabled="disabled"
        :maxlength="maxlength"
        :minlength="minlength"
        :max="max"
        :min="min"
        :placeholder="placeholder"
        :readonly="readonly"
        :required="required"
        :type="type"
        :value="localInputValue"
        v-model="localInputValue"
        @blur="onBlur"
        @change="onChange"
        @input="onInput"
      />
      <tag v-if="tag" class="mr-2" color="secondary" pill>{{ tag }}</tag>
    </span>
    <input
      v-if="!multiline && !icon && !tag"
      class="textbox__entry"
      id="localInputValue"
      :class="status ? `textbox--${status}` : ''"
      :disabled="disabled"
      :maxlength="maxlength"
      :minlength="minlength"
      :max="max"
      :min="min"
      :placeholder="placeholder"
      :readonly="readonly"
      :required="required"
      :type="type"
      :value="localInputValue"
      v-model="localInputValue"
      @blur="onBlur"
      @change="onChange"
      @input="onInput"
      @focus="onFocus"
    />
    <textarea
      v-if="multiline && !icon && !tag"
      class="textbox__entry textbox__textarea"
      :class="status ? `textbox--${status}` : ''"
      :disabled="disabled"
      :maxlength="maxlength"
      :minlength="minlength"
      :placeholder="placeholder"
      :readonly="readonly"
      :required="required"
      :value="localInputMultiLineValue"
      v-model="localInputMultiLineValue"
      @blur="onBlur"
      @change="onChange"
      @input="onInput"
    ></textarea>
    <span v-if="message" class="textbox__message">{{ message }}</span>
  </label>
</template>

<script lang="ts">
import { ref, watch, defineComponent, computed, onUpdated, onBeforeUpdate, onMounted } from 'vue';
import Tag from '@/components/Tag.vue';

export default defineComponent({
  components: { Tag },
  props: {
    disabled: { type: Boolean, default: false },
    icon: { type: String },
    maxlength: { type: Number },
    minlength: { type: Number },
    max: { type: Number },
    min: { type: Number },
    multiline: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    hideLabel: { type: Boolean, default: false },
    label: { type: String, required: true },
    fieldName: { type: String },
    value: { type: [Date, Number, String], default: '' },
    message: { type: String },
    placeholder: { type: String },
    tag: { type: String },
    required: { type: Boolean, default: false },
    status: {
      type: String,
      default: undefined,
      validator: (value: string): boolean => ['success', 'error'].includes(value),
    },
    type: {
      type: String,
      default: 'text',
      validator: (value: string): boolean =>
        ['datetime-local', 'email', 'month', 'password', 'search', 'text', 'tel', 'url', 'week'].includes(value),
    },
  },
  setup(props, { emit }) {
    const localInputValue = ref(props.value);
    const localInputMultiLineValue = ref(props.value) as any;

    watch(
      () => props.value,
      () => {
        localInputValue.value = props.value;
        localInputMultiLineValue.value = props.value;
      }
    );

    const onBlur = (event: Event) => {
      emit('update:modelValue', (event.target as HTMLInputElement).value);
    };

    const onFocus = (event: Event) => {
      emit('update:modelValue', (event.target as HTMLInputElement).value);
    };

    const onChange = (event: Event) => {
      emit('update:modelValue', (event.target as HTMLInputElement).value);
    };

    const onInput = (event: Event) => {
      if (props.fieldName === 'postCode') {
        (event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value.replace(
          /[^a-zA-Z0-9\s-]/g,
          ''
        );
      }
      emit('update:modelValue', (event.target as HTMLInputElement).value);
    };

    return {
      localInputValue,
      localInputMultiLineValue,
      onBlur,
      onFocus,
      onChange,
      onInput,
    };
  },
});
</script>

<style lang="scss" scoped>
.textbox {
  &__icon {
    input {
      min-width: 0;
    }
  }
}
</style>
