<template>
  <div>
    <span class="textbox" :class="hideLabel ? 'sr-only' : null">
      <span :class="labelClass"> {{ label }} </span>
    </span>
    <span class="textbox__entry textbox__icon searchbox__entry">
      <i :class="'icon-' + icon" style="font-size: 36px"></i>
      <input
        v-model="localInputValue"
        class="textbox__input"
        :disabled="disabled"
        :placeholder="placeholder"
        type="text"
        @blur="blur"
        @focus="focus"
        @keyup.enter="search"
        @keyup.esc="reset"
        style="font-size: 12px"
      />
      <button
        class="searchbox__reset"
        :class="[!localInputValue ? 'searchbox__reset--hidden' : undefined]"
        data-test="button-searchReset"
        :disabled="disabled"
        @click="reset"
      >
        <i class="icon-cross"></i>
      </button>

      <button
        class="searchbox__search"
        data-test="button-search"
        :disabled="disabled || !localInputValue || buttonDisabled"
        @click="search"
      >
        <div class="row">
          <div class="col-1 button-icon-align">
            <i :class="'icon-' + buttonIcon" class="button-icon"></i>
          </div>
          <div v-if="buttonIcon" class="col-1 button-text-with-icon">
            {{ buttonText }}
          </div>
          <div v-else class="col-1 button-text">
            {{ buttonText }}
          </div>
        </div>
      </button>
    </span>
  </div>
</template>

<script lang="ts">
import { icon } from 'leaflet';
import { ref, watch, onMounted, onBeforeUnmount, defineComponent } from 'vue';
import { Btn } from '@/components';

export default defineComponent({
  props: {
    disabled: { type: Boolean, default: false },
    label: { type: String, required: true },
    placeholder: { type: String },
    value: { type: [Date, Number, String], default: '' },
    icon: { type: String },
    buttonIcon: { type: String },
    buttonText: { type: String },
    hideLabel: { type: Boolean, default: false },
    labelClass: { type: String },
    buttonDisabled: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const localInputValue = ref(props.value);
    const value = ref('');
    const hasFocus = ref(false);

    watch(value, (newValue) => {
      localInputValue.value = newValue;
    });

    watch(localInputValue, (newValue) => {
      if (newValue === '') {
        reset();
      }
    });

    onMounted(() => {
      localInputValue.value = value.value;
    });

    const blur = (event) => {
      hasFocus.value = false;
      return event;
    };

    const focus = (event) => {
      hasFocus.value = true;
      return event;
    };

    const reset = () => {
      emit('reset');
      localInputValue.value = '';
    };

    const search = () => {
      emit('search', localInputValue.value);
    };

    return {
      localInputValue,
      blur,
      focus,
      search,
      reset,
    };
  },
});
</script>

<style>
:root {
  --tw-searchbox-background-color-disabled: var(--kw-color-light-theme-grey-2);
}

.theme--dark {
  --tw-searchbox-background-color-disabled: var(--kw-color-dark-theme-grey-3);
}
</style>

<style lang="scss" scoped>
.mute-label {
  font-size: 0.75rem;
  line-height: 2rem;
  color: #A5A5B6;
  font-family: var(--kw-font-stack, sans-serif);
}

.button-icon {
  font-size: 30px;
}

.button-icon-align {
  padding-top: 16px;
}

.button-text-with-icon {
  padding-top: 9px;
  padding-left: 26px;
  padding-right: 0px;
}

.button-text {
  padding-right: 30px;
  padding-left: 10px;
}

.searchbox {
  &__entry {
    border-radius: var(--kw-border-inner);

    &:focus-visible {
      // prettier-ignore
      &:not([readonly]) {
        outline: 0;
      }
    }
  }

  &__reset,
  &__search {
    cursor: pointer;
  }

  &__reset {
    background-color: transparent;
    border: none;
    color: inherit;
    margin-top: 1em;

    &--hidden {
      display: none;
    }
  }

  &__search {
    background-color: var(--kw-default-button-background-color);
    border: 1px solid var(--kw-textbox-border-color);
    border-left: 0;
    border-radius: 0 var(--kw-border-inner) var(--kw-border-inner) 0;
    border-width: 0;
    color: var(--kw-default-button-text-color);
    width: auto;
    height: 100%;

    &:disabled {
      background-color: var(--kw-secondary-tag-background-color);
      color: var(--kw-tag-text-color);
      cursor: not-allowed;
    }
  }

  .textbox__input {
    color: red;
    font-size: 2px;
  }
}
</style>
