<template>
  <svg
    class="loading-spinner"
    role="img"
    data-test="img-loadingSpinner"
    aria-label="Content is loading"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 106 106"
  >
    <g fill-rule="nonzero">
      <path
        d="M53 106c4.4754 0 8.882-.5558 13.145-1.644 2.6756-.683 4.291-3.4057 3.608-6.0814-.683-2.6756-3.4057-4.291-6.0814-3.608C60.2152 95.549 56.6395 96 53 96c-2.7614 0-5 2.2386-5 5s2.2386 5 5 5z"
      />
      <path
        d="M87.1784 93.5086a53.276 53.276 0 008.9769-9.735c1.605-2.247 1.0844-5.3698-1.1627-6.9748-2.247-1.605-5.3698-1.0844-6.9748 1.1627a43.276 43.276 0 01-7.2915 7.907c-2.1097 1.7818-2.3757 4.9364-.594 7.0461 1.7817 2.1098 4.9364 2.3757 7.0461.594z"
      />
      <path
        d="M105.252 61.919c.4951-2.92.7465-5.8915.748-8.8926 0-1.4674-.0576-2.9054-.173-4.3378-.222-2.7525-2.6332-4.804-5.3857-4.582-2.7525.2218-4.804 2.633-4.582 5.3855A43.8031 43.8031 0 0196 53.0239c-.0012 2.4395-.2054 4.8534-.6073 7.2231-.4617 2.7226 1.3711 5.304 4.0937 5.7656 2.7225.4617 5.3039-1.3711 5.7656-4.0937z"
      />
      <path
        d="M98.6514 26.0603a53.194 53.194 0 00-8.1101-10.4722c-1.9493-1.956-5.1151-1.9614-7.071-.0122-1.956 1.9493-1.9615 5.1151-.0122 7.071a43.194 43.194 0 016.5854 8.503c1.4055 2.377 4.4718 3.1646 6.8488 1.7591 2.377-1.4054 3.1646-4.4717 1.7591-6.8487z"
        opacity=".8"
      />
      <path
        d="M70.5906 2.9904C66.4275 1.5261 62.0816.5857 57.6326.2c-2.751-.2386-5.1746 1.7983-5.4131 4.5494-.2385 2.7511 1.7983 5.1747 4.5494 5.4132 3.6093.313 7.1307 1.075 10.5036 2.2613 2.605.9163 5.4595-.4527 6.3758-3.0577.9163-2.605-.4527-5.4595-3.0577-6.3758z"
      />
      <path
        d="M34.1528 3.4504c-4.1495 1.5792-8.0778 3.675-11.6978 6.232-2.2556 1.593-2.7926 4.713-1.1995 6.9685 1.5931 2.2556 4.713 2.7926 6.9686 1.1995 2.9371-2.0745 6.1225-3.774 9.4855-5.0539 2.5809-.9822 3.8768-3.8706 2.8946-6.4514-.9821-2.5809-3.8705-3.8768-6.4514-2.8947z"
      />
      <path
        d="M6.6032 27.3626A52.69 52.69 0 001.6898 39.677c-.6923 2.6732.9135 5.4015 3.5868 6.0939 2.6732.6923 5.4015-.9135 6.0938-3.5868a42.6904 42.6904 0 013.9815-9.9779c1.3375-2.4159.4634-5.4586-1.9525-6.7961-2.416-1.3375-5.4586-.4634-6.7962 1.9525z"
      />
      <path
        d="M.993 63.2583A52.646 52.646 0 005.1635 75.844c1.1915 2.491 4.1769 3.5446 6.668 2.353 2.4911-1.1915 3.5446-4.177 2.353-6.668a42.6463 42.6463 0 01-3.3787-10.1963c-.5317-2.7097-3.1594-4.4753-5.8692-3.9436C2.227 57.9208.4612 60.5486.993 63.2583z"
      />
      <path
        d="M19.8962 94.3924c3.4596 2.77 7.2537 5.099 11.2958 6.926 2.5163 1.1373 5.4782.0194 6.6156-2.497 1.1373-2.5163.0194-5.4782-2.497-6.6155-3.2775-1.4814-6.3558-3.371-9.164-5.6196-2.1557-1.726-5.3023-1.3777-7.0282.778-1.726 2.1555-1.3777 5.3021.7778 7.028z"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LoadSpinner',
});
</script>
