<template>
  <activity-template header="Network Error">
    <workspace-network-error message="There was a problem connecting to the server. The server may not exist or it is unavailable at this time. Check your network connection and try again." />
  </activity-template>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ActivityTemplate, WorkspaceNetworkError } from '@/components';

export default defineComponent({
  components: {
    ActivityTemplate,
    WorkspaceNetworkError,
  },
});
</script>