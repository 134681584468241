<template>
  <ul class="slider-tabs">
    <li
      v-for="(option, index) in options"
      :key="index"
      :aria-setsize="options.length"
      :aria-posinset="index + 1"
      class="slider-tabs__tab"
    >
      <a :href="resolveHref(option.to)" class="slider-tabs__link">
        {{ option.text }}
      </a>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent, Prop } from 'vue';

interface ISliderTab {
  text: string;
  to: object | string;
}

export default defineComponent({
  name: 'SliderTabs',
  props: {
    options: {
      type: Array as () => ISliderTab[],
      required: true,
    },
  },
  setup(props) {
    const resolveHref = (to: object | string): string => {
      if (typeof to === 'object') {
        return (to as any).path || '';
      }
      return to;
    };

    return {
      resolveHref,
    };
  },
});
</script>
