<!-- TODO: Component is broken & needs fixing. See https://mitrefinch.visualstudio.com/CloudGuard/_workitems/edit/27832 -->

<template>
  <span class="kw-selector">
    <input type="checkbox" :id="inputId" :disabled="disabled" :readonly="readonly" v-model="isChecked" />
    <label class="kw-selector__label" :for="inputId">
      <span class="kw-selector__tick"></span>
      <span class="kw-selector__label-text" :class="{ 'sr-only': hideLabel }">{{ label }}</span>
    </label>
  </span>
</template>

<script lang="ts">
import { ref, defineComponent, watch } from 'vue';

export default defineComponent({
  props: {
    value: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    inputId: { type: String, required: true },
    readonly: { type: Boolean, default: false },
    hideLabel: { type: Boolean, default: false },
    label: { type: String, required: true },
  },
  setup(props) {
    const isChecked = ref(props.value);

    watch(
      () => props.value,
      (newValue) => {
        isChecked.value = newValue;
      }
    );

    if (props.readonly && props.disabled) {
      throw new Error('The readonly prop and the disabled prop cannot both be set.');
    }

    return {
      isChecked,
    };
  },
});
</script>
