<template>
  <div class="tooltip" @mouseenter="showTooltip" @mouseleave="hideTooltip">
    <div class="tooltip__trigger">
      <slot name="tooltipTrigger" />
    </div>
    <transition name="fade">
      <div v-if="isTooltipDisplayed" class="tooltip__content" role="tooltip" tabindex="0">
        <slot name="tooltipContent" />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue';
import { ref } from '@vue/reactivity';

export default defineComponent({
  name: 'Tooltip',
  props: {
    tooltipDelay: {
      type: Number,
      default: 1000,
    },
  },
  setup(props) {
    const isTooltipDisplayed = ref(false);
    let timeout = 0;

    const showTooltip = () => {
      timeout = setTimeout(() => {
        isTooltipDisplayed.value = true;
      }, props.tooltipDelay);
    };

    const hideTooltip = () => {
      clearTimeout(timeout);
      isTooltipDisplayed.value = false;
    };

    return {
      ...toRefs(props),
      isTooltipDisplayed,
      showTooltip,
      hideTooltip,
    };
  },
});
</script>

<style lang="scss" scoped>
.fade {
  &-enter,
  &-leave {
    opacity: 1;

    &-active {
      transition: opacity 0.5s ease-out;
    }
  }

  &-leave-to {
    opacity: 1;
  }
}
</style>
