<!-- TODO: Component is broken & needs fixing. See https://mitrefinch.visualstudio.com/CloudGuard/_workitems/edit/27832 -->

<template>
  <label class="toggle">
    <!-- Label always hidden for now because the Kernel standard toggles have no labels... -->
    <span class="sr-only">{{ label }}</span>
    <input
      :checked="modelValue"
      :value="modelValue"
      class="toggle__input"
      :disabled="disabled"
      @change="onInput"
      @focus="onFocus"
      @input="onInput"
      type="checkbox"
    />
    <span class="toggle__slider" :tabindex="disabled ? undefined : '0'" @keydown.space="onSpace"></span>
    <span v-if="checkedMessage && uncheckedMessage" class="toggle__message toggle__message--checked">{{
      checkedMessage
    }}</span>
    <span v-if="checkedMessage && uncheckedMessage" class="toggle__message toggle__message--unchecked">{{
      uncheckedMessage
    }}</span>
  </label>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    checkedMessage: {
      type: String,
      default: '',
    },
    uncheckedMessage: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'input', 'focus', 'change'],
  setup(props, { emit }) {
    const onInput = (event: Event): string => {
      const value = (event.target as HTMLInputElement).value;
      emit('input', value);
      return value;
    };

    const onFocus = (event: Event): HTMLInputElement => {
      const target = event.target as HTMLInputElement;
      emit('focus', target);
      return target;
    };

    const onSpace = (event: Event): boolean => {
      const newValue = !props.modelValue;
      emit('update:modelValue', newValue);
      onInput(event);
      return newValue;
    };

    return {
      onInput,
      onFocus,
      onSpace,
    };
  },
});
</script>
