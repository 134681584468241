import {
  ActivitiesListDto,
  ActivityListMetaDataDto,
  DeviceActivitiesClient,
  ActivitiesClient,
  DeviceActivitiesDto,
  FileParameter,
  FileLoadDto,
} from '@/apiClients';

import authorizedClient from './authorizedClient';

export interface IActivitiesState {
  activities: ActivitiesListDto;
  metaData: ActivityListMetaDataDto;
}

export interface IGetDeviceActivitiesPayload {
  id: any;
  pageOffset: number;
  pageSize: number;
  searchTerm: string;
}

export interface IProcessFilePayload {
  fileName: string;
  skipOffset: number;
  pageSize: number;
}

export interface IModifyDeviceActivitiesPayload {
  deviceId: string;
  deviceActivities: DeviceActivitiesDto;
}

export default {
  namespaced: true as true,
  state: () =>
    ({
      activities: {},
      metaData: new ActivityListMetaDataDto(),
    }) as IActivitiesState,
  getters: {
    activities:
      (state: IActivitiesState) =>
      (id: string): ActivitiesListDto | undefined => {
        return state.activities;
      },
    metaData: (state: IActivitiesState) => () => state.metaData,
  },
  mutations: {
    ADD_DEVICE_ACTIVITIES(state: IActivitiesState, activities: ActivitiesListDto) {
      state.activities = activities;
    },
    ADD_DEVICE_ACTIVITIES_METADATA(state: IActivitiesState, metaData: ActivityListMetaDataDto) {
      state.metaData = metaData;
    },
  },
  actions: {
    async getDeviceActivities({ commit }: any, payload: IGetDeviceActivitiesPayload) {
      const deviceActivitiesClient = await authorizedClient(DeviceActivitiesClient);
      try {
        const result = await deviceActivitiesClient.getDeviceActivitiesPage(
          payload.id,
          payload.pageOffset,
          payload.pageSize,
          payload.searchTerm,
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION
        );
        if (result) {
          if (result.activities) {
            commit('ADD_DEVICE_ACTIVITIES', result.activities);
          }
          if (result.metaData) {
            commit('ADD_DEVICE_ACTIVITIES_METADATA', result.metaData);
          }
          return result.activities;
        }
      } catch (error) {
        throw error;
      }
      return [];
    },
    async modifyActivities({ commit }: any, { deviceId, deviceActivities }: IModifyDeviceActivitiesPayload) {
      try {
        const deviceActivitiesClient = await authorizedClient(DeviceActivitiesClient);
        await deviceActivitiesClient.modify(deviceId, process.env.VUE_APP_TERMINAL_WEB_API_VERSION, deviceActivities);
      } catch (error) {
        throw error;
      }
    },
    async importActivities({ commit }: any, file: FileParameter) {
      try {
        const activitiesClient = await authorizedClient(ActivitiesClient);
        const result = await activitiesClient.importCsv(process.env.VUE_APP_TERMINAL_WEB_API_VERSION, file);
        return result?.result;
      } catch (error) {
        throw error;
      }
    },
    async processFile({ commit }: any, payload: IProcessFilePayload) {
      const activitiesClient = await authorizedClient(ActivitiesClient);
      try {
        const result = await activitiesClient.processFile(
          payload.fileName,
          payload.skipOffset,
          payload.pageSize,
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION
        );
        return result?.result;
      } catch (error) {
        throw error;
      }
    },
  },
};
