export default class ArrayHelper {
  public static pushOrUpdate<T>(array: T[], value: T, predicate: (value: T, index: number, obj: T[]) => unknown) {
    const existingIndex = array.findIndex(predicate);
    if (existingIndex === -1) {
      array.push(value);
    } else {
      array[existingIndex] = value;
    }
  }

  public static removeItemWhere<T>(array: T[], predicate: (value: T, index: number, obj: T[]) => unknown) {
    const index = array.findIndex(predicate);
    if (index > -1) {
      array.splice(index, 1);
    }
  }
}
