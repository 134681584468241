import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7cb376c3"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt", "title"]
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("figure", {
    class: _normalizeClass(["profile-picture", _ctx.size !== 'thumbnail' ? `profile-picture--${_ctx.size}` : ''])
  }, [
    (_ctx.showImage)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.imageUrl,
          alt: `${_ctx.getFullname}'s profile picture`,
          title: _ctx.getFullname,
          loading: "lazy"
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.getInitials), 1))
  ], 2))
}