<template>
  <!-- TODO: Make the below visible when CORS permissions for notifications is configured in Saas -->
  <section v-if="false" class="col-auto the-app-header__tools border--left border--grey-2 mr-0">
    <Btn @click="isNotificationsDisplayed = true" id="notifications-bell-button" title="View notifications" ghost>
      <i class="icon-notifications the-app-header__tools__profile-chevron"></i>
    </Btn>
    <iframe
      v-if="isNotificationsDisplayed"
      id="notifications-iframe"
      class="notifications__iframe"
      src="https://account.mitrefinchdev.co.uk/Notification"
    ></iframe>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Btn } from '@/components';

export default defineComponent({
  name: 'Notifications',
  components: {
    Btn,
  },
  setup() {
    const isNotificationsDisplayed = true;
    // TODO: Uncomment the below when CORS permissions for notifications is configured in Saas
    // private isNotificationsDisplayed: boolean = false;
    // private mounted(): void {
    //   // TODO: Uncomment the below when CORS permissions for notifications is configured in Saas
    //   window.document.addEventListener(
    //     'appframe_close_event',
    //     e => {
    //       this.isNotificationsDisplayed = false;
    //     },
    //     false
    //   );
    // }
    return {
      isNotificationsDisplayed,
    };
  },
});
</script>

<style lang="scss" scoped>
// TODO: Uncomment the below when CORS permissions for notifications is configured in Saas
// .notifications__iframe {
//   border: 0;
//   height: 100vh;
//   position: fixed;
//   right: 0;
//   top: 0;
//   width: 100vw;
//   z-index: 1;
// }
</style>
