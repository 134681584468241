import { DeviceStatusAlertConfigurationDto, DeviceStatusAlertConfigurationClient, UpsertDeviceStatusAlertConfigurationDto } from '@/apiClients';

import authorizedClient from './authorizedClient';

export interface IDeviceStatusAlertConfigurationState {
  deviceStatusAlertConfigurations: DeviceStatusAlertConfigurationDto;
}

export interface IDeviceStatusAlertConfigurationPayload {
  deviceId: string;
  dto: UpsertDeviceStatusAlertConfigurationDto;
}

export default {
  namespaced: true,
  state: (): IDeviceStatusAlertConfigurationState =>
    ({
      deviceStatusAlertConfigurations: {},
    }) as IDeviceStatusAlertConfigurationState,
  getters: {
    deviceStatusAlertConfiguration: (state: IDeviceStatusAlertConfigurationState) => () => state.deviceStatusAlertConfigurations,
  },
  mutations: {
    SET_DEVICE_STATUS_ALERT_CONFIGURATION(state: IDeviceStatusAlertConfigurationState, deviceStatusAlertConfiguration: DeviceStatusAlertConfigurationDto) {
      state.deviceStatusAlertConfigurations = deviceStatusAlertConfiguration;
    },
  },
  actions: {
    async getDeviceStatusAlertConfiguration({ commit }: any, deviceId: string) {
      const deviceStatusAlertConfigurationsClient = await authorizedClient(DeviceStatusAlertConfigurationClient);
      try {
        const deviceStatusAlertConfiguration = await deviceStatusAlertConfigurationsClient.getDeviceStatusAlertConfiguration(
          deviceId, process.env.VUE_APP_TERMINAL_WEB_API_VERSION
        );
        if (deviceStatusAlertConfiguration) {
          commit('SET_DEVICE_STATUS_ALERT_CONFIGURATION', deviceStatusAlertConfiguration);
        }
      } catch (error) {
        throw error;
      }
    },
    async upsertDeviceStatusAlertConfiguration({ commit }: any,  { deviceId, dto }: IDeviceStatusAlertConfigurationPayload ) {
      const deviceStatusAlertConfiguration = await authorizedClient(DeviceStatusAlertConfigurationClient);
      try {
        await deviceStatusAlertConfiguration.upsert(deviceId, process.env.VUE_APP_TERMINAL_WEB_API_VERSION, dto);
      } catch (error) {
        throw error;
      }
    },
  },
};

