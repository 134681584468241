<template>
  <div
    v-click-away="closeDropdown"
    class="kw-dropdown-menu"
    :class="position ? `kw-dropdown-menu--${position}` : undefined"
  >
    <div aria-haspopup="menu" @click="onKebabClick()">
      <slot />
    </div>
    <div
      v-if="!disabled"
      class="kw-dropdown"
      :class="{ 'kw-dropdown--hidden': !isDropdownDisplayed }"
      :aria-hidden="!isDropdownDisplayed"
      role="menu"
    >
      <ul class="kw-dropdown__list">
        <li
          v-for="(item, index) in dropdownItems"
          :key="item.name"
          :title="item.name"
          :data-test="`item-${(item as IDropdownItem).name.replace(/\s/g, '-')}`"
          class="kw-dropdown__list-item"
          :aria-setsize="dropdownItems.length"
          :aria-posinset="index + 1"
        >
          <a
            v-if="item.action"
            @click="() => (item as IDropdownItem).action && triggerAction((item as IDropdownItem).action)"
            href="javascript:void(0)"
          >
            <i v-if="item.icon" :class="`icon-${item.icon}`"></i>
            {{ (item as IDropdownItem).name }}
          </a>

          <a
            v-else-if="typeof item.link === 'string' && item.link.startsWith('http')"
            :href="item.link"
            rel="noopener"
            target="_blank"
          >
            <i v-if="item.icon" :class="`icon-${item.icon}`"></i>
            {{ (item as IDropdownItem).name }}
          </a>
          <router-link v-else :to="item.link || ''">
            <i v-if="item.icon" :class="`icon-${item.icon}`"></i>
            {{ (item as IDropdownItem).name }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { IDropdownItem } from './IDropdownItem';

export default {
  props: {
    dropdownItems: {
      type: Array as () => IDropdownItem[],
      required: true,
    },
    disabled: Boolean,
    position: String,
  },
  setup(props) {
    const isDropdownDisplayed = ref(false);

    watch(
      () => props.dropdownItems,
      (dropdownItems) => {
        dropdownItems.forEach((item: any) => {
          if (item.action && item.link) {
            throw new Error(`You must only set one of either the link or action properties for list item ${item.name}`);
          } else if (!item.action && !item.link) {
            throw new Error(
              `You must set exactly one of either the link or action properties for list item ${item.name}`
            );
          }
        });
      },
      { immediate: true }
    );

    const triggerAction = (action: () => void): void => {
      action();
      isDropdownDisplayed.value = false;
    };

    const closeDropdown = (): void => {
      isDropdownDisplayed.value = false;
    };

    const onKebabClick = () => {
      isDropdownDisplayed.value = !isDropdownDisplayed.value;
      const dropdownMenu = document.querySelector('.kw-dropdown-menu');
    };

    //const ondocumentClick = (event?: Event) => {
    //  const dropdownMenu = document.querySelector('.kw-dropdown-menu');
    //  if (event && event.target) {
    //    const target = event.target as HTMLElement;
    //    if (dropdownMenu && !dropdownMenu.contains(target)) {
    //      closeDropdown();
    //    }
    //  }
    //};
    //
    //
    //onMounted(() => {
    //  document.addEventListener('click', ondocumentClick);
    //});
    //
    //onBeforeUnmount(() => {
    //  document.removeEventListener('click', ondocumentClick);
    //});

    return {
      isDropdownDisplayed,
      triggerAction,
      closeDropdown,
      onKebabClick,
    };
  },
};
</script>
