import {
  ClockingsClient,
  ClockingPageMetaDataDto,
  ClockingPageItemDto,
  ModifyClockingAnalysisActionDto,
  ClockingAnalysisActionStatus,
  AnalysisActionDto,
} from '@/apiClients';
import authorizedClient from './authorizedClient';

export interface IClockingsState {
  metaData: ClockingPageMetaDataDto;
}

export interface IGetAllClockingsPayload {
  userId: string;
  startDate: Date;
  endDate: Date;
  pageOffset: number;
  pageSize: number;
  searchTerm: string;
}

export interface IModifyClockingAnalysisStatus {
  clocking: ClockingPageItemDto;
  status: ClockingAnalysisActionStatus;
}

export default {
  namespaced: true as true,
  state: {
    metaData: new ClockingPageMetaDataDto({ filteredTotal: 0, hasAuthenticationType: false, hasAnalysis: false }),
  } as IClockingsState,
  getters: {
    metaData: (state: IClockingsState) => () => state.metaData,
  },

  mutations: {
    ADD_CLOCKING_METADATA(state: IClockingsState, metaData: ClockingPageMetaDataDto) {
      state.metaData = metaData;
    },
  },

  actions: {
    async getAllClockingsWithinRangeForSingleUser({ commit }: any, payload: IGetAllClockingsPayload) {
      const clockingClient = await authorizedClient(ClockingsClient);
      try {
        const result = await clockingClient.getAllClockings2(
          payload.userId,
          payload.startDate,
          payload.endDate,
          payload.pageOffset,
          payload.pageSize,
          payload.searchTerm,
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION
        );
        if (result) {
          if (result.result?.metadata) {
            commit('ADD_CLOCKING_METADATA', result.result?.metadata);
          }
          return result.result?.clockings;
        }
      } catch (error) {
        throw error;
      }
    },
    async setClockingAnalysisStatus({ commit }: any, payload: IModifyClockingAnalysisStatus): Promise<void> {
      const clockingClient = await authorizedClient(ClockingsClient);

      // Get the initial status, so that we can reset it if nececcesary
      const initialAction = payload?.clocking?.analysisAction;

      try {
        // Use -1 as a value to indicate that we're updating the status.
        payload.clocking.analysisAction = {} as AnalysisActionDto;
        payload.clocking.analysisAction.status = -1 as ClockingAnalysisActionStatus;

        // Call the api to update the status in the database. If this fails it will
        // throw, where we're reset the status back to its original value.
        const dto = {} as ModifyClockingAnalysisActionDto;
        dto.status = payload.status;
        const result = await clockingClient.updateAnalysisAction(
          payload.clocking.id,
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION,
          dto
        );

        // Set the final value of the status.
        payload.clocking.analysisAction = {} as AnalysisActionDto;
        payload.clocking.analysisAction.status = payload.status;
      } catch (error) {
        // Reset the status to its initial value
        if (payload?.clocking) {
          payload.clocking.analysisAction = initialAction;
        }

        throw error;
      }
    },
    async getClockingAnalysis({ commit }: any, clocking: ClockingPageItemDto): Promise<void> {
      const clockingClient = await authorizedClient(ClockingsClient);

      try {
        if ((clocking as any).hasAdditionalAnalysis) {
          // The additional details have already been set for this clocking.
          return;
        }

        // Call the endpoint to get the updated analysis items.
        const result = await clockingClient.getAnalysis(clocking.id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION);

        // Replace all of the analysis items on the clocking with the ones returned from the API.
        clocking.analysisTestItemResults = result;

        (clocking as any).hasAdditionalAnalysis = true;
      } catch (error) {
        // Something went wrong, maybe unauthorized, or the clocking no longer exists.
        throw error;
      }
    },
  },
};
