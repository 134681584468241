import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["disabled", "name", "readonly", "checked", "value"]
const _hoisted_2 = {
  key: 2,
  class: "checkbox__message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass([[_ctx.status ? `checkbox--${_ctx.status}` : '', _ctx.visualIcon ? 'checkbox--visual' : ''], "checkbox"]),
    "data-test": "label-Checkbox"
  }, [
    (!_ctx.visualIcon)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["checkbox__label", _ctx.hideLabel ? 'sr-only' : null])
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
      onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
      onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
      disabled: _ctx.disabled,
      name: _ctx.name,
      readonly: _ctx.readonly,
      class: _normalizeClass(_ctx.visualIcon ? 'checkbox--visual__input' : 'checkbox__input'),
      type: "checkbox",
      checked: _ctx.isChecked,
      value: _ctx.isChecked,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isChecked) = $event))
    }, null, 42, _hoisted_1), [
      [_vModelCheckbox, _ctx.isChecked]
    ]),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.visualIcon ? `checkbox--visual__icon icon-${_ctx.visualIcon}` : 'checkbox__tick')
    }, null, 2),
    (_ctx.visualIcon)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass(["checkbox__label", _ctx.hideLabel ? 'sr-only' : null])
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true),
    (_ctx.message)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.message), 1))
      : _createCommentVNode("", true)
  ], 2))
}