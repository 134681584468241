<template>
  <section class="drawer" role="dialog">
    <div class="drawer__overlay" @click="close"></div>
    <div class="drawer__panel">
      <header class="drawer__header">
        {{ header }}
        <slot name="headerExtra" />
        <button class="button--ghost drawer__close" data-test="button-drawerButtonClose" title="Close" @click="close">
          <i class="icon-cross"></i>
        </button>
      </header>
      <section class="drawer__main" :class="{ 'drawer__main--not-scrollable': notScrollable }">
        <slot />
      </section>
      <footer v-if="hasFooterSlot()" class="drawer__footer">
        <slot name="footer" />
      </footer>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeUnmount } from 'vue';

export default defineComponent({
  name: 'Drawer',

  props: {
    header: { type: String, required: true },
    notScrollable: { type: Boolean, default: false },
  },

  emits: ['close'],

  setup(props, { emit, slots }) {
    function escapeKeyHandler(e: KeyboardEvent): void {
      if (e.keyCode === 27 || e.key === 'Escape') {
        emit('close');
      }
    }

    onMounted(() => {
      document.addEventListener('keyup', escapeKeyHandler);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('keyup', escapeKeyHandler);
    });

    function hasFooterSlot(): boolean {
      return !!slots.footer;
    }

    const close = () => emit('close');

    return {
      hasFooterSlot,
      close,
    };
  },
});
</script>

<style lang="scss" scoped>
.drawer {
  &__main {
    &--not-scrollable {
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
    }
  }
}
</style>
