<template>
  <aside class="px-0">
    <nav
      class="app-sidebar"
      :class="{
        'app-sidebar--collapsible': collapsible,
        'app-sidebar--collapsed': isCollapsed && collapsible,
      }"
      :aria-expanded="(!isCollapsed && collapsible) || !collapsible"
    >
      <section v-if="businessName" class="app-sidebar__business">
        <tag class="overflow-x--ellipsis width--100" color="grey" pill :title="businessName">
          {{ businessName }}
        </tag>
      </section>
      <section class="app-sidebar__product">
        <product-select />
      </section>
      <section v-if="!hideDashboard" data-test="section-Dashboard">
        <ul class="app-sidebar__list" role="menu">
          <li class="app-sidebar__list--item" :title="isCollapsed && collapsible ? 'Dashboard' : undefined">
            <router-link
              :to="{ name: 'Dashboard' }"
              class="app-sidebar__list--link"
              :aria-current="calcCurrent('/dashboard')"
            >
              <i class="icon-dashboard app-sidebar__icon"></i>
              <span
                class="app-sidebar--collapsed__text"
                :class="{ 'app-sidebar--collapsed__text--hidden': isCollapsed && collapsible }"
                >Dashboard</span
              >
            </router-link>
          </li>
        </ul>
      </section>
      <section v-for="sidebarItem in sidebarList" :key="sidebarItem.headerText">
        <p
          class="app-sidebar__header app-sidebar--collapsed__text"
          :class="{ 'app-sidebar--collapsed__text--hidden': isCollapsed && collapsible }"
          :id="`sidebar-header-${sidebarItem.headerText}`"
        >
          {{ sidebarItem.headerText }}
        </p>
        <ul class="app-sidebar__list" role="menu" :aria-labelledby="`sidebar-header-${sidebarItem.headerText}`">
          <li v-for="menuItem in sidebarItem.menuItems" :key="menuItem.text" class="app-sidebar__list--item">
            <router-link
              :to="menuItem.to"
              class="app-sidebar__list--link"
              :data-test="`link-${menuItem.text.replace(/\s/g, '')}`"
              :class="{ 'overflow-x--ellipsis': !(isCollapsed && collapsible) }"
              :title="isCollapsed && collapsible ? menuItem.text : undefined"
              :aria-current="calcCurrent(menuItem.to.toString())"
            >
              <i class="app-sidebar__icon" :class="`icon-${menuItem.icon}`"></i>
              <span
                class="app-sidebar--collapsed__text"
                :class="{ 'app-sidebar--collapsed__text--hidden': isCollapsed && collapsible }"
                >{{ menuItem.text }}</span
              >
            </router-link>
            <ul
              v-if="menuItem.subItems"
              class="app-sidebar__list--sub"
              :class="{ 'p-0': isCollapsed && collapsible }"
              role="menu"
            >
              <router-link
                :to="subItem.to"
                v-for="subItem in menuItem.subItems"
                :key="subItem.text"
                custom
                v-slot="{ href }"
              >
                <li class="app-sidebar__list--sub-item" :class="{ 'ml-1': isCollapsed && collapsible }">
                  <a class="app-sidebar__list--sub-item__link font-size--body-text" :href="href">
                    {{ subItem.text }}
                  </a>
                </li>
              </router-link>
            </ul>
          </li>
        </ul>
      </section>
    </nav>
    <ul
      v-if="collapsible"
      class="app-sidebar--collapsible__list-item m-0"
      :class="{ 'app-sidebar--collapsed': isCollapsed && collapsible }"
    >
      <li class="app-sidebar__list--item" :title="isCollapsed && collapsible ? 'Expand menu' : undefined">
        <a @click="isCollapsed = !isCollapsed" href="javascript:void(0)" class="app-sidebar__list--link">
          <i class="app-sidebar__icon" :class="`icon-${isCollapsed && collapsible ? 'expand' : 'collapse'}-menu`"></i>
          <span
            class="app-sidebar--collapsed__text"
            :class="{ 'app-sidebar--collapsed__text--hidden': isCollapsed && collapsible }"
            >{{ isCollapsed ? 'Expand' : 'Collapse' }} menu</span
          >
        </a>
      </li>
    </ul>
  </aside>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { Routes } from '@/router';
import { Tag } from '@/components';
import ProductSelect from './ProductSelect.vue';
import { useRouter } from 'vue-router';

export interface ISidebarMenuItem {
  text: string;
  to: string | object;
}

export interface ISidebarMenuItemWithIcon extends ISidebarMenuItem {
  icon: string;
  subItems: ISidebarMenuItem[];
}

export interface ISidebarItem {
  headerText: string;
  menuItems: ISidebarMenuItemWithIcon[];
}

export default defineComponent({
  components: {
    ProductSelect,
    Tag,
  },
  props: {
    businessName: {
      default: '',
      type: String,
    },
    sidebarList: {
      default: () => [],
      type: Array as () => ISidebarItem[],
    },
    hideDashboard: {
      default: false,
      type: Boolean,
    },
    collapsible: {
      default: false,
      type: Boolean,
    },
  },
  setup(props) {
    const isCollapsed = ref(false);
    const router = useRouter();

    if (screen.width <= 1200) {
      isCollapsed.value = true;
    }

    function calcCurrent(to: string): 'page' | undefined {
      return router.currentRoute.value.path === to ? 'page' : undefined;
    }

    return {
      Routes,
      isCollapsed,
      calcCurrent,
    };
  },
});
</script>

<style lang="scss" scoped>
.app-sidebar {
  &__business,
  &__product {
    padding: 0 1.625rem;
    margin-top: 1.5rem;
  }

  &__business {
    display: flex;
    justify-content: center;
  }
}
</style>
