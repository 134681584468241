<template>
  <div id="app">
    <template v-if="!state.isJwtTokenExpired">
      <div class="container-fluid px-0">
        <app-header :account-uri="state.accountUri" @logoutClicked="logout" />
        <div v-if="hasNetworkError" class="the-app-body">
          <sidebar :business-name="state.businessName" :sidebar-list="sidebarList" hide-dashboard />
          <network-error />
        </div>
        <div v-else class="the-app-body">
          <sidebar :business-name="state.businessName" :sidebar-list="sidebarList" hide-dashboard />
          <router-view />
        </div>
      </div>
    </template>
    <div v-else class="initial-loading-spinner">
      <LoaderSpinner />
    </div>
    <div id="toasts" class="toasts"></div>
  </div>
</template>

<script lang="ts">
import { onMounted } from 'vue';
import { computed, defineComponent, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { AppHeader, LoaderSpinner, Sidebar } from '@/components';
import { ISidebarItem } from '@/components/Global/Sidebar.vue';
import { Routes } from '@/router';
import { container } from 'inversify-props';
import IAuthenticationService from '@/services/IAuthenticationService';
import type { IPendoService } from '@/services/PendoService';
import NetworkError from '@/views/NetworkError.vue';

export default defineComponent({
  components: {
    AppHeader,
    LoaderSpinner,
    Sidebar,
    NetworkError,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const authenticationService = container.get<IAuthenticationService>('authenticationService');
    const pendoService = container.get<IPendoService>('pendoService');
    const hasNetworkError = ref(false);

    let state = reactive({
      accountUri: `${process.env.VUE_APP_AUTH_AUTHORITY_URI}/profile`,
      Routes,
      businessName: '',
      isJwtTokenExpired: true,
    });

    const sidebarList = computed(() => {
      if (route.meta.sidebar === false) {
        return [] as ISidebarItem[];
      }
      return [
        {
          headerText: 'Site Management',
          menuItems: [
            {
              text: 'Sites',
              to: { name: Routes.Sites },
              icon: 'building',
            },
          ],
        },
        {
          headerText: 'User Management',
          menuItems: [
            {
              text: 'Users',
              to: { name: Routes.Users },
              icon: 'user-2',
            },
            {
              text: 'User groups',
              to: { name: Routes.UserGroups },
              icon: 'user-groups',
            },
          ],
        },
        {
          headerText: 'Configuration',
          menuItems: [
            {
              text: 'Tools',
              to: { name: Routes.Tools },
              icon: 'wrench',
            },
            {
              text: 'Tags',
              to: { name: Routes.Tags },
              icon: 'clipboard',
            },
            {
              text: 'Global Device Settings',
              to: { name: Routes.GlobalDeviceSettings },
              icon: 'settings',
            },
          ],
        },
      ] as ISidebarItem[];
    });

    const initializePendo = async () => {
      // do we need to test for this bus name first?
      await pendoService?.initializePendo(state.businessName);
    };

    const getHasTokenExpired = async () => {
      await authenticationService?.getToken();
      const hasTokenExpiredResult = await authenticationService?.hasTokenExpired();
      state.isJwtTokenExpired = hasTokenExpiredResult ? hasTokenExpiredResult : false;
    };

    const logout = async () => {
      await authenticationService?.logout();
    };

    onMounted(async () => {
      const jwt = await authenticationService?.getToken();
      if (jwt != null) {
        await getHasTokenExpired();

        try {
          await store.dispatch('globalConfigurations/getGlobalConfiguration');
        } catch (error) {
          if (error.message == 'Network Error') {
            hasNetworkError.value = true;
          }
        }

        const globalConfigurationGetter = store.getters['globalConfigurations/globalConfiguration'];
        const globalConfiguration = globalConfigurationGetter();

        if (globalConfiguration && globalConfiguration.configuration) {
          const { businessName: fetchedBusinessName } = globalConfiguration.configuration;
          if (fetchedBusinessName != null) {
            state.businessName = fetchedBusinessName;
          }
        }
      }
      await initializePendo();
    });

    return {
      state,
      initializePendo,
      sidebarList,
      Routes,
      logout,
      hasNetworkError,
      NetworkError,
    };
  },
});
</script>

<style lang="scss">
@import 'styles/main';

.initial-loading-spinner {
  display: flex;
  height: 100vh;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  align-items: center;
  justify-content: center;
}
</style>
