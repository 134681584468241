<template>
  <section class="drawer" role="dialog">
    <div class="drawer__overlay" @click="close"></div>

    <div class="drawer__panel drawer__panel--tabbed">
      <aside class="drawer__tabs">
        <ul class="drawer__list">
          <li
            v-for="(tab, index) in tabs"
            :key="index"
            :aria-setsize="tabs.length"
            :aria-posinset="index + 1"
            @keydown.enter="selectTab(tab)"
            @click="selectTab(tab)"
            class="drawer__tab"
          >
            <a
              href="javascript:void(0)"
              :class="{
                'drawer__link--active': tab.isActive,
                [`${tab.name.replace(/\s+/g, '-').toLowerCase()}-active`]: tab.isActive,
                [`${tab.name.replace(/\s+/g, '-').toLowerCase()}-inactive`]: !tab.isActive,
              }"
              :aria-current="tab.isActive ? 'true' : null"
              class="drawer__link"
            >
              {{ tab.name }}
            </a>
          </li>
        </ul>
      </aside>

      <div class="drawer__content">
        <header class="drawer__header">
          <h2 :title="header" class="m-0 overflow-x--ellipsis">{{ header }}</h2>
          <Btn
            class="drawer__close"
            data-test="button-tabDrawerButtonClose"
            icon="cross"
            title="Close"
            @click="close"
            ghost
          />
        </header>
        <section :class="{ 'drawer__main--not-scrollable': notScrollable }" class="drawer__main">
          <slot name="main" />
        </section>
        <footer class="tab-drawer__footer">
          <slot name="footer" />
        </footer>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, provide, onUnmounted, Ref } from 'vue';
import Btn from '../Btn.vue';

interface ITab {
  isActive: Ref<boolean>;
  name: string;
}

export default defineComponent({
  components: {
    Btn,
  },
  props: {
    header: {
      type: String,
      required: true,
    },
    notScrollable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const tabs = ref([]);

    const close = () => {
      emit('close');
    };

    const escapeKeyHandler = (e: KeyboardEvent) => {
      if (e.keyCode === 27 || e.key === 'Escape') {
        close();
      }
    };

    const registerTab = (tab: ITab) => {
      tabs.value.push(tab);
      //Activate the first tab
      if (tabs.value.length === 1) {
        tabs.value[0].isActive = true;
      }
    };

    const unregisterTab = (tab: ITab) => {
      const index = tabs.value.findIndex((t) => t.name === tab.name);
      if (index !== -1) {
        tabs.value.splice(index, 1);
      }
    };

    const selectTab = (selectedTab: ITab) => {
      tabs.value.forEach((tab) => {
        tab.isActive = tab.name === selectedTab.name;
      });
    };

    provide('registerTab', registerTab);
    provide('unregisterTab', unregisterTab);
    provide('selectTab', selectTab);

    onMounted(() => {
      document.addEventListener('keyup', escapeKeyHandler);
    });

    onUnmounted(() => {
      document.removeEventListener('keyup', escapeKeyHandler);
    });

    return {
      close,
      tabs,
      selectTab,
    };
  },
});
</script>

<style lang="scss" scoped>
.drawer {
  &__main {
    overflow-x: hidden;

    &--not-scrollable {
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
    }
  }
  &__header{
    margin-bottom: 0px
  }
}

.m-0 {
  max-width: 350px;
}
</style>
