import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { container } from 'inversify-props';

import {
  AccessSchedulesClient,
  AddressLookupClient,
  AreasClient,
  AuthorizedClient,
  DeviceFirmwareClient,
  DeviceGroupsClient,
  DevicesClient,
  GlobalConfigurationsClient,
  LicenceClient,
  SitesClient,
  UserGroupsClient,
  UsersClient,
  NoticesClient
} from '@/apiClients';
import IAuthenticationService from '@/services/IAuthenticationService';
import apiUrl from '@/utilities/apiHelper';
import { Routes } from '@/router';

type Instantiable<
  T =
    | typeof AccessSchedulesClient
    | typeof AddressLookupClient
    | typeof AreasClient
    | typeof AuthorizedClient
    | typeof DeviceFirmwareClient
    | typeof DeviceGroupsClient
    | typeof DevicesClient
    | typeof GlobalConfigurationsClient
    | typeof LicenceClient
    | typeof SitesClient
    | typeof UserGroupsClient
    | typeof UsersClient
    | typeof NoticesClient,
> = { new (baseUrl?: string, instance?: AxiosInstance): T };

export default async function <T>(client: Instantiable<T>): Promise<T> {
  const authenticationService = container.get<IAuthenticationService>('authenticationService');
  const jwt = await authenticationService.getToken();

  const config: AxiosRequestConfig = { withCredentials: true };
  if (jwt !== null) {
    config.headers = { Authorization: `Bearer ${jwt}` };
  }
  const axiosInstance = axios.create(config);

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    async function (error: AxiosError) {
      if (error.response?.status === 403) {  
        const errorMsg = (error.response?.data as { messages?: { message: string }[] })?.messages?.[0]?.message ?? "An error occurred.";
        let path = window.location.pathname.slice(1);
        if (localStorage.path !== path || localStorage.errorMsg !== errorMsg) {
          if (errorMsg === 'Invalid login') {
            path = Routes.IncorrectPermissions;
          } else if (errorMsg === 'Invalid licence') {
            path = Routes.UnlicensedProduct;
          } else {
            path = Routes.AccessDenied;
          }
          localStorage.path = path;
          localStorage.errorMsg = errorMsg;
          window.location.href = path;
        }
      }
      return Promise.reject(error);
    }
  );

  return new client(apiUrl(), axiosInstance);
}
