/* eslint-disable prettier/prettier */
import {
  TagsClient,
  TagDto,
  CreateTagDto,
  TagListMetaDataDto,
  UserGroupTagsClient,
  UserGroupTagListSelectDto,
  UserGroupTagListSelectMetaDataDto,
  UserGroupTagSelectDto,
} from '@/apiClients';
import authorizedClient from './authorizedClient';
import { Dictionary } from '@/extensions/types';
 
// export interface ITagsUserGroupState {
//   tags: Dictionary<UserGroupTagListSelectDto>;
//   lastPage: number;
//   metaData: UserGroupTagListSelectMetaDataDto;
// }
 
export interface ITagState {
  tags: TagDto[];
  allTags: TagDto[];
  allTagsUserGroup: UserGroupTagSelectDto[];
  tagGroups: string[];
  allSearchTags: Dictionary<TagDto>;
  metaDataTag: TagListMetaDataDto;
  tagsUserGroup: Dictionary<UserGroupTagListSelectDto>;
  metaDataTagUserGroup: UserGroupTagListSelectMetaDataDto;
}
 
export interface IGetTagsPayload {
  pageOffset: number;
  pageSize: number;
  searchTerm: string;
}
 
export interface IGetAllTagsOnUserGroup {
  userGroupId: string;
  pageOffset: number;
  pageSize: number;
  searchTerm: string;
}
 
export default {
  namespaced: true as true,
  state: () =>
  ({
    tags: {},
    allTags: {},
    allTagsUserGroup: {},
    tagGroups: {},
    allSearchTags: {},
    metaDataTag: new TagListMetaDataDto({ filteredTotal: 0 }),
    metaDataTagUserGroup: new UserGroupTagListSelectMetaDataDto({ totalFiltered: 0, totalSelected: 0 }),
  }) as ITagState,
  getters: {
    tags:
      (state: ITagState) =>
      (id: string): TagDto[] => {
        return state.tags;
      },
    allTags:
      (state: ITagState) =>
      (id: string): TagDto[] => {
        return state.allTags;
      },
    allTagsUserGroup:
      (state: ITagState) =>
      (id: string): UserGroupTagSelectDto[] => {
        return state.allTagsUserGroup;
      },
    tagGroups:
      (state: ITagState) =>
      (id: string): string[] => {
        return state.tagGroups;
      },
    allSearchTags: (state: ITagState) => () => state.allSearchTags,
    metaDataTag: (state: ITagState) => () => state.metaDataTag,
    metaDataTagUserGroup: (state: ITagState) => () => state.metaDataTagUserGroup,
  },
  mutations: {
    ADD_TAGS_FOR_USER(state: ITagState, tags: TagDto[]) {
      state.tags = tags;
    },
    ADD_ALL_TAGS(state: ITagState, allTags: TagDto[]) {
      state.allTags = allTags;
    },
    ADD_TAGS_GROUPS(state: ITagState, tagGroups: string[]) {
      state.tagGroups = tagGroups;
    },
    ADD_ALL_SEARCH_TAGS(state: ITagState, allSearchTags: TagDto[]) {
      allSearchTags.forEach((tag) => {
        state.allSearchTags[tag.id] = tag;
      });
    },
    ADD_TAG_METADATA(state: ITagState, metaDataTag: TagListMetaDataDto) {
      state.metaDataTag = metaDataTag;
    },
    ADD_ALL_TAGS_USERGROUP(state: ITagState, allTagsUserGroup: UserGroupTagSelectDto[]) {
      state.allTagsUserGroup = allTagsUserGroup;
    },
    ADD_TAG_METADATA_USERGROUP(state: ITagState, metaDataTagUserGroup: UserGroupTagListSelectMetaDataDto) {
      state.metaDataTagUserGroup = metaDataTagUserGroup;
    },
  },
  actions: {
    async getTagsByUser({ commit }: any, id: string) {
      const tagsClient = await authorizedClient(TagsClient);
      await tagsClient
        .getTagsByUser(id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        })
        .then((result) => {
          commit('ADD_TAGS_FOR_USER', result);
        });
    },
    async getTagGroups({ commit }: any) {
      const tagsClient = await authorizedClient(TagsClient);
      await tagsClient
        .getTagGroups(process.env.VUE_APP_TERMINAL_WEB_API_VERSION)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        })
        .then((result) => {
          commit('ADD_TAGS_GROUPS', result);
        });
    },
    async getAllTagsAll({ commit }: any) {
      const tagsClient = await authorizedClient(TagsClient);
      await tagsClient
        .getAllTagsAll(process.env.VUE_APP_TERMINAL_WEB_API_VERSION)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        })
        .then((result) => {
          commit('ADD_ALL_TAGS', result);
        });
    },
    async createTag({ commit }: any, userTag: CreateTagDto) {
      const tagsClient = await authorizedClient(TagsClient);
      try {
        return await tagsClient.create(process.env.VUE_APP_TERMINAL_WEB_API_VERSION, userTag);
      } catch (error) {
        throw error;
      }
    },
    async deleteTag({ commit }: any, id: string) {
      const tagsClient = await authorizedClient(TagsClient);
      try {
        return await tagsClient.delete(id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
      } catch (error) {
        throw error;
      }
    },
    async getAllTags({ commit }: any, payload: IGetTagsPayload) {
      const tagsClient = await authorizedClient(TagsClient);
      try {
        const result = await tagsClient.getAllTags(
          payload.pageOffset,
          payload.pageSize,
          payload.searchTerm,
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION
        );
        if (result) {
          if (result.tags) {
            commit('ADD_ALL_SEARCH_TAGS', result.tags);
          }
          if (result.metaData) {
            commit('ADD_TAG_METADATA', result.metaData);
          }
          return result.tags;
        }
      } catch (error) {
        throw error;
      }
      return [];
    },
    async getAllTagsOnUserGroup({ commit }: any, payload: IGetAllTagsOnUserGroup) {
      const userGroupTagsClient = await authorizedClient(UserGroupTagsClient);
      try {
        const result = await userGroupTagsClient.getAllTags(
          payload.userGroupId,
          payload.pageOffset,
          payload.pageSize,
          payload.searchTerm,
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION
        );
        if (result) {
          if (result.tags) {
            commit('ADD_ALL_SEARCH_TAGS', result.tags);
          }
          if (result.metaData) {
            commit('ADD_TAG_METADATA_USERGROUP', result.metaData);
          }
          return result.tags;
        }
      } catch (error) {
        throw error;
      }
      return [];
    },
  },
};