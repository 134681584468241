import { GlobalConfigurationDto, GlobalConfigurationsClient, UpsertGlobalConfigurationDto } from '@/apiClients';

import authorizedClient from './authorizedClient';

export interface IGlobalConfigurationState {
  globalConfigurations: GlobalConfigurationDto;
}

export default {
  namespaced: true,
  state: (): IGlobalConfigurationState =>
    ({
      globalConfigurations: {},
    }) as IGlobalConfigurationState,
  getters: {
    globalConfiguration: (state: IGlobalConfigurationState) => () => state.globalConfigurations,
  },
  mutations: {
    SET_GLOBAL_CONFIGURATION(state: IGlobalConfigurationState, globalConfiguration: GlobalConfigurationDto) {
      state.globalConfigurations = globalConfiguration;
    },
  },
  actions: {
    async getGlobalConfiguration({ commit }: any) {
      const globalConfigurationsClient = await authorizedClient(GlobalConfigurationsClient);
      try {
        const globalConfiguration = await globalConfigurationsClient.getGlobalConfiguration(
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION
        );
        if (globalConfiguration) {
          commit('SET_GLOBAL_CONFIGURATION', globalConfiguration);
        }
      } catch (error) {
        throw error;
      }
    },
    async upsert({ commit }: any, dto: UpsertGlobalConfigurationDto) {
      const globalConfigurationsClient = await authorizedClient(GlobalConfigurationsClient);
      try {
        await globalConfigurationsClient.upsert(process.env.VUE_APP_TERMINAL_WEB_API_VERSION, dto);
      } catch (error) {
        throw error;
      }
    },
  },
};
