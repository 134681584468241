import { injectable } from 'inversify-props';
import IAuthenticationService from './IAuthenticationService';
import { IAuthenticator } from '@/authentication/IAuthenticator';
import { Authenticator } from '@/authentication/Authenticator';
import { AccountProfile } from '@/authentication/AccountProfile';
import { PermissionsClient, PermissionsDto } from '@/apiClients';
import axios, { AxiosRequestConfig } from 'axios';
import { getCookie, setCookie, deleteCookie } from '@/utilities/cookies';
import apiUrl from '@/utilities/apiHelper';

@injectable()
export default class AuthenticationService implements IAuthenticationService {
  private readonly permissionsCookieName: string = 'permissions';

  private authenticator: IAuthenticator;

  public constructor() {
    const origin = window.location.origin;
    this.authenticator = new Authenticator(
      process.env.VUE_APP_AUTH_AUTHORITY_URI,
      'mitrefinch.terminal.web',
      origin + '/signin-callback.html',
      origin + '/silent-callback.html',
      'mitrefinch.terminal.web.api',
      origin
    );
  }

  public getAccountProfile(): Promise<AccountProfile | null> {
    return this.authenticator.getAccountProfile();
  }

  public async getToken(): Promise<string | null> {
    let token = await this.authenticator.getToken();

    if (token === null && (await this.authenticator.hasTokenExpired())) {
      await this.authenticator.renewToken();
      token = await this.authenticator.getToken();
    }
    if (token === null) {
      await this.logout();
    }

    return token;
  }

  public hasTokenExpired(): Promise<boolean> {
    return this.authenticator.hasTokenExpired();
  }

  public login(): Promise<void> {
    return this.authenticator.login();
  }

  public logout(): Promise<void> {
    deleteCookie(this.permissionsCookieName);
    return this.authenticator.logout();
  }

  public async getPermission(name: keyof PermissionsDto): Promise<boolean> {
    const permissions = await this.getPermissions();
    if (permissions === null) {
      // If something has gone wrong and permissions couldn't be determined, default to disallow.
      return false;
    }
    const permitted = permissions[name];
    if (typeof permitted !== 'boolean') {
      throw `${name} not a valid permission name`;
    }
    return permitted;
  }

  private async getPermissions(): Promise<PermissionsDto | null> {
    const permissionsCookie = getCookie(this.permissionsCookieName);
    if (permissionsCookie) {
      return JSON.parse(permissionsCookie) as PermissionsDto;
    }

    const jwt = await this.getToken();
    const axiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      withCredentials: true,
    } as AxiosRequestConfig);
    const permissionsClient = new PermissionsClient(apiUrl(), axiosInstance);
    try {
      const permissions = await permissionsClient.getPermissions(process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
      setCookie(this.permissionsCookieName, JSON.stringify(permissions), 1);
      return permissions;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to get user permission from API');
    }
    return null;
  }
}
