import { AccessSchedulesClient, ModifyAccessScheduleByUserGroupDto, AccessScheduleUserGroupSelectDto, AccessScheduleUserGroupListSelectMetaDataDto, AccessScheduleDto } from '@/apiClients';
import ArrayHelper from '@/utilities/arrayHelper';
import Search from '@/utilities/search';
import authorizedClient from './authorizedClient';
import { Dictionary } from '@/extensions/types';

export interface IGetAccessScheduleUserGroupSummariesPayload {
  id: string;
  pageOffset: number;
  pageSize: number;
  searchTerm: string;
}

export interface IAccessScheduleByUserGroupState {
  userGroupSummaries: Dictionary<AccessScheduleUserGroupSelectDto>;
  metaData: AccessScheduleUserGroupListSelectMetaDataDto;
}

export interface IAccessSchedulesState {
  accessSchedules: IAccessSchedulesForSite[];
}

interface IAccessSchedulesForSite {
  id: string;
  accessSchedules: AccessScheduleDto[];
}

export interface IModifyAccessSchedulesPayload {
  siteId: string;
  accessSchedules: AccessScheduleDto[];
}

export interface IModifyAccessScheduleByUserGroupsPayload {
  Id: string;
  accessScheduleByUserGroup: ModifyAccessScheduleByUserGroupDto;
}

export default {
  namespaced: true,
  state() {
    return {
      accessSchedules: { accessSchedules: [] },
      userGroupState: {
        userGroupSummaries: {},
        metaData: new AccessScheduleUserGroupListSelectMetaDataDto(),
      },
    };
  },
  getters: {
    userGroup: (state: IAccessScheduleByUserGroupState) => (id: string) => state.userGroupSummaries[id],
    metaData: (state: IAccessScheduleByUserGroupState) => () => state.metaData,
    accessSchedules: (state: IAccessSchedulesState) => (id: string) => state.accessSchedules.find(x => x.id === id),
    filteredAccessSchedules: (state: IAccessSchedulesState) => (id: string, searchTerm: string) => {
      const accessSchedulesForSite = state.accessSchedules.find(x => x.id === id);
      if (!accessSchedulesForSite) return [];
      return searchTerm === '' ? accessSchedulesForSite.accessSchedules : accessSchedulesForSite.accessSchedules.filter(accessSchedule => filterAccessSchedules(accessSchedule, searchTerm));
    },
  },
  mutations: {
    ADD_ACCESS_SCHEDULES(state: IAccessSchedulesState, accessSchedules: IAccessSchedulesForSite): void {
      ArrayHelper.pushOrUpdate(state.accessSchedules, accessSchedules, x => x.id === accessSchedules.id);
    },
    REMOVE_ACCESS_SCHEDULES(state: IAccessSchedulesState, id: string): void {
      ArrayHelper.removeItemWhere(state.accessSchedules, x => x.id === id);
    },
    ADD_USER_GROUP_SUMMARIES(state: IAccessScheduleByUserGroupState, userGroupSummaries: AccessScheduleUserGroupSelectDto[]): void {
      if (!state.userGroupSummaries) {
        state.userGroupSummaries = {};
      }
      userGroupSummaries.forEach(summary => {
        state.userGroupSummaries[summary.id] = summary;
      });
    },
    ADD_USER_GROUP_METADATA(state: IAccessScheduleByUserGroupState, metaData: AccessScheduleUserGroupListSelectMetaDataDto): void {
      state.metaData = metaData;
    },
  },
  actions: {
    async getAccessSchedules({ commit }: any, id: string): Promise<void> {
      const accessSchedulesClient = await authorizedClient(AccessSchedulesClient);
      try {
        const accessSchedules = await accessSchedulesClient.getAllAccessSchedulesForASite(id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
        if (accessSchedules) {
          commit('ADD_ACCESS_SCHEDULES', { id, accessSchedules } as IAccessSchedulesForSite);
        }
      } catch (error) {
        throw error;
      }
    },
    async modifyAccessSchedules({ commit }: any, payload: IModifyAccessSchedulesPayload): Promise<void> {
      if (!payload.siteId) {
        throw new Error('A site ID must be provided when updating access schedules');
      }
      const accessSchedulesClient = await authorizedClient(AccessSchedulesClient);
      try {
        await accessSchedulesClient.modifyAllForSite(payload.siteId, process.env.VUE_APP_TERMINAL_WEB_API_VERSION, payload.accessSchedules);
      } catch (error) {
        throw error;
      }
    },
    async modifyAccessScheduleByUserGroups({ commit }: any, payload: IModifyAccessScheduleByUserGroupsPayload): Promise<void> {
      if (!payload.Id) {
        throw new Error('An ID must be provided when updating access schedules');
      }
      const accessSchedulesClient = await authorizedClient(AccessSchedulesClient);
      try {
        await accessSchedulesClient.modifyAccessScheduleByUserGroups(payload.Id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION, payload.accessScheduleByUserGroup);
      } catch (error) {
        throw error;
      }
    },

    async getAccessScheduleUserGroupsByDevice({ commit }: any, payload: IGetAccessScheduleUserGroupSummariesPayload) {
      const accessSchedulesClient = await authorizedClient(AccessSchedulesClient);
      try {
        const result = await accessSchedulesClient.getAccessScheduleUserGroupsByDevice(payload.id, payload.pageOffset, payload.pageSize, payload.searchTerm, process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
        if (result) {
          if (result.metaData) {
            commit('ADD_USER_GROUP_METADATA', result.metaData);
            
          }
          if (result.userGroups) {
            commit('ADD_USER_GROUP_SUMMARIES', result.userGroups);
          }

          return result.userGroups;
        }
      } catch (error) {
        throw error;
      }
      return [];
    },




  },
};

function filterAccessSchedules(accessSchedule: AccessScheduleDto, searchTerm: string): boolean {
  return Search.fuzzySearch(accessSchedule.name, searchTerm);
}