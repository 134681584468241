import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_workspace_network_error = _resolveComponent("workspace-network-error")!
  const _component_activity_template = _resolveComponent("activity-template")!

  return (_openBlock(), _createBlock(_component_activity_template, { header: "Network Error" }, {
    default: _withCtx(() => [
      _createVNode(_component_workspace_network_error, { message: "There was a problem connecting to the server. The server may not exist or it is unavailable at this time. Check your network connection and try again." })
    ]),
    _: 1
  }))
}