<template>
  <div class="chip" @click="click">
    <div class="chip-content">{{ name }}: {{ count }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, SetupContext } from 'vue';

interface Props {
  isSelected: boolean;
  name: string;
  count: number;
}

interface Emits {
  click: () => void;
}

export default defineComponent({
  name: 'Chip',
  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
  emits: ['click'],
  setup(props: Props, context: SetupContext<Emits>) {
    function click() {
      context.emit('click');
    }

    return {
      click,
    };
  },
});
</script>

<style lang="scss" scoped>
.chip {
  display: inline-flex;
  flex-direction: row;
  cursor: default;
  outline: none;
  padding: 0;
  white-space: nowrap;
  align-items: center;
  vertical-align: middle;
  text-decoration: none;
  justify-content: center;
  box-sizing: border-box;
  height: 32px;
  border-radius: 20px;
  margin-right: 8px;
  border: 1px solid #008aff;
  background-color: rgba(0, 138, 255, 0.2);
}

.chip-content {
  cursor: pointer;
  height: 18px;
  color: var(--kw-default-text-color);
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 12px;
  font-size: 12px;
  padding-right: 12px;
  display: inline-block;
}
</style>
