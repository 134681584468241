import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.to ? 'router-link' : 'button'), {
    to: _ctx.to,
    class: _normalizeClass([
      _ctx.ghost ? 'button--ghost' : 'button',
      _ctx.color ? `button--${_ctx.color}` : '',
      {
        'button--create': _ctx.create && _ctx.small,
        'button--create-large': _ctx.create && !_ctx.small,
        'button--shadow': _ctx.shadow,
        'button--small': !_ctx.create && _ctx.small,
        'button--square': _ctx.square,
        'width--100': _ctx.fullWidth,
        'button--compact': _ctx.compact,
        'button--smart-filter': _ctx.smartFilter,
      },
    ]),
    type: !_ctx.to && _ctx.type,
    form: !_ctx.to && _ctx.form,
    disabled: !_ctx.to && _ctx.disabled
  }, {
    default: _withCtx(() => [
      (!_ctx.iconBeforeText)
        ? _renderSlot(_ctx.$slots, "default", { key: 0 })
        : _createCommentVNode("", true),
      (_ctx.icon || _ctx.create)
        ? (_openBlock(), _createElementBlock("i", {
            key: 1,
            class: _normalizeClass(`icon-${_ctx.create ? 'plus' : _ctx.icon}`)
          }, null, 2))
        : _createCommentVNode("", true),
      (_ctx.iconBeforeText)
        ? _renderSlot(_ctx.$slots, "default", { key: 2 })
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["to", "class", "type", "form", "disabled"]))
}