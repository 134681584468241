<template>
  <label class="numberbox textbox" :class="required ? 'textbox--required' : null">
    <span :class="hideLabel ? 'sr-only' : null">
      {{ label }}
      <template v-if="required">
        <i class="textbox__asterisk" aria-hidden="true">*</i><i class="sr-only">(required)</i>
      </template>
    </span>
    <span class="textbox__entry numberbox__entry textbox__icon pl-0" :class="status ? `textbox--${status}` : ''">
      <input
        :value="inputNumber"
        class="textbox__input"
        :disabled="disabled"
        :max="max"
        :min="min"
        :readonly="readonly"
        :required="required"
        :step="step"
        type="number"
        @blur="onBlur"
        @change="onChange"
        @input="onInput"
      />
    </span>
    <span v-if="message" class="textbox__message">{{ message }}</span>
  </label>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed, onMounted } from 'vue';

export default defineComponent({
  name: 'Numberbox',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    max: {
      type: Number,
      default: 100,
    },
    message: {
      type: String,
    },
    min: {
      type: Number,
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: undefined,
      validator: (value: string): boolean => ['success', 'error'].includes(value),
    },
    step: {
      type: Number,
    },
    value: {
      type: [Number, String],
    },
  },
  emits: ['blur', 'change', 'input'],
  setup(props, { emit }) {
    const inputNumber = ref(props.value ?? 0);

    watch(
      () => props.value,
      (newValue) => {
        if (newValue != null && newValue !== inputNumber.value) {
          inputNumber.value = newValue;
        }
      }
    );

    onMounted(() => {
      if (props.value != null) {
        inputNumber.value = props.value;
      }

      if (props.min != null && props.max != null && props.max < props.min) {
        throw new Error('The max prop should not have a value less than the min prop.');
      }

      if (props.readonly && props.disabled) {
        throw new Error('The readonly prop and the disabled prop cannot both be set.');
      }
    });

    const onBlur = (event: any) => {
      emit('blur', event.target.valueAsNumber);
    };

    const onChange = (event: any) => {
      emit('change', event.target.valueAsNumber);
    };

    const onInput = (event: any) => {
      emit('input', event.target.valueAsNumber);
    };

    return {
      onBlur,
      onChange,
      onInput,
      inputNumber,
    };
  },
});
</script>

<style lang="scss" scoped>
.numberbox__entry {
  min-width: 4.125rem;

  input {
    width: 100%;
  }
}
</style>
