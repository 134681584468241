import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, renderSlot as _renderSlot, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["aria-expanded", "aria-controls"]
const _hoisted_2 = { class: "kw-collapsible-section__heading-text" }
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["kw-collapsible-section", { 'kw-collapsible-section--expanded': _ctx.isSectionBodyDisplayed }])
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.headingTag), { class: "kw-collapsible-section__heading" }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          class: "kw-collapsible-section__button",
          "aria-expanded": _ctx.isSectionBodyDisplayed,
          "aria-controls": _ctx.collapsibleSectionId,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isSectionBodyDisplayed = !_ctx.isSectionBodyDisplayed))
        }, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.header), 1),
          _createElementVNode("i", {
            class: _normalizeClass(["kw-collapsible-section__chevron", `icon-chevron-${_ctx.isSectionBodyDisplayed ? 'up' : 'down'}`]),
            "aria-hidden": "true"
          }, null, 2)
        ], 8, _hoisted_1)
      ]),
      _: 1
    })),
    (_ctx.isSectionBodyDisplayed)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "kw-collapsible-section__body",
          id: _ctx.collapsibleSectionId
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 8, _hoisted_3))
      : _createCommentVNode("", true)
  ], 2))
}