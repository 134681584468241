import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["aria-hidden"]
const _hoisted_2 = { class: "kw-dropdown__list" }
const _hoisted_3 = ["title", "data-test", "aria-setsize", "aria-posinset"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["kw-dropdown-menu", $props.position ? `kw-dropdown-menu--${$props.position}` : undefined])
  }, [
    _createElementVNode("div", {
      "aria-haspopup": "menu",
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.onKebabClick()))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    (!$props.disabled)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["kw-dropdown", { 'kw-dropdown--hidden': !$setup.isDropdownDisplayed }]),
          "aria-hidden": !$setup.isDropdownDisplayed,
          role: "menu"
        }, [
          _createElementVNode("ul", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.dropdownItems, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: item.name,
                title: item.name,
                "data-test": `item-${(item as IDropdownItem).name.replace(/\s/g, '-')}`,
                class: "kw-dropdown__list-item",
                "aria-setsize": $props.dropdownItems.length,
                "aria-posinset": index + 1
              }, [
                (item.action)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      onClick: () => (item as IDropdownItem).action && $setup.triggerAction((item as IDropdownItem).action),
                      href: "javascript:void(0)"
                    }, [
                      (item.icon)
                        ? (_openBlock(), _createElementBlock("i", {
                            key: 0,
                            class: _normalizeClass(`icon-${item.icon}`)
                          }, null, 2))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString((item as IDropdownItem).name), 1)
                    ], 8, _hoisted_4))
                  : (typeof item.link === 'string' && item.link.startsWith('http'))
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 1,
                        href: item.link,
                        rel: "noopener",
                        target: "_blank"
                      }, [
                        (item.icon)
                          ? (_openBlock(), _createElementBlock("i", {
                              key: 0,
                              class: _normalizeClass(`icon-${item.icon}`)
                            }, null, 2))
                          : _createCommentVNode("", true),
                        _createTextVNode(" " + _toDisplayString((item as IDropdownItem).name), 1)
                      ], 8, _hoisted_5))
                    : (_openBlock(), _createBlock(_component_router_link, {
                        key: 2,
                        to: item.link || ''
                      }, {
                        default: _withCtx(() => [
                          (item.icon)
                            ? (_openBlock(), _createElementBlock("i", {
                                key: 0,
                                class: _normalizeClass(`icon-${item.icon}`)
                              }, null, 2))
                            : _createCommentVNode("", true),
                          _createTextVNode(" " + _toDisplayString((item as IDropdownItem).name), 1)
                        ]),
                        _: 2
                      }, 1032, ["to"]))
              ], 8, _hoisted_3))
            }), 128))
          ])
        ], 10, _hoisted_1))
      : _createCommentVNode("", true)
  ], 2)), [
    [_directive_click_away, $setup.closeDropdown]
  ])
}