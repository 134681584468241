export function forceStringVal(val: string | null | undefined): string {
  if (val === null || val === undefined) {
    return '';
  }
  return val;
}

export function createTextWithEllipsis(longText: string, maxLength: number): string {
  if (longText.length > maxLength) {
    return longText.substring(0, maxLength) + '...';
  } else {
    return longText;
  }
}
