import { NoticesClient, BusinessNoticeDto } from '@/apiClients';

import authorizedClient from './authorizedClient';

export interface IBusinessNoticeState {
  businessNotices: BusinessNoticeDto[];
}

export default {
  namespaced: true,
  state: (): IBusinessNoticeState =>
    ({
      businessNotices: {},
    }) as IBusinessNoticeState,
  getters: {
    businessNotices: (state: IBusinessNoticeState) => () => state.businessNotices,
  },
  mutations: {
    SET_BUSINESS_NOTICES(state: IBusinessNoticeState, businessNotices: BusinessNoticeDto[]) {
      state.businessNotices = businessNotices;
    },
  },
  actions: {
    async get({ commit }: any) {
      const businessNoticesClient = await authorizedClient(NoticesClient);
      try {
        const businessNotices = await businessNoticesClient.get(
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION
        );
        if (businessNotices) {
          commit('SET_BUSINESS_NOTICES', businessNotices);
        }
      } catch (error) {
        throw error;
      }
    },
    async update({ commit }: any, dto: BusinessNoticeDto[]) {
      const businessNoticesClient = await authorizedClient(NoticesClient);
      try {
        await businessNoticesClient.update(
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION,
          dto);
      } catch (error) {
        throw error;
      }
    },
  },
};
