<template>
  <main class="container-fluid activity" :class="notScrollable ? 'overflow-y--hidden' : ''">
    <header class="activity__header">
      <Btn
        v-if="backArrowLink"
        :to="backArrowLink"
        class="activity__back-arrow"
        data-test="link-backArrowLeft"
        ghost
        :disabled="!backArrowLink"
      >
        <i class="icon-arrow-left"></i>
      </Btn>
      <h1
        :class="[{ 'ml-2 mr-3': backArrowLink, 'activity__header--fill-space': headerFlex }]"
        class="overflow-x--ellipsis"
        data-test="header-activityHeader"
        :title="header"
      >
        {{ header }}
      </h1>
      <slot name="additionalContent" />
    </header>
    <section
      class="activity__main"
      :class="[{ 'activity__main--not-scrollable': notScrollable, 'p-0': fillMaxWorkspace }]"
    >
      <div class="container-fluid height--100 px-0">
        <slot></slot>
      </div>
    </section>
    <activity-loader v-if="isActivityLoading" data-test="activity-loader" />
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Btn from '@/components/Btn.vue';
import ActivityLoader from '@/components/Global/ActivityLoader.vue';

export default defineComponent({
  components: {
    ActivityLoader,
    Btn,
  },
  props: {
    backArrowLink: {
      type: [Object, String],
      default: undefined,
    },
    header: {
      type: String,
      default: '',
    },
    headerFlex: {
      type: Boolean,
      default: false,
    },
    fillMaxWorkspace: {
      type: Boolean,
      default: false,
    },
    notScrollable: {
      type: Boolean,
      default: false,
    },
    isActivityLoading: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss">
:root {
  --tw-back-arrow-color: var(--kw-color-light-theme-grey-3);
}

.theme--dark {
  --tw-back-arrow-color: var(--kw-color-dark-theme-grey-3);
}
</style>

<style lang="scss" scoped>
.activity {
  padding: 0;
  position: relative;
  overflow-x: hidden;

  &__header {
    align-items: center;
    display: flex;
    padding: 0 3rem;
    overflow-x: hidden;

    &--fill-space {
      flex: 1;
    }

    > h1 {
      flex: 1;
    }
  }

  &__back-arrow {
    color: var(--tw-back-arrow-color);
    font-size: 2.5rem;

    .icon-arrow-left {
      line-height: initial;
      top: auto;
    }
  }

  &__main {
    overflow-x: hidden;

    &--not-scrollable {
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
    }
  }
}
</style>
