<template>
  <section class="unlicensed-product height--100 d-flex flex-column align-items-center justify-content-center">
    <div class="row">
      <div class="col-auto">
        <svg
          width="258px"
          height="175px"
          viewBox="0 0 258 175"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>8F69EE14-B158-43CA-9E1B-64AAD02C6315</title>
          <defs>
            <linearGradient x1="100%" y1="62.5%" x2="0%" y2="37.5989759%" id="linearGradient-1">
              <stop stop-color="#86D472" offset="0%"></stop>
              <stop stop-color="#008AFF" offset="100%"></stop>
            </linearGradient>
            <filter x="-5.2%" y="-13.5%" width="110.5%" height="127.1%" filterUnits="objectBoundingBox" id="filter-2">
              <feGaussianBlur stdDeviation="5.7" in="SourceGraphic"></feGaussianBlur>
            </filter>
            <linearGradient x1="105.462326%" y1="50%" x2="-21.4281156%" y2="53.0411224%" id="linearGradient-3">
              <stop stop-color="#86D472" offset="0%"></stop>
              <stop stop-color="#008AFF" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="92.5011659%" y1="40.4428694%" x2="16.7605412%" y2="59.2684722%" id="linearGradient-4">
              <stop stop-color="#FF9F12" offset="0%"></stop>
              <stop stop-color="#FFBB00" offset="100%"></stop>
            </linearGradient>
          </defs>
          <g id="v0.5" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Error-state---Generic-error" transform="translate(-710.000000, -353.000000)">
              <g id="Group-2" transform="translate(627.000000, 353.000000)">
                <g id="MF-Secure---Dark" transform="translate(96.000000, 0.000000)">
                  <g id="Base" transform="translate(0.000000, 28.500000)">
                    <path
                      d="M7.09970394,68.4168341 L110.717308,16.2507302 C113.930759,14.6329235 117.719334,14.6269643 120.937859,16.2346538 L225.39304,68.4111909 C227.3644,69.3959071 228.164232,71.7922799 227.179516,73.7636393 C226.79182,74.5397896 226.161707,75.1684236 225.384648,75.5542946 L120.913788,127.432298 C117.708419,129.024015 113.941629,129.018111 110.741266,127.416353 L7.10812244,75.5487306 C5.13753668,74.562467 4.33958592,72.1654669 5.32584953,70.1948812 C5.71031742,69.4267022 6.33243478,68.8031144 7.09970394,68.4168341 Z"
                      id="Shadow"
                      fill="url(#linearGradient-1)"
                      opacity="0.15"
                      filter="url(#filter-2)"
                    ></path>
                    <path
                      d="M7.09970394,56.4468341 L110.717308,4.28073023 C113.930759,2.66292354 117.719334,2.6569643 120.937859,4.26465381 L225.39304,56.4411909 C227.3644,57.4259071 228.164232,59.8222799 227.179516,61.7936393 C226.79182,62.5697896 226.161707,63.1984236 225.384648,63.5842946 L120.913788,115.462298 C117.708419,117.054015 113.941629,117.048111 110.741266,115.446353 L7.10812244,63.5787306 C5.13753668,62.592467 4.33958592,60.1954669 5.32584953,58.2248812 C5.71031742,57.4567022 6.33243478,56.8331144 7.09970394,56.4468341 Z"
                      id="Gradient"
                      fill="url(#linearGradient-1)"
                    ></path>
                    <path
                      d="M7.09970394,54.7368341 L110.717308,2.57073023 C113.930759,0.952923541 117.719334,0.946964302 120.937859,2.55465381 L225.39304,54.7311909 C227.3644,55.7159071 228.164232,58.1122799 227.179516,60.0836393 C226.79182,60.8597896 226.161707,61.4884236 225.384648,61.8742946 L120.913788,113.752298 C117.708419,115.344015 113.941629,115.338111 110.741266,113.736353 L7.10812244,61.8687306 C5.13753668,60.882467 4.33958592,58.4854669 5.32584953,56.5148812 C5.71031742,55.7467022 6.33243478,55.1231144 7.09970394,54.7368341 Z"
                      fill="#282831"
                    ></path>
                    <ellipse
                      id="Oval"
                      fill="#000000"
                      opacity="0.15"
                      cx="115.71"
                      cy="57.855"
                      rx="49.59"
                      ry="24.795"
                    ></ellipse>
                    <path
                      d="M112.368355,99.5564148 C112.500001,99.0290843 112.960315,98.5466017 113.749299,98.1089671 L126.028418,91.8515433 C127.202687,91.3518555 128.540258,91.1395867 130.041131,91.2147369 C131.957892,91.3107112 133.305529,92.0038146 133.755791,92.3419907 C134.206052,92.6801668 134.732705,93.3524718 134.431183,94.2583221 C134.281096,94.7092236 133.903727,95.1179893 133.299076,95.4846192 L121.313176,101.653722 C120.1492,102.284574 118.818553,102.6 117.321235,102.6 C114.507099,102.6 113.156313,101.472746 112.797879,101.152642 C112.439444,100.832539 112.143224,100.458218 112.368355,99.5564148 Z"
                      id="Mark"
                      fill="url(#linearGradient-3)"
                    ></path>
                  </g>
                  <g id="Group-2-Copy" transform="translate(50.730000, 0.000000)">
                    <path
                      d="M17.2477523,60.7662981 C19.0717523,59.8542981 24.3157523,57.3462981 27.1657523,55.8642981 C17.2477523,51.1902981 6.53175235,37.7382981 31.3837523,27.8202981 C46.5710048,21.7593303 67.8637523,24.5142981 78.4657523,29.9862981 C79.6057523,29.3022981 86.3317523,25.8822981 88.2697523,24.9702981 C73.1077523,17.4462981 51.6757523,14.8242981 32.4097523,19.2702981 C-5.02818952,27.9098231 -5.89424765,49.8222981 17.2477523,60.7662981 Z"
                      id="Path-13"
                      fill="#A5A5B6"
                    ></path>
                    <path
                      d="M44.9497523,68.5182981 L74.2474099,83.8007912 C83.9659707,88.8702694 95.5453428,88.885287 105.27702,83.8410342 L145.498471,62.9929156 C149.131819,61.1096301 150.550525,56.6375149 148.66724,53.0041667 C147.966267,51.651808 146.865555,50.5483317 145.514958,49.8439699 L113.121752,32.9502981 L113.121752,32.9502981 L44.9497523,68.5182981 Z"
                      id="Path-14"
                      fill="url(#linearGradient-4)"
                    ></path>
                    <path
                      d="M97.5037523,54.0402981 C104.114601,54.0402981 109.473752,56.8474727 109.473752,60.3102981 C109.473752,61.2444883 109.083716,62.1309605 108.384289,62.9274712 L116.541752,67.2642981 L111.297752,70.0002981 L103.366743,65.7779541 C101.633963,66.2888594 99.6338329,66.5802981 97.5037523,66.5802981 C90.8929039,66.5802981 85.5337523,63.7731234 85.5337523,60.3102981 C85.5337523,56.8474727 90.8929039,54.0402981 97.5037523,54.0402981 Z"
                      id="Oval-2"
                      fill="#FFFFFF"
                    ></path>
                    <path
                      d="M32.1534125,2.19949209 C14.1819221,6.3467591 3.29449723,13.8613846 1.00603704,22.914744 C-1.1718678,31.5307362 4.84908126,40.2039355 16.7603887,45.8368691 L17.2616389,46.0739135 L24.1395465,42.7018303 C25.4316189,42.0648375 26.4908215,41.5346007 27.3403704,41.0974274 L29.7333483,39.8540652 L27.6517317,38.8730735 C22.298163,36.3501273 18.0324577,31.9521814 17.362277,27.6724309 C16.5358564,22.3949376 20.9305451,17.2594204 31.8063002,12.9190961 C45.3795189,7.5022611 66.2500906,9.00432748 77.9428994,15.0393256 L78.5074768,15.3307204 L79.0522775,15.00384 C80.0818085,14.3861214 86.6908354,11.0132387 88.755161,10.0417914 L90.8892117,9.03753223 L88.7765023,7.98912007 C73.1280681,0.223731677 51.642913,-2.15037201 32.7258618,2.06959468 L32.1534125,2.19949209 Z M85.4208552,8.93067881 L85.63794,9.02823 L84.081184,9.81034199 C82.1712544,10.7756967 80.0851586,11.8504817 78.8410667,12.5165845 L78.43599,12.73494 L78.2417679,12.639247 C65.8656095,6.62448468 44.8830855,5.24551995 30.9612045,10.8015 C19.2107956,15.4908834 14.0849289,21.480836 15.1097277,28.0251652 L15.1479234,28.2524826 C15.9496007,32.7200762 19.7201592,37.0047308 24.6260884,39.8572716 L24.67986,39.88803 L22.8981079,40.7717538 L17.24022,43.53774 L17.0800085,43.4597261 C6.49167319,38.250294 1.38912193,30.7028165 3.21651125,23.4734959 C5.24999903,15.4288309 15.4383009,8.39674816 32.6660922,4.42110402 C50.2867815,0.354791106 70.320791,2.21366894 85.4208552,8.93067881 Z"
                      id="Path-13-Copy"
                      fill="#FFFFFF"
                      fill-rule="nonzero"
                    ></path>
                    <path
                      d="M146.042108,32.873172 L113.121641,15.7045232 L42.4850338,52.5584052 L73.7201726,68.8515438 C83.7681761,74.0928687 95.7400693,74.1083954 105.801634,68.8931509 L146.023085,48.0450323 C150.21541,45.8720106 151.852378,40.7118777 149.679357,36.5195528 C148.870542,34.9591389 147.600489,33.6858971 146.042108,32.873172 Z M47.41431,52.55799 L113.12163,18.27591 L144.987808,34.8947679 C146.13062,35.4907663 147.061992,36.424477 147.655123,37.5687805 C149.248672,40.6431521 148.048229,44.4272496 144.973857,46.0207988 L104.752406,66.8689174 L104.441815,67.0278037 C95.1049686,71.7412701 84.0605882,71.67385 74.7746473,66.8300386 L47.41431,52.55799 Z"
                      id="Path-14-Copy"
                      fill="#FFFFFF"
                      fill-rule="nonzero"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-auto col-xl-9">
        <h2 class="font-size--page-head-text text-align--center mb-0">
          Your organisation is not currently licensed for Device and Access Manager.
        </h2>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-auto col-xl-7">
        <p class="font-size--body-text text-align--center">
          In order to use Device and Access Manager, it requires a license. If you think that your organisation should
          be licensed, please contact your System Administrator.
        </p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-auto mt-3">
        <a :href="mfAccountUri" class="button button--blue width--100 button--shadow" tabindex="0">
          <i class="icon-arrow-left"></i>
          Return to User Admin
        </a>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    const mfAccountUri = process.env.VUE_APP_AUTH_AUTHORITY_URI;

    return {
      mfAccountUri,
    };
  },
});
</script>

<style lang="scss" scoped>
.unlicensed-product {
  &__image {
    display: block;
    min-width: 15rem;
    max-width: 15rem;
    height: auto;
  }
}

.button {
  padding: 0.5625rem 1.3125rem 0.5625rem 0.5625rem;
}
</style>
