<template>
  <details class="mcc-accordion">
    <summary :class="additionalDetail ? '' : 'no-additional-detail'">
      <div class="titles">
        <span class="mcc-accordion__label">{{ title }}</span>
        <span v-show="subtitle" class="mcc-accordion__label subtitle">{{ subtitle }}</span>
      </div>
      <div v-show="additionalDetail" class="icon mcc-accordion__caret mu-ml-auto" name="expand_arrow" aria-hidden="true">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Icon">
            <path
              id="Vector"
              d="M21.7432 5.98928C21.5451 5.99396 21.3569 6.07681 21.2197 6.21975L12 15.4395L2.78027 6.21975C2.71036 6.14776 2.62672 6.09053 2.53429 6.05144C2.44187 6.01236 2.34254 5.99222 2.24219 5.99221C2.09293 5.99225 1.94707 6.03682 1.82328 6.12021C1.69949 6.20361 1.6034 6.32204 1.5473 6.46036C1.49119 6.59868 1.47763 6.75058 1.50835 6.89665C1.53907 7.04272 1.61266 7.1763 1.71973 7.2803L11.4697 17.0303C11.6104 17.1709 11.8011 17.2499 12 17.2499C12.1989 17.2499 12.3896 17.1709 12.5303 17.0303L22.2803 7.2803C22.3893 7.17562 22.4642 7.04043 22.4951 6.89245C22.526 6.74447 22.5115 6.59061 22.4534 6.45103C22.3953 6.31146 22.2965 6.19269 22.1697 6.11029C22.043 6.02789 21.8943 5.98571 21.7432 5.98928Z"
              fill="currentcolor"
            />
          </g>
        </svg>
      </div>
    </summary>
    <div v-show="additionalDetail" class="mcc-accordion__body">{{ additionalDetail }}</div>
  </details>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String, required: true
    },
    subtitle: {
      type: String, required: true
    },
    additionalDetail: {
      type: String, required: true
    },
    hideLabel: {
      type: String
    }
  },
  setup(props) {
    // const handleClick = () => {
    //   emit('click');
    // };

    // return {
    //   click: handleClick,
    //   slots,
    // };
  },
});
</script>

<style lang="scss" scoped>
.mcc-accordion {
  --spacing-block: var(--mds-spacing-05, 1rem);
  --spacing-inline: var(--mds-spacing-05, 1rem);
  --summary-gap: var(--mds-spacing-05, 1rem);
  --summary-font-weight: bold;
  --focus-offset: false;

  --mds-accordion-base-transform: rotate(0);
  --mds-accordion-base-spacing-block: var(--mds-accordion-spacing-block, --spacing-block);
  --mds-accordion-base-spacing-inline: var(--mds-accordion-spacing-inline, --spacing-inline);
  --mds-accordion-base-gap: var(--mds-accordionn-gap, --summary-gap);
  --mds-accordion-title-color: inherit;
  --mds-accordion-subtitle-color: var(--mds-text-color-muted);

  display: block;
  inline-size: 100%;
  padding: var(--mds-accordion-base-spacing-block);
  padding-inline: var(--mds-accordion-base-spacing-inline);
  padding: 16px;
  margin-bottom: 0.5rem;

  &:focus-within {
    --mds-outline-offset: 0.125rem;
    outline-offset: calc(var(--focus-offset) * -1);
  }

  > summary {
    display: flex;
    align-items: center;
    gap: var(--mds-accordion-base-gap);
    margin-block: calc(var(--mds-accordion-base-spacing-block) * -1);
    margin-inline: calc(var(--mds-accordion-base-spacing-inline) * -1);
    padding-block: var(--mds-accordion-base-spacing-block);
    padding-inline: var(--mds-accordion-base-spacing-inline);
    font-weight: --summary-font-weight;
    cursor: pointer;
    list-style: none;

    &:focus-visible {
      outline: none;
    }

    span:first-of-type {
      font-size: 110%;
    }
  }

  // Customise the standard style from Mosaic to allow the subtitle to be on a second line.
  > summary {
    flex-wrap: wrap;
    > *:first-child {
      flex-basis: 90% !important;
    }

    &.no-additional-detail {
      cursor: inherit;
    }
  }

  .subtitle {
    color: var(--mds-accordion-subtitle-color);
  }

  .titles {
    display: flex;
    flex-direction: column;
  }

  .icon {
    display: flex;
  }

  // ↓ Caret
  &__caret svg {
    transform: var(--mds-accordion-base-transform);
    transition: 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition-property: transform;
  }

  // ↓ Body
  &__body {
    display: block;
    inline-size: 100%;
    padding-top: 16px;
    white-space: pre-line;
  }

  // ↓ Open state
  &[open] {
    --mds-accordion-base-transform: rotate(-180deg);

    > summary {
      margin-block-end: 0;
    }
  }
}
</style>
