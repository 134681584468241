<template>
  <div v-if="isActive">
    <slot />
  </div>
</template>

<script lang="ts">
import { Ref, defineComponent, inject, onBeforeUnmount, onMounted, ref } from 'vue';

interface ITab {
  isActive: Ref<boolean>;
  name: string;
}

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isActive = ref(false);
    //const name = ref('');

    // these are injected from TabDrawer.vue but not working why?
    const registerTab = inject<(tab: ITab) => void>('registerTab', (tab: ITab) => {});
    const unregisterTab = inject<(tab: ITab) => void>('unregisterTab', (tab: ITab) => {});

    onMounted(() => {
      registerTab({ isActive, name: props.name });
    });

    onBeforeUnmount(() => {
      unregisterTab({ isActive, name: props.name });
    });

    return {
      isActive,
      //name,
    };
  },
});
</script>
