import {
  CreateUserDto,
  ModifyUserDto,
  IUserAuthenticationDto,
  UserDto,
  UserListMetaDataDto,
  UsersClient,
  UserSelectDto,
  UserListSelectDto,
  UserListSelectMetaDataDto,
} from '@/apiClients';
import { Dictionary } from '@/extensions/types';
import { forceStringVal } from '@/utilities/strings';

import authorizedClient from './authorizedClient';

export interface IUsersState {
  users: Dictionary<UserDto>;
  lastPage: number;
  metaData: UserListMetaDataDto;
}

export interface IGetUsersPayload {
  pageOffset: number;
  pageSize: number;
}

export interface IGetAllUsersOnUserGroup {
  userGroupId: string;
  pageOffset: number;
  pageSize: number;
  includeNew: boolean;
  includeUnenrolled: boolean;
  includeActive: boolean;
  includeInactive: boolean;
  searchTerm: string;
}

export interface IGetFilteredMatchedUsersPayload {
  pageOffset: number;
  pageSize: number;
  includeNew: boolean;
  includeUnenrolled: boolean;
  includeActive: boolean;
  includeInactive: boolean;
  searchTerm: string;
}

export default {
  namespaced: true as true,
  state: () =>
    ({
      users: {},
      metaData: new UserListMetaDataDto({ new: 0, unenrolled: 0, active: 0, inactive: 0, filtered: 0 }),
    }) as IUsersState,
  getters: {
    user:
      (state: IUsersState) =>
      (id: string): UserDto | undefined => {
        return state.users[id];
      },
    metaData: (state: IUsersState) => () => state.metaData,
  },
  mutations: {
    ADD_USERS_META_DATA(state: IUsersState, metaData: UserListMetaDataDto) {
      state.metaData = metaData;
    },
    ADD_USERS(state: IUsersState, users: UserDto[]) {
      users.forEach((user) => {
        state.users[user.id] = user;
      });
    },
    ADD_USERS_SELECT_META_DATA(state: IUsersState, metaData: UserListSelectMetaDataDto) {
      state.metaData = metaData;
    },
    SET_USER(state: IUsersState, user: UserDto) {
      state.users[user.id] = user;
    },
    REMOVE_USER(state: IUsersState, id: string) {
      delete state.users[id];
    },
  },
  actions: {
    async getUsers({ commit }: any, payload: IGetUsersPayload) {
      const usersClient = await authorizedClient(UsersClient);
      try {
        const userList = await usersClient.getAllUsers(
          payload.pageOffset,
          payload.pageSize,
          true,
          true,
          true,
          true,
          '',
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION
        );
        if (userList && userList.users) {
          commit('ADD_USERS', userList.users);
          return userList.users;
        }
      } catch (error) {
        throw error;
      }
      return [];
    },
    async getAllUsersOnUserGroup({ commit }: any, payload: IGetAllUsersOnUserGroup) {
      const usersClient = await authorizedClient(UsersClient);
      try {
        const userList = await usersClient.getAllUsersOnUserGroup(
          payload.userGroupId,
          payload.pageOffset,
          payload.pageSize,
          true,
          true,
          true,
          true,
          payload.searchTerm,
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION
        );
        if (userList) {
          if (userList.users) {
            commit('ADD_USERS', userList.users);
          }
          if (userList.metaData) {
            commit('ADD_USERS_SELECT_META_DATA', userList.metaData);
          }
          return userList.users;
        }
      } catch (error) {
        throw error;
      }
      return [];
    },
    async getFilteredMatchedUsers({ commit }: any, payload: IGetFilteredMatchedUsersPayload) {
      const usersClient = await authorizedClient(UsersClient);
      try {
        const userList = await usersClient.getAllUsers(
          payload.pageOffset,
          payload.pageSize,
          payload.includeNew,
          payload.includeUnenrolled,
          payload.includeActive,
          payload.includeInactive,
          payload.searchTerm,
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION
        );
        if (userList) {
          if (userList.users) {
            commit('ADD_USERS', userList.users);
          }
          if (userList.metaData) {
            commit('ADD_USERS_META_DATA', userList.metaData);
          }
          return userList.users;
        }
      } catch (error) {
        throw error;
      }
      return [];
    },
    async createUser({ commit }: any, user: CreateUserDto) {
      const usersClient = await authorizedClient(UsersClient);
      try {
        return await usersClient.create(process.env.VUE_APP_TERMINAL_WEB_API_VERSION, user);
      } catch (error) {
        throw error;
      }
    },
    async getUser({ commit }: any, id: string) {
      const usersClient = await authorizedClient(UsersClient);
      try {
        const user = await usersClient.getUser(id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
        if (user) {
          commit('SET_USER', user);
        }
      } catch (error) {
        throw error;
      }
    },
    async modifyUser({ commit }: any, user: UserDto): Promise<void> {
      if (user.id === null || user.id === undefined) {
        throw Error('Invalid user ID; user ID must not be null nor undefined');
      }
      const modifyDto = new ModifyUserDto({
        firstName: forceStringVal(user.firstName),
        surname: forceStringVal(user.surname),        
        enrolmentCode: forceStringVal(user.enrolmentCode),
        startDate: user.startDate!,
        endDate: user.endDate,
        externalReference: forceStringVal(user.externalReference),
        authentication: user.authentication as IUserAuthenticationDto,
      });
      const usersClient = await authorizedClient(UsersClient);
      await usersClient.modify(user.id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION, modifyDto);
    },
    async deleteUser({ commit }: any, id: string): Promise<void> {
      const usersClient = await authorizedClient(UsersClient);
      await usersClient.delete(id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
    },
    async resetUserEnrolmentCode({ commit }: any, id: string): Promise<void> {
      const usersClient = await authorizedClient(UsersClient);
      await usersClient.resetEnrolmentCode(id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
    },
    async downloadUserList({ commit }: any): Promise<void> {
      const usersClient = await authorizedClient(UsersClient);
      try {
        const file = await usersClient.downloadCsv(process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
        const objectUrl = window.URL.createObjectURL(file!.data);
        const element = document.createElement('a');
        element.download = file!.fileName || 'AD@AM_users.csv';
        element.href = objectUrl;
        element.click();
      } catch (error) {
        throw error;
      }
    },
  },
};
