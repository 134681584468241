import { AreaDto, AreasClient, CreateAreaDto, ModifyAreaDto } from '@/apiClients';
import { Dictionary } from '@/extensions/types';
import { forceStringVal } from '@/utilities/strings';

import authorizedClient from './authorizedClient';

export interface IAreasState {
  areas: Dictionary<AreaDto>;
}

export default {
  namespaced: true as true,
  state: () =>
    ({
      areas: {},
    }) as IAreasState,
  getters: {
    area:
      (state: IAreasState) =>
      (id: string): AreaDto | undefined => {
        return state.areas[id];
      },
  },
  mutations: {
    SET_AREA(state: IAreasState, area: AreaDto) {
      state.areas[area.id] = area;
    },
    REMOVE_AREA(state: IAreasState, id: string) {
      delete state.areas[id];
    },
  },
  actions: {
    async getArea({ commit }: any, id: string) {
      await authorizedClient(AreasClient).then(async (client) => {
        await client
          .getArea(id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION)
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
          })
          .then((result) => {
            if (result) {
              commit('SET_AREA', result);
            }
          });
      });
    },
    async createArea({ commit }: any, area: CreateAreaDto) {
      const areasClient = await authorizedClient(AreasClient);
      try {
        return await areasClient.create(process.env.VUE_APP_TERMINAL_WEB_API_VERSION, area);
      } catch (error) {
        throw error;
      }
    },
    async deleteArea({ commit }: any, id: string) {
      const areasClient = await authorizedClient(AreasClient);
      try {
        await areasClient.delete(id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
        commit('REMOVE_AREA', id);
      } catch (error) {
        throw error;
      }
    },
    async modifyArea({ commit }: any, area: AreaDto) {
      if (area.id == null) {
        throw Error('Invalid area ID; area ID must not be null nor undefined');
      }
      const areasClient = await authorizedClient(AreasClient);
      const modifyDto = new ModifyAreaDto({
        name: forceStringVal(area.name),
      });
      return areasClient.modify(area.id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION, modifyDto);
    },
  },
};
