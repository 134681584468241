<template>
  <section class="modal" aria-modal="true" role="dialog">
    <div class="modal__mask" @click="maskClicked($event)">
      <div class="modal__wrapper">
        <div @click="bodyClicked" :class="`modal__container--${large ? 'large' : 'small'}`">
          <span
            class="tag tag--pill-icon modal__close-button"
            v-if="showClose"
            @click="close"
            @keydown.enter="close"
            tabindex="0"
            title="Close"
          >
            <i class="icon-cross"></i>
            <span class="sr-only">Close</span>
          </span>
          <header class="modal__header" data-test="header-warningModalHeaderText">
            <slot name="modalHeader" />
          </header>
          <section class="modal__body" data-test="section-warningModalBodyText">
            <slot name="modalBody" />
          </section>
          <footer class="modal__footer">
            <slot name="modalFooter" />
          </footer>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, Prop, ref, onMounted, onBeforeUnmount, onUnmounted } from 'vue';

export default defineComponent({
  name: 'Modal',
  props: {
    showClose: {
      type: Boolean,
      default: true,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const insideClickHandled = ref(false);

    const close = () => {
      emit('close');
    };

    const escapeKeyHandler = (e: KeyboardEvent): void => {
      if (e.keyCode === 27 || e.key === 'Escape') {
        close();
      }
    };

    const maskClicked = (event: MouseEvent): void => {
      if (insideClickHandled.value) {
        insideClickHandled.value = false;
        return;
      }
      close();
    };

    const bodyClicked = (event: MouseEvent): void => {
      insideClickHandled.value = true;
    };

    onMounted(() => {
      document.addEventListener('keyup', escapeKeyHandler);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('keyup', escapeKeyHandler);
    });

    return {
      close,
      insideClickHandled,
      maskClicked,
      bodyClicked,
    };
  },
});
</script>

<style lang="scss" scoped>
.modal__container--small {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
