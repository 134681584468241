import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21e7a6d1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "[ mfc-radio-button-wrapper ] [ mu-mb-03 ]" }
const _hoisted_2 = ["value", "id", "name"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      value: _ctx.isChecked,
      id: _ctx.inputId,
      name: _ctx.name,
      type: "radio",
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
    }, null, 40, _hoisted_2),
    _withDirectives(_createElementVNode("label", { for: _ctx.inputId }, [
      _withDirectives(_createElementVNode("span", { class: "title" }, _toDisplayString(_ctx.title), 513), [
        [_vShow, _ctx.title]
      ]),
      _withDirectives(_createElementVNode("span", { class: "subtitle" }, _toDisplayString(_ctx.subtitle), 513), [
        [_vShow, _ctx.subtitle]
      ])
    ], 8, _hoisted_3), [
      [_vShow, !_ctx.hideLabel]
    ])
  ]))
}