import { CreateSiteDto, ModifySiteDto, SiteDto, SiteSummaryDto, SitesClient } from '@/apiClients';
import { Dictionary } from '@/extensions/types';
import Search from '@/utilities/search';
import { forceStringVal } from '@/utilities/strings';

import authorizedClient from './authorizedClient';

export interface ISitesState {
  siteSummaries: null | SiteSummaryDto[];
  sites: Dictionary<SiteDto>;
}

export default {
  namespaced: true as true,
  state: () =>
    ({
      siteSummaries: null,
      sites: {},
    }) as ISitesState,
  getters: {
    filteredSites: (state: ISitesState) => (searchTerm: string) => {
      if (state.siteSummaries == null) {
        return [];
      }

      if (searchTerm === '') {
        return state.siteSummaries;
      }
      return state.siteSummaries.filter((site) => filterSite(site, searchTerm));
    },
    site:
      (state: ISitesState) =>
      (id: string): SiteDto | undefined => {
        return state.sites[id];
      },
  },
  mutations: {
    SET_SITE_SUMMARIES(state: ISitesState, sites: SiteSummaryDto[]) {
      state.siteSummaries = sites;
    },
    SET_SITE(state: ISitesState, site: SiteDto) {
      state.sites[site.id] = site;
    },
  },
  actions: {
    async getSites({ commit }: any) {
      const sitesClient = await authorizedClient(SitesClient);
      await sitesClient
        .getAllSites(process.env.VUE_APP_TERMINAL_WEB_API_VERSION)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        })
        .then((result) => {
          commit('SET_SITE_SUMMARIES', result);
        });
    },
    async getSite({ commit }: any, id: string) {
      const sitesClient = await authorizedClient(SitesClient);
      await sitesClient
        .getASite(id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        })
        .then((result) => {
          if (result) {
            commit('SET_SITE', result);
          }
        });
    },
    async createSite({ commit }: any, site: CreateSiteDto) {
      const sitesClient = await authorizedClient(SitesClient);
      try {
        return await sitesClient.create(process.env.VUE_APP_TERMINAL_WEB_API_VERSION, site);
      } catch (error) {
        throw error;
      }
    },
    async deleteSite({ commit }: any, id: string) {
      const sitesClient = await authorizedClient(SitesClient);
      await sitesClient.delete(id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION);
    },
    async modifySite({ commit }: any, site: SiteDto) {
      if (site.id === null || site.id === undefined) {
        throw Error('Invalid site ID; site ID must not be null or undefined');
      }
      const sitesClient = await authorizedClient(SitesClient);
      const modifyDto = new ModifySiteDto({
        addressLine1: forceStringVal(site.addressLine1),
        addressLine2: forceStringVal(site.addressLine2),
        country: forceStringVal(site.country),
        county: forceStringVal(site.county),
        houseOrNumber: forceStringVal(site.houseOrNumber),
        name: forceStringVal(site.name),
        postCode: forceStringVal(site.postCode),
        telephoneNumber: forceStringVal(site.telephoneNumber),
        townCity: forceStringVal(site.townCity),
        latitude: site.latitude!,
        longitude: site.longitude!,
      });
      await sitesClient.modify(site.id, process.env.VUE_APP_TERMINAL_WEB_API_VERSION, modifyDto);
    },
  },
};

function filterSite(site: SiteSummaryDto, searchTerm: string): boolean {
  return (
    Search.fuzzySearch(site.name, searchTerm) ||
    Search.fuzzySearch(site.houseOrNumber, searchTerm) ||
    Search.fuzzySearch(site.addressLine1, searchTerm) ||
    Search.fuzzySearch(site.addressLine2, searchTerm) ||
    Search.fuzzySearch(site.townCity, searchTerm) ||
    Search.fuzzySearch(site.county, searchTerm) ||
    Search.fuzzySearch(site.postCode, searchTerm) ||
    Search.fuzzySearch(site.country, searchTerm) ||
    Search.fuzzySearch(site.telephoneNumber, searchTerm)
  );
}
