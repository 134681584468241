import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withKeys as _withKeys, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5fcbb2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "drawer",
  role: "dialog"
}
const _hoisted_2 = { class: "drawer__panel drawer__panel--tabbed" }
const _hoisted_3 = { class: "drawer__tabs" }
const _hoisted_4 = { class: "drawer__list" }
const _hoisted_5 = ["aria-setsize", "aria-posinset", "onKeydown", "onClick"]
const _hoisted_6 = ["aria-current"]
const _hoisted_7 = { class: "drawer__content" }
const _hoisted_8 = { class: "drawer__header" }
const _hoisted_9 = ["title"]
const _hoisted_10 = { class: "tab-drawer__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Btn = _resolveComponent("Btn")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", {
      class: "drawer__overlay",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("aside", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              "aria-setsize": _ctx.tabs.length,
              "aria-posinset": index + 1,
              onKeydown: _withKeys(($event: any) => (_ctx.selectTab(tab)), ["enter"]),
              onClick: ($event: any) => (_ctx.selectTab(tab)),
              class: "drawer__tab"
            }, [
              _createElementVNode("a", {
                href: "javascript:void(0)",
                class: _normalizeClass([{
                'drawer__link--active': tab.isActive,
                [`${tab.name.replace(/\s+/g, '-').toLowerCase()}-active`]: tab.isActive,
                [`${tab.name.replace(/\s+/g, '-').toLowerCase()}-inactive`]: !tab.isActive,
              }, "drawer__link"]),
                "aria-current": tab.isActive ? 'true' : null
              }, _toDisplayString(tab.name), 11, _hoisted_6)
            ], 40, _hoisted_5))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("header", _hoisted_8, [
          _createElementVNode("h2", {
            title: _ctx.header,
            class: "m-0 overflow-x--ellipsis"
          }, _toDisplayString(_ctx.header), 9, _hoisted_9),
          _createVNode(_component_Btn, {
            class: "drawer__close",
            "data-test": "button-tabDrawerButtonClose",
            icon: "cross",
            title: "Close",
            onClick: _ctx.close,
            ghost: ""
          }, null, 8, ["onClick"])
        ]),
        _createElementVNode("section", {
          class: _normalizeClass([{ 'drawer__main--not-scrollable': _ctx.notScrollable }, "drawer__main"])
        }, [
          _renderSlot(_ctx.$slots, "main", {}, undefined, true)
        ], 2),
        _createElementVNode("footer", _hoisted_10, [
          _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
        ])
      ])
    ])
  ]))
}