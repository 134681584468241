import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21c2e53a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tooltip__trigger" }
const _hoisted_2 = {
  key: 0,
  class: "tooltip__content",
  role: "tooltip",
  tabindex: "0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "tooltip",
    onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showTooltip && _ctx.showTooltip(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.hideTooltip && _ctx.hideTooltip(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "tooltipTrigger", {}, undefined, true)
    ]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.isTooltipDisplayed)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "tooltipContent", {}, undefined, true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ], 32))
}